import { useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "../../components/ui/container/Container";
import NavBar from "../../components/ui/navbar/NavBar";
import styles from "./TransactionDetail.module.scss";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import TabTitle from "../../components/ui/tabs/TabTitle";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Icon from "../../components/ui/icon/Icon";
import MessagesForm from "../../components/forms/transaction/Messages";
import NotesForm from "../../components/forms/transaction/Notes";
import { TransactionsFormsProvider } from "../../providers/transactions/TransactionsFormContext";
import VerificationForm from "../../components/forms/transaction/Verification";
import SamplesForm from "../../components/forms/transaction/Samples";
import TransportForm from "../../components/forms/transaction/Transport";
import Search from "../../components/ui/input/search/Search";
import LogItem from "../../components/ui/logs/Item";
import Padding from "../../components/ui/padding/Padding";
import Center from "../../components/ui/center/Center";
import Heading from "../../components/ui/heading/Heading";
import LogDay from "../../components/ui/logs/Day";
import Alert from "../../components/ui/alert/Alert";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { useClickOnBody, useTranslation } from "../../utils/hooks";
import { Virtuoso } from "react-virtuoso";
import {
  checkPermission,
  getOffset,
  getOrgBuyerOrgSellerFromTransactionDetail,
  scrollTo,
  searchOnLog,
} from "../../utils/functions";
import BoxIcon from "../../components/ui/icons/general/BoxIcon";
import Loading from "../../components/ui/loading/Loading";
import { getTransactionDetail } from "../../model/transactions";
import Sidebar from "./sidebar/Sidebar";
import {
  logActions,
  logTypes,
  transactionFormTransportDefault,
  responseTypes,
  BREAKPOINT_LG,
  transactionFinancesTypes,
  transactionFormFinancesDefault,
  RoleType,
} from "../../utils/variables";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { addMonths } from "date-fns";
import LayoutDetail from "../../layout/detail/Detail";
import TransactionDetailHeader from "./header/Detail";
import { useDocumentTitle } from "../../utils/document/hooks";
import {
  toastError,
  toastSuccess,
  ClearButton,
  Content,
  useMatchMedia,
  Paper,
  Row,
  Spacer,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { buildFormSign } from "../../components/forms/transaction/functions";
import { postClient } from "../../utils/restclient";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { renderComponentWithPermission } from "../../utils/functions";
import TrackingForm from "../../components/forms/transaction/Tracking";
import FinancesForm from "../../components/forms/transaction/Finances";
import TransactionHeader from "./header/TransactionHeader";
import TabPanelWrap from "./wrappers/TabPanelWrap";
import TransactionSteps from "../../widgets/transactions/steps/Steps";
import _ from "lodash";
import WebsocketsWrapper from "../../widgets/websockets/Wrapper";
import { WS_ROOM } from "../../utils/websockets/functions";
import ChipOutlined from "../../components/ui/chip/ChipOutlined";

const calculateMessageslength = (messages) => {
  return messages
    .map((message) => message?.logItems?.length + 1)
    .reduce((prev, current) => prev + current, 0);
};

const TransactionDetail = () => {
  let { id } = useParams();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const user = useUser();
  const setDataUser = useSetDataUser();
  const { t, i18n, values } = useTranslation([
    "common",
    "transactions",
    "finances",
  ]);

  const changeTab = (index) => {
    if (tabIndex === index) {
      setTabIndex(-1);
    } else {
      setTabIndex(index);
    }
  };
  const [tabIndex, setTabIndex] = useState(-1);
  const [transport, setTransport] = useState("cost");
  const [returnType, setReturnType] = useState("tolerance");
  const transportRef = useRef(null);
  const financesRef = useRef(null);
  const firstReachedTopRef = useRef(true);
  const transportContainerRef = useRef(null);
  const financesContainerRef = useRef(null);
  const verificationcontainerRef = useRef(null);
  const [openTransport, setOpenTransport] = useState(false);
  const [openFinances, setOpenFinances] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [reachedTop, setReachedTop] = useState(false);
  const virtuosoRef = useRef(null);
  const [data, setData] = useState([]);
  const [notPinned, setNotPinned] = useState([]);
  const [pinned, setPinned] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const notPinnedBuffer = useRef([]);
  const {
    canManageTransaction,
    canReadLogistics,
    canReadFinances,
    canManageLogistics,
    canManageTransactionMessages,
    canManageTransactionSamplesVerificationTransport,
    canManageTransactionReturn,
    canReadTransactionMessages,
    canReadTransactionSamplesVerificationTransport,
    canManageFinances,
  } = useOperationsProvider();

  const [formsData, setFormsData] = useState({
    message: {
      destination: { seller: false, buyer: false },
      files: [],
      content: "",
      hiddenDestination: "",
      uuidEvent: id,
      loading: false,
    },
    notes: {
      files: [],
      content: "",
      uuidEvent: id,
      loading: false,
      hiddenDestination: "",
    },
    verification: {
      suplier: "",
      price: "",
      state: "",
      uuidEvent: id,
      loading: false,
      update: false,
    },
    transport: {
      files: [],
      suplier: "",
      suplierUuid: null,
      untilDate: addMonths(new Date().getTime(), 1).getTime(),
      numLote: "",
      unitPrice: "",
      estimatedDate: null,
      estimatedSchedule: {
        from: "",
        to: "",
      },
      state: "",
      stateName: "",
      uuidEvent: id,
      loading: false,
      update: false,
      transportType: "",
      incoterm: "",
      shippingDeadline: "",
      equipment: [],
    },
    samples: {
      deliveryPrice: "",
      estimatedDate: null,
      state: "PENDING",
      uuidEvent: id,
      loading: false,
      update: false,
    },
    return: {
      destination: { seller: false, buyer: true },
      hiddenDestination: "",
      valueTolerance: "",
      valueBail: "",
      valueOther: "",
      comments: "",
      content: "",
      uuidEvent: id,
      loading: false,
      update: false,
    },
    finances: {
      destination: { seller: false, buyer: true },
      hiddenDestination: "",
      amount: "",
      deposit: "",
      concept: "",
      loading: false,
      update: false,
      uuidEvent: id,
    },
    loading: false,
  });
  const sideData = data?.sideData;
  // const subtitle = `ID ${
  //   data?.header?.uuidConversation?.split("-")?.[0]
  // } - iniciada por ${data?.header?.startedBy} el ${formatDate(
  //   data?.header?.startedAt,
  //   i18n,
  //   false
  // )}`;

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );
  const subtitle = (
    <ChipOutlined iconComponent={typeIcon}>
      <span style={{ textTransform: "uppercase" }}>
        {data?.header?.uuidConversation?.split("-")?.[0]}
      </span>
    </ChipOutlined>
  );
  useDocumentTitle(`${t("transaction")} ${id.split("-")?.[0]}`, values);

  useEffect(() => {
    const el = document.getElementById("Anchor");
    if (el) {
      const position = getOffset(el);
      const finalPosition =
        window.innerWidth < BREAKPOINT_LG
          ? position.top - 20
          : position.top + 35;
      scrollTo(document.querySelector("html"), finalPosition, 700);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const search = document.getElementById("LogSearch");
  //     if (search.value === "") {
  //       fetchData(true);
  //     }
  //   }, 10000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useEffect(() => {
    if (data.log) {
      const pinned = data.log.map((log) => {
        if (log.logItems !== null && log.logItems.length > 0) {
          return {
            date: log.date,
            logItems: log.logItems.filter((f) => f && f.pinned === true),
          };
        }
      });
      const notPinned = data.log.map((log) => {
        if (log.logItems !== null && log.logItems.length > 0) {
          return {
            date: log.date,
            logItems: log.logItems.filter(
              (f) => f && (!f.pinned || f.pinned === false)
            ),
          };
        }
      });
      notPinnedBuffer.current = [...notPinned];
      setNotPinned(notPinned.filter((f) => f));
      setPinned(pinned.length > 0 ? pinned[pinned.length - 1] : []);
    }
  }, [data]);

  useLayoutEffect(() => {
    moveTabMenu(financesContainerRef, financesRef);
  }, [openFinances]);

  useLayoutEffect(() => {
    moveTabMenu(transportContainerRef, transportRef);
  }, [openTransport]);

  const handleAnswer = (key) => () => {
    setOpenAnswer(`modal_answer_${key}`);
  };
  useClickOnBody(
    document.querySelector(".transport-ref"),
    () => setOpenTransport(false),
    true
  );
  useClickOnBody(
    document.querySelector(".return-ref"),
    () => setOpenFinances(false),
    true
  );

  const webSocketCallback = (d) => {
    if (
      !_.isEmpty(d) &&
      d?.subscribeRoom?.uuid === data?.header?.uuidConversation
    ) {
      fetchData(true);
    }
  };

  const handleTransport = (type) => (e) => {
    setTransport(type);
    changeTab(3);
    setOpenTransport(false);
    setFormsData({
      ...formsData,
      transport: {
        ...formsData.transport,
        ...transactionFormTransportDefault,
      },
    });
  };
  const handleFinances = (type) => (e) => {
    setReturnType(type);
    changeTab(5);
    setOpenFinances(false);
    setFormsData({
      ...formsData,
      return: {
        ...formsData.finances,
        ...transactionFormFinancesDefault,
      },
    });
  };
  const NoMoreMessages = () => {
    if (data.length === 0) return <></>;
    if (!reachedTop) return <div style={{ height: 70 }}></div>;
    return (
      <Padding>
        <Center>
          <Heading type="5">{t("no-more-messages")}</Heading>
        </Center>
      </Padding>
    );
  };

  const NoMessages = () => {
    return (
      <Padding>
        <Center>
          <Heading type="5">{t("no-messages")}</Heading>
        </Center>
      </Padding>
    );
  };

  const moveTabMenu = (ref, dropRef) => {
    if (!ref || !ref.current || !dropRef || !dropRef.current) return;
    const tabs = document.querySelector(".tab-list--ref");
    dropRef.current.style.top = `56px`;
    dropRef.current.style.left = `${
      ref.current.offsetLeft - tabs.scrollLeft
    }px`;
  };

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  const handleSign = (key) => async (type) => {
    const data = buildFormSign(user.userUuid, id, type, key);
    setSignLoading(true);
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      fetchData(true);
      toastSuccess(t("action-ok"));
    } catch (error) {
      toastError(error.message);
    } finally {
      setSignLoading(false);
    }
  };

  const hasNoReadPermissionsInTabs = () => {
    if (
      canReadTransactionMessages &&
      !canReadTransactionMessages() &&
      canReadTransactionSamplesVerificationTransport &&
      !canReadTransactionSamplesVerificationTransport() &&
      canReadLogistics &&
      !canReadLogistics() &&
      canReadFinances &&
      !canReadFinances()
    )
      return true;
    return false;
  };

  /* 
    Dado que renderComponentWithPermission espera como primer parámetro una funciónn de permiso,
    en esta función devolvemos la función de canRead apropiada en caso de que exista y su permiso devuelva true.
    Es una comprobación extra pero de esta manera no cambiamos el comportamiento por defecto de la pestaña.
   */
  const handleFinancesReadPerimssion = () => {
    if (canReadFinances && canReadFinances()) return canReadFinances;
    if (canReadLogistics && canReadLogistics()) return canReadLogistics;
    return undefined;
  };

  /**
   * Igual que la función de arriba pero para el permiso de manage
   */
  const handleFinancesManagePermission = () => {
    if (canManageFinances && canManageFinances()) return canManageFinances;
    if (canManageLogistics && canManageLogistics()) return canManageLogistics;
    return undefined;
  };

  const buyerAndSellerUuids = getOrgBuyerOrgSellerFromTransactionDetail(
    data?.sideData
  );

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#FFF"}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <TransactionHeader
                      goBack
                      imageUrl={data?.sideData?.originAd?.image}
                      breadcrumbs={[
                        {
                          description: t("transactions"),
                          onClick: () =>
                            navigate("/transactions", {
                              replace: true,
                            }),
                        },
                        { description: data?.header?.adTitle },
                      ]}
                      title={data?.header?.adTitle}
                      subtitle={subtitle}
                      rightArea={
                        <TransactionDetailHeader
                          uuidEvent={id}
                          title={data?.header?.adTitle}
                          subtitle={subtitle}
                          fetchData={fetchData}
                          permission={canManageTransaction}
                          permissionLogistics={canReadLogistics}
                          onlyMenu
                        />
                      }
                    />
                    {/* <Header
                      title={data?.header?.adTitle}
                      titleSmall
                      goBack
                      breadcrumbs={[
                        {
                          description: t("transactions"),
                          onClick: () =>
                            navigate("/transactions", {
                              replace: true,
                            }),
                        },
                        { description: data?.header?.adTitle },
                      ]}
                      subtitle={subtitle}
                      subHeaderRight={
                        <TransactionDetailHeader
                          uuidEvent={id}
                          title={data?.header?.adTitle}
                          subtitle={subtitle}
                          fetchData={fetchData}
                          permission={canManageTransaction}
                          permissionLogistics={canReadLogistics}
                        />
                      }
                      lowerRightStyles={{ width: matches ? "100%" : "auto" }}
                    /> */}
                  </>
                )}
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <TransactionSteps id={id} />
            </Container>

            <Container>
              <Row wrap={matches} gap={15}>
                <Paper
                  style={{ width: "calc(100% - 450px)", display: "block" }}
                >
                  <div className={styles["sc-transaction--log"]}>
                    <div className={styles["sc-logs--container"]}>
                      <div className={styles["sc-transaction--search"]}>
                        <Search
                          minimal
                          id={"LogSearch"}
                          placeholder={t("search")}
                          onChange={searchOnLog(
                            notPinned,
                            setNotPinned,
                            notPinnedBuffer.current,
                            t,
                            i18n
                          )}
                        />
                      </div>
                      <div className={styles["sc-logs"]}>
                        <Virtuoso
                          ref={virtuosoRef}
                          style={{ flex: 1 }}
                          firstItemIndex={0}
                          initialTopMostItemIndex={
                            calculateMessageslength(notPinned) - 1
                          }
                          alignToBottom={true}
                          followOutput="smooth"
                          data={notPinned}
                          atTopThreshold={50}
                          overscan={50}
                          atTopStateChange={(atTop) => {
                            firstReachedTopRef.current
                              ? (firstReachedTopRef.current = false)
                              : setReachedTop(atTop);
                          }}
                          itemContent={(index, data) => {
                            if (!data) return <></>;
                            const { date, logItems } = data;
                            return (
                              <div>
                                <LogDay date={date} />
                                {logItems.map((item) => {
                                  if (!item) return;
                                  const {
                                    key,
                                    originatedBy,
                                    eventName,
                                    phase,
                                    type,
                                    date,
                                    content,
                                    contentData,
                                    realatedMessage,
                                  } = item;
                                  const hasResponse =
                                    type === responseTypes.NEED_REPLY &&
                                    checkPermission(
                                      canManageTransactionMessages
                                    );
                                  const documents = contentData?.filter(
                                    (f) => f.key === "link"
                                  );
                                  const info = undefined;
                                  const answer = realatedMessage
                                    ? { ...realatedMessage }
                                    : undefined;
                                  const hasReceivedPaymentButton =
                                    type === responseTypes.CONFIRM_DEPOSIT;
                                  const hasEventGeneratedAction = false;
                                  const hasDepositDone =
                                    type === responseTypes.CONFIRM_DEPOSIT;
                                  const hasSignButton =
                                    type ===
                                    responseTypes.PURCHASE_SALE_PENDING_SCRAPAD_READ
                                      ? false
                                      : type ===
                                          responseTypes.PURCHASE_SALE_CONTRACT &&
                                        eventName ===
                                          responseTypes.PURCHASE_SALE_PENDING_SCRAPAD;

                                  return (
                                    <>
                                      <LogItem
                                        uuidEvent={id}
                                        key={key}
                                        uuid={key}
                                        type={logTypes[originatedBy]}
                                        originatedBy={t(originatedBy)}
                                        action={logActions[phase]}
                                        phase={logActions[phase]}
                                        date={date}
                                        hasReceivedPaymentButton={
                                          hasReceivedPaymentButton
                                        }
                                        hasResponseButton={hasResponse}
                                        hasReportButton={
                                          eventName ===
                                          responseTypes.REPORT_PROBLEM
                                        }
                                        hasSignButton={hasSignButton}
                                        hasEventGeneratedAction={
                                          hasEventGeneratedAction
                                        }
                                        documents={documents}
                                        hasDepositDone={hasDepositDone}
                                        info={info}
                                        content={content}
                                        contentData={contentData}
                                        answer={answer}
                                        handleAnswer={handleAnswer(key)}
                                        handleSign={
                                          hasSignButton
                                            ? handleSign(key)
                                            : undefined
                                        }
                                        signLoading={signLoading}
                                        fetchData={fetchData}
                                        uuidComprador={
                                          sideData?.advertiser?.role === "buyer"
                                            ? sideData?.advertiser?.uuidPerson
                                            : sideData?.interested?.uuidPerson
                                        }
                                      />
                                      {hasResponse && (
                                        <ModalFactory
                                          type="transactions-answer"
                                          open={
                                            openAnswer === `modal_answer_${key}`
                                          }
                                          setOpen={setOpenAnswer}
                                          uuidEvent={id}
                                          uuidMessageToReply={key}
                                          fetchData={fetchData}
                                        />
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            );
                          }}
                          components={{
                            Header: isLoading
                              ? Loading
                              : notPinned.length > 0 && NoMoreMessages,
                            EmptyPlaceholder: NoMessages,
                          }}
                        />
                      </div>
                      {pinned && pinned.length > 0 && (
                        <Alert
                          type="warning"
                          light
                          center
                          style={{ marginBottom: "0" }}
                        >
                          {pinned[0].content}
                        </Alert>
                      )}
                    </div>
                    <div className={styles["sc-forms"]}>
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <Tabs
                          selectedIndex={tabIndex}
                          onSelect={changeTab}
                          className={`${styles["react-tabs"]} ${styles["minimal"]}`}
                        >
                          {tabIndex === -1 && (
                            <TabList
                              className={`${styles["react-tabs__tab-list"]} tab-list--ref`}
                              style={
                                hasNoReadPermissionsInTabs()
                                  ? { padding: 0 }
                                  : undefined
                              }
                            >
                              {renderComponentWithPermission(
                                canReadTransactionMessages,
                                <Tab
                                  className={`${styles["react-tabs__tab"]}`}
                                  selectedClassName={`${styles["selected"]}`}
                                  data-cy="transaction_message_tab"
                                >
                                  <TabTitle
                                    icon={
                                      <Icon
                                        name={"question_answer"}
                                        color={"#63A1FF"}
                                      />
                                    }
                                    label={t("messages")}
                                  />
                                </Tab>
                              )}

                              {renderComponentWithPermission(
                                canReadTransactionMessages,
                                <Tab
                                  className={`${styles["react-tabs__tab"]}`}
                                  selectedClassName={`${styles["selected"]}`}
                                >
                                  <TabTitle
                                    icon={
                                      <Icon
                                        name={"sticky_note_2"}
                                        color={"#9E9E9E"}
                                      />
                                    }
                                    label={t("notes", { ns: "transactions" })}
                                  />
                                </Tab>
                              )}
                              {renderComponentWithPermission(
                                canReadTransactionSamplesVerificationTransport,
                                <Tab
                                  className={`${styles["react-tabs__tab"]}`}
                                  selectedClassName={`${styles["selected"]}`}
                                >
                                  <>
                                    <span ref={verificationcontainerRef}></span>
                                    <TabTitle
                                      icon={
                                        <Icon
                                          name={"task_alt"}
                                          color={"#9E9E9E"}
                                        />
                                      }
                                      label={t("verification")}
                                    />
                                  </>
                                </Tab>
                              )}
                              {renderComponentWithPermission(
                                canReadTransactionSamplesVerificationTransport,
                                <Tab
                                  className={`${styles["react-tabs__tab"]} react-tabs__ref transport-ref`}
                                  selectedClassName={`${styles["selected"]}`}
                                  disabled={true}
                                >
                                  <>
                                    <span ref={transportContainerRef}></span>
                                    <ClearButton
                                      type="button"
                                      onClick={() =>
                                        setOpenTransport(!openTransport)
                                      }
                                    >
                                      <TabTitle
                                        icon={
                                          <Icon
                                            name={"local_shipping"}
                                            color={"#9E9E9E"}
                                          />
                                        }
                                        label={t("transport")}
                                      />
                                      <Icon
                                        name={"arrow_drop_down"}
                                        color={"#7E7E7E"}
                                      />
                                    </ClearButton>
                                    {openTransport && (
                                      <Content
                                        ref={transportRef}
                                        position="absolute"
                                        style={{
                                          zIndex: 2,
                                        }}
                                        items={[
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleTransport(
                                                  "cost"
                                                )}
                                              >
                                                Cotización y contratación
                                              </ClearButton>
                                            ),
                                          },
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleTransport(
                                                  "load"
                                                )}
                                              >
                                                Carga
                                              </ClearButton>
                                            ),
                                          },
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleTransport(
                                                  "boarding"
                                                )}
                                              >
                                                Embarque
                                              </ClearButton>
                                            ),
                                          },
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleTransport(
                                                  "unload"
                                                )}
                                              >
                                                Descarga
                                              </ClearButton>
                                            ),
                                          },
                                        ]}
                                      />
                                    )}
                                  </>
                                </Tab>
                              )}
                              {renderComponentWithPermission(
                                canReadTransactionSamplesVerificationTransport,
                                <Tab
                                  className={`${styles["react-tabs__tab"]}`}
                                  selectedClassName={`${styles["selected"]}`}
                                >
                                  <TabTitle
                                    icon={<BoxIcon />}
                                    label={t("samples")}
                                  />
                                </Tab>
                              )}
                              {renderComponentWithPermission(
                                handleFinancesReadPerimssion,
                                <Tab
                                  className={`${styles["react-tabs__tab"]} react-tabs__ref return-ref`}
                                  selectedClassName={`${styles["selected"]}`}
                                  disabled
                                >
                                  <>
                                    <span ref={financesContainerRef}></span>
                                    <ClearButton
                                      type="button"
                                      onClick={() =>
                                        setOpenFinances(!openFinances)
                                      }
                                    >
                                      <TabTitle
                                        icon={
                                          <Icon
                                            name={"account_balance_wallet"}
                                            color={"#9E9E9E"}
                                          />
                                        }
                                        label={t("finances")}
                                      />
                                      <Icon
                                        name={"arrow_drop_down"}
                                        color={"#7E7E7E"}
                                      />
                                    </ClearButton>
                                    {openFinances && (
                                      <Content
                                        ref={financesRef}
                                        position="absolute"
                                        style={{
                                          zIndex: 2,
                                        }}
                                        items={[
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleFinances(
                                                  transactionFinancesTypes.EDIT
                                                )}
                                              >
                                                {t("edit_payment_modes", {
                                                  ns: "common",
                                                })}
                                              </ClearButton>
                                            ),
                                          },
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleFinances(
                                                  transactionFinancesTypes.REQUEST
                                                )}
                                              >
                                                {t("request-payment-to")}
                                              </ClearButton>
                                            ),
                                          },
                                          {
                                            component: (
                                              <ClearButton
                                                onClick={handleFinances(
                                                  transactionFinancesTypes.MAKE
                                                )}
                                              >
                                                {t("make-deposit")}
                                              </ClearButton>
                                            ),
                                          },
                                        ]}
                                      />
                                    )}
                                  </>
                                </Tab>
                              )}
                            </TabList>
                          )}

                          {renderComponentWithPermission(
                            canReadTransactionMessages,
                            <TabPanel
                              className={`${styles["react-tabs__tab-panel"]}`}
                              selectedClassName={`${styles["react-tabs__tab-panel--selected"]}`}
                            >
                              <TransactionsFormsProvider
                                initialState={formsData.message}
                              >
                                <TabPanelWrap
                                  title={t("new-message")}
                                  onClose={() => changeTab(-1)}
                                >
                                  <MessagesForm
                                    permission={canManageTransactionMessages}
                                    fetchData={fetchData}
                                    uuidComprador={
                                      buyerAndSellerUuids?.uuidBuyer
                                    }
                                    uuidVendedor={
                                      buyerAndSellerUuids?.uuidSeller
                                    }
                                    minimal
                                  />
                                </TabPanelWrap>
                              </TransactionsFormsProvider>
                            </TabPanel>
                          )}
                          {renderComponentWithPermission(
                            canReadTransactionMessages,
                            <TabPanel
                              className={`${styles["react-tabs__tab-panel"]}`}
                              selectedClassName={`${styles["react-tabs__tab-panel--selected"]}`}
                            >
                              <TransactionsFormsProvider
                                initialState={formsData.notes}
                              >
                                <TabPanelWrap
                                  title={t("notes")}
                                  onClose={() => changeTab(-1)}
                                >
                                  <NotesForm
                                    fetchData={fetchData}
                                    permission={canManageTransactionMessages}
                                    minimal
                                  />
                                </TabPanelWrap>
                              </TransactionsFormsProvider>
                            </TabPanel>
                          )}
                          {renderComponentWithPermission(
                            canReadTransactionSamplesVerificationTransport,
                            <TabPanel
                              className={`${styles["react-tabs__tab-panel"]}`}
                              selectedClassName={`${styles["react-tabs__tab-panel--selected"]}`}
                            >
                              <TransactionsFormsProvider
                                initialState={formsData.verification}
                              >
                                {formsData.loading ? (
                                  <Loading />
                                ) : (
                                  <Paper
                                    whiteMode
                                    style={{
                                      alignItems: "stretch",
                                      marginLeft: "-20px",
                                      marginRight: "-20px",
                                      padding: "15px",
                                    }}
                                  >
                                    <TabPanelWrap
                                      title={t("verification")}
                                      onClose={() => changeTab(-1)}
                                    >
                                      <VerificationForm
                                        fetchData={fetchData}
                                        permission={
                                          canManageTransactionSamplesVerificationTransport
                                        }
                                      />
                                    </TabPanelWrap>
                                  </Paper>
                                )}
                              </TransactionsFormsProvider>
                            </TabPanel>
                          )}
                          {renderComponentWithPermission(
                            canReadTransactionSamplesVerificationTransport,
                            <TabPanel
                              className={`${styles["react-tabs__tab-panel"]}`}
                              selectedClassName={`${styles["react-tabs__tab-panel--selected"]}`}
                            >
                              {transport !== "tracking" ? (
                                <TransactionsFormsProvider
                                  initialState={formsData.transport}
                                >
                                  {formsData.loading ? (
                                    <Loading />
                                  ) : (
                                    <TransportForm
                                      onClose={() => changeTab(-1)}
                                      type={transport}
                                      fetchData={fetchData}
                                      permission={
                                        canManageTransactionSamplesVerificationTransport
                                      }
                                    />
                                  )}
                                </TransactionsFormsProvider>
                              ) : (
                                <Paper
                                  whiteMode
                                  style={{ alignItems: "stretch" }}
                                >
                                  <TabPanelWrap
                                    title={t("tracking")}
                                    onClose={() => changeTab(-1)}
                                  >
                                    <TrackingForm data={data} />
                                  </TabPanelWrap>
                                </Paper>
                              )}
                            </TabPanel>
                          )}
                          {renderComponentWithPermission(
                            canReadTransactionSamplesVerificationTransport,
                            <TabPanel
                              className={`${styles["react-tabs__tab-panel"]}`}
                              selectedClassName={`${styles["react-tabs__tab-panel--selected"]}`}
                            >
                              <TransactionsFormsProvider
                                initialState={formsData.samples}
                              >
                                {formsData.loading ? (
                                  <Loading />
                                ) : (
                                  <SamplesForm
                                    onClose={() => changeTab(-1)}
                                    fetchData={fetchData}
                                    permission={
                                      canManageTransactionSamplesVerificationTransport
                                    }
                                  />
                                )}
                              </TransactionsFormsProvider>
                            </TabPanel>
                          )}
                          {renderComponentWithPermission(
                            handleFinancesReadPerimssion,
                            <TabPanel
                              className={`${styles["react-tabs__tab-panel"]}`}
                              selectedClassName={`${styles["react-tabs__tab-panel--selected"]}`}
                            >
                              <TransactionsFormsProvider
                                initialState={formsData.finances}
                              >
                                {formsData.loading ? (
                                  <Loading />
                                ) : (
                                  <FinancesForm
                                    onClose={() => changeTab(-1)}
                                    permission={handleFinancesManagePermission}
                                    fetchData={fetchData}
                                    type={returnType}
                                    uuidComprador={
                                      buyerAndSellerUuids?.uuidBuyer
                                    }
                                    uuidVendedor={
                                      buyerAndSellerUuids?.uuidSeller
                                    }
                                    sellerPaymentMethod={
                                      data?.sideData?.offerData
                                        ?.sellerPaymentMethod
                                    }
                                    buyerPaymentMethod={
                                      data?.sideData?.offerData
                                        ?.buyerPaymentMethod
                                    }
                                  />
                                )}
                              </TransactionsFormsProvider>
                            </TabPanel>
                          )}
                        </Tabs>
                      )}
                    </div>
                  </div>
                </Paper>
                <Paper
                  style={{
                    width: "450px",
                    minWidth: "450px",
                    maxWidth: "450px",
                    display: "block",
                  }}
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <Sidebar
                      data={data?.sideData}
                      headerData={data?.header}
                      log={data?.log}
                      fetchData={fetchData}
                      uuidEvent={id}
                      permission={canManageTransaction}
                    />
                  )}
                </Paper>
              </Row>
            </Container>
            <Spacer height={125} />
          </>
        }
      />
      {user && (
        <WebsocketsWrapper
          callback={webSocketCallback}
          room={WS_ROOM.transaction}
        />
      )}
    </>
  );
};

export default TransactionDetail;
