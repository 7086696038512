import {
  Icon,
  IconButton,
  Row,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import styles from "./DocumentLocationItemSelectable.module.scss";

function DocumentLocationItemSelectable({
  name,
  selected,
  onSelected = () => {},
  onNext = () => {},
}) {
  const [active, setActive] = useState(selected);

  return (
    <div
      className={`${styles["document-location-item"]} ${
        active ? styles["selected"] : ""
      }`}
      onClick={(e) => {
        e.preventDefault();
        console.log("item seleccionado");
        setActive(!active);
      }}
    >
      <div className={styles["inner"]}>
        <Row gap={8}>
          <Icon name={"folder"} color={WARM_NEUTRAL_50} />
          <Typography noMargin>{name}</Typography>
        </Row>
        <div className={styles["actions"]}>
          <div className={styles["radio"]}></div>
          <IconButton
            name="chevron_right"
            onClick={(e) => {
              console.log("siguiente carpeta");
              e?.stopPropagation();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DocumentLocationItemSelectable;

