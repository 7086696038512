import React, { useEffect, useRef, useState } from "react";
import deepmerge from "deepmerge";
import {
  Paper,
  Heading,
  Row,
  Button,
  Label,
  InputText,
  DropDown,
  Spacer,
  InputNumber,
  TextArea,
  IconButton,
  Icon,
  useMatchMedia,
  BREAKPOINT_MD,
  Switch,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import CatalogSearch from "../../components/ui/input/search/CatalogSearch";
import { handleOnChange } from "../../utils/functions";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../model/filters";
import { currencyOptions } from "../../utils/variables";
import FilesList from "../fields/FilesList";
import AdFiles from "../ads/AdFile";
import _ from "lodash";
import BusinessRegister from "../business/register/BusinessRegister";
import AddressSelector from "../../wrappers/address/AddressSelector";
import NewAddressForm from "../business/addresses/NewAddressForm";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { handleCRUD } from "../../utils/crud/functions";
import { useNavigate } from "react-router-dom";
import FormWrap from "../../components/ui/form-wrap/FormWrap";

const canEdit = (state, key, isEdit) => {
  return true;
};

function OpportunityForm({
  index,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  headerHeight,
  setHeaderHeight,
  isEdit,
  isMobile,
  isDirty,
  setIsDirty,
  uuid,
  ...props
}) {
  const navigate = useNavigate();
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { t, i18n } = useTranslation(["common", "ads"]);
  const filesBuffer = useRef([]);
  const [showNewBusiness, setShowNewBusiness] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [orgName, setOrgName] = useState(state?.orgName);
  // const [isPort, setIsPort] = useState(state?.address?.port);
  const [incotermName, setIncotermName] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const typeOptions = [
    {
      uuid: "purchase",
      text: t("purchase"),
    },
    {
      uuid: "sale",
      text: t("sale"),
    },
  ];

  const { data: currencyList } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "currency",
  });

  useEffect(() => {
    setOrgName(state?.orgName);
  }, [state?.orgName]);

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    let data = {
      ...state,
      ...{
        uuidUser: user?.userUuid,
        files: state?.files?.map((file) => file.uuid),
        amount: state?.amount * 100 * 1,
        proposedPrice: state?.proposedPrice * 100 * 1,
        material: !_.isEmpty(state?.material?.name)
          ? state?.material?.uuid
          : state?.material,
        uuidEvent: uuid,
        paymentMethod: !_.isEmpty(state?.paymentMethod?.name)
          ? state?.paymentMethod?.uuid
          : state?.paymentMethod,
        incoterm: !_.isEmpty(state?.incoterm?.name)
          ? state?.incoterm?.uuid
          : state?.incoterm,
      },
    };
    if (
      _.isEmpty(data?.selectedAddress?.port) ||
      !_.isEmpty(data?.selectedAddress?.uuidFavourite)
    ) {
      delete data?.selectedAddress?.port;
    } else {
      delete data?.selectedAddress?.port?.__typename;
    }

    if (data?.opportunityType === "sale") {
      delete data?.incoterm;
      delete data?.paymentMethod;
    }

    if (uuid) {
      delete data?.orgName;
      delete data?.updatedDate;
      delete data?.opportunityTypeDesc;
    } else {
      delete data?.uuidEvent;
    }

    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: data,
      successCallback: (e) => {
        setIsDirty(false);
        setTimeout(() => {
          if (isEdit || uuid) {
            navigate(-1);
          } else {
            navigate(`/opportunities/${e.uuidCreatedEvent}`, { replace: true });
          }
        }, 25);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const handleItemSelected = (key) => (option, open, setOpen) => {
    const obj = {};
    obj[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setOpen(!open);
  };

  const handleAddress =
    (dispatch = () => {}) =>
    (e = {}, isFav = false, keepId = false) => {
      const newState = deepmerge([], state);
      if (e?.port) {
        newState["selectedAddress"] = { ...newState?.selectedAddress, ...e };
      } else {
        if (isFav) {
          newState["selectedAddress"] = { ...e };
        } else {
          newState["selectedAddress"] = { ...newState?.selectedAddress, ...e };
          if (!keepId) delete newState["selectedAddress"]["id"];
        }
      }
      dispatch({ type: actions.UPDATE_INPUT, payload: newState });
    };

  const handleAddImage = (e) => {
    const newState = deepmerge([], state);
    const filesCpy = newState?.files || [];
    newState["files"] = [...filesCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleDeleteImage = (e) => {
    const newState = deepmerge([], state);
    newState["files"] = newState["files"]?.filter(
      (element) => e.uuid !== element.uuid
    );
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleSortImage = (dragIndex, hoverIndex) => {
    if (state?.files?.length === 0) return;
    const newState = deepmerge([], state);
    const bkState = deepmerge([], state);
    newState?.files?.splice(dragIndex, 1);
    newState?.files?.splice(hoverIndex, 0, bkState?.files?.[dragIndex]);
    filesBuffer.current = [...newState?.files];
  };

  const onBusinessRegistered = (e) => {
    if (!_.isEmpty(e?.uuid)) {
      handleOnChange(
        "opportunityOrg",
        dispatch
      )({
        target: {
          value: e?.uuid,
        },
      });
      setOrgName(e?.name);
    }
    setShowNewBusiness(false);
  };

  const onAddressRegistered = () => {
    setShowNewAddress(false);
  };

  if (showNewBusiness) {
    return (
      <div>
        <Paper
          style={{
            padding: "15px",
            gap: "15px",
            alignItems: "stretch",
          }}
        >
          <Row align="center" gap={10}>
            <IconButton
              type="button"
              name={"arrow_back"}
              onClick={() => {
                setShowNewBusiness(false);
              }}
            />
            <Heading type={5} style={{ margin: 0 }}>
              {t("new_business")}
            </Heading>
          </Row>

          <BusinessRegister onSuccess={onBusinessRegistered} />
        </Paper>
      </div>
    );
  }

  if (showNewAddress) {
    return (
      <div>
        <Paper
          style={{
            padding: "15px",
            gap: "15px",
            alignItems: "stretch",
          }}
        >
          <Row align="center" gap={10}>
            <IconButton
              type="button"
              name={"arrow_back"}
              onClick={() => {
                setShowNewAddress(false);
              }}
            />
            <Heading type={5} style={{ margin: 0 }}>
              {t("new_address")}
            </Heading>
          </Row>

          <NewAddressForm
            orgUuid={state?.opportunityOrg}
            onSuccess={onAddressRegistered}
          />
        </Paper>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmitWidget} preventImplicitSubmission>
      <Paper
        style={{
          padding: "15px",
          gap: "15px",
          alignItems: "stretch",
        }}
      >
        <Paper
          whiteMode
          style={{ padding: "15px 20px", alignItems: "stretch", gap: "15px" }}
        >
          <Row align="center" justify="space-between">
            <Heading type={5} style={{ margin: 0 }}>
              {t("business_singular")}
            </Heading>
            <Button
              text={t("new_business")}
              mode="outline-info"
              component="button"
              reduced
              tabindex="-1"
              type="button"
              onClick={() => setShowNewBusiness(true)}
            />
          </Row>
          <CatalogSearch
            required
            initialValue={!_.isEmpty(orgName) ? orgName : state?.opportunityOrg}
            inputProps={{
              position: "absolute",
              leadingIcon: (
                <Icon
                  name={"search"}
                  color={"#B1B1B1"}
                  style={{
                    position: "relative",
                    left: -10,
                    fontSize: 20,
                  }}
                />
              ),
              trailingIcon: <></>,
            }}
            catalogName={"org-bo-asigned"}
            onClickCallback={(option, open, setOpen) => {
              handleOnChange(
                "opportunityOrg",
                dispatch
              )({
                target: {
                  value: option.uuid,
                },
              });
              setOrgName(option.name);
            }}
            onChangeCallback={(e) => {
              if (_.isEmpty(e.target.value)) {
                handleOnChange(
                  "opportunityOrg",
                  dispatch
                )({
                  target: {
                    value: undefined,
                  },
                });
                setOrgName(undefined);
              }
            }}
            mode={"underline"}
            data-cy="opportunity_business_input"
          />
        </Paper>
        <FormWrap disabled={_.isEmpty(state?.opportunityOrg)}>
          <Paper
            whiteMode
            style={{
              padding: "15px 20px",
              alignItems: "stretch",
              gap: "20px",
            }}
          >
            <Row align="center" justify="space-between">
              <Heading type={5} style={{ margin: 0 }}>
                {t("address")}
              </Heading>
              <Button
                text={t("new_address")}
                mode="outline-info"
                reduced={true}
                component="button"
                tabindex="-1"
                type="button"
                onClick={() => setShowNewAddress(true)}
              />
            </Row>
            <Label text={`${t("saved_address")}*`} noPadding>
              <AddressSelector
                uuid={state?.selectedAddress?.uuidFavourite}
                uuidOrg={state?.opportunityOrg}
                savedDirectionProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  onChange: (option, open, setOpen) => {
                    handleOnChange(
                      "selectedAddress",
                      dispatch
                    )({
                      target: {
                        value: {
                          uuidFavourite: option.uuid,
                          type: option.type,
                        },
                      },
                    });
                    //handleAddress(dispatch)({ id: option?.uuid }, true);
                    setTimeout(() => {
                      setOpen(false);
                    }, 5);
                  },
                  val: state?.selectedAddress?.uuidFavourite,
                }}
              />
            </Label>
          </Paper>
          <Paper
            whiteMode
            style={{ padding: "15px 20px", alignItems: "stretch" }}
          >
            <Heading type={5} style={{ margin: 0 }}>
              {t("oportunity")}
            </Heading>
            <Spacer height={10} />
            <Row>
              <Switch
                isLabelInLeft={false}
                label={`${t("can_publish")}`}
                onChange={() => {
                  handleOnChange(
                    "canPublish",
                    dispatch
                  )({
                    target: {
                      value: !state?.canPublish,
                    },
                  });
                }}
                checked={state?.canPublish}
                readOnly={isEdit}
              />
            </Row>
            <Spacer height={10} />
            <Label text={`${t("title")}*`} noPadding>
              <InputText
                required
                placeholder={t("write-here")}
                value={state?.title}
                onChange={handleOnChange("title", dispatch)}
                mode="underline"
                name="title"
              />
            </Label>
            <Row gap={30} wrap={matches}>
              <Label text={`${t("opportunity_type")}*`} noPadding>
                <DropDown
                  placeholder={t("select-value")}
                  required
                  handleItemClick={(option, open, setOpen) => {
                    handleOnChange(
                      "opportunityType",
                      dispatch
                    )({
                      target: {
                        value: option.uuid,
                      },
                    });
                    setTimeout(() => {
                      setOpen(false);
                    }, 5);
                  }}
                  value={
                    typeOptions.find((f) => f.uuid === state?.opportunityType)
                      ?.text
                  }
                  options={typeOptions}
                  mode="underline"
                />
              </Label>
              <Label text={`${t("material")}*`} noPadding>
                <CatalogSearch
                  inputProps={{
                    required: true,
                  }}
                  initialValue={state?.material?.name}
                  catalogName={"material-autocomplete"}
                  onClickCallback={(option, open, setOpen) => {
                    handleOnChange(
                      "material",
                      dispatch
                    )({
                      target: {
                        value: option.uuid,
                      },
                    });
                    setTimeout(() => {
                      setOpen(false);
                    }, 5);
                  }}
                  mode="underline"
                />
              </Label>
            </Row>
            <Row gap={30} wrap={matches}>
              <Label text={`${t("amount")}*`} noPadding>
                <Row gap={15}>
                  <InputNumber
                    readOnly={!canEdit(state, "amount", isEdit)}
                    min={1}
                    step={".01"}
                    required={true}
                    placeholder={t("write-here")}
                    name="amount"
                    onChange={handleOnChange("amount", dispatch)}
                    value={state?.amount}
                    mode="underline"
                  />
                  <div style={{ maxWidth: "40px" }}>
                    <InputText value={"t"} readOnly mode="underline" />
                  </div>
                </Row>
              </Label>
              <Label text={`${t("ad-proposed-price")}*`} noPadding>
                <Row gap={15}>
                  <InputNumber
                    readOnly={!canEdit(state, "proposedPrice", isEdit)}
                    min={1}
                    step={".01"}
                    required={true}
                    placeholder={t("write-here")}
                    name="proposedPrice"
                    onChange={handleOnChange("proposedPrice", dispatch)}
                    value={state?.proposedPrice}
                    mode="underline"
                  />
                  <DropDown
                    readOnly={!canEdit(state, "currency", isEdit)}
                    id="currency"
                    name="currency"
                    placeholder={t("select-value")}
                    options={
                      currencyList
                        ? currencyList.map((item) => ({
                            uuid: item.uuid,
                            text: item?.name?.includes("%")
                              ? item.name
                              : `${item.name}/t`,
                          }))
                        : currencyOptions
                    }
                    required={true}
                    value={
                      currencyList
                        ? currencyList
                            ?.find((f) => f.uuid === state?.currency)
                            ?.name.includes("%")
                          ? currencyList?.find(
                              (f) => f.uuid === state?.currency
                            )?.name
                          : `${
                              currencyList?.find(
                                (f) => f.uuid === state?.currency
                              )?.name
                            }/t`
                        : currencyOptions
                            ?.find((f) => f.uuid === state?.currency)
                            ?.name.includes("%")
                        ? currencyOptions?.find(
                            (f) => f.uuid === state?.currency
                          )?.name
                        : `${
                            currencyOptions?.find(
                              (f) => f.uuid === state?.currency
                            )?.name
                          }/t`
                    }
                    handleItemClick={
                      canEdit(state, "currency", isEdit)
                        ? handleItemSelected("currency")
                        : undefined
                    }
                    style={{ maxWidth: "130px" }}
                    mode="underline"
                  />
                </Row>
              </Label>
            </Row>

            {state?.opportunityType === "purchase" && (
              <>
                <Row gap={30} wrap={matches}>
                  <Label text={`${t("incoterm")}*`} noPadding>
                    <CatalogSearch
                      preload
                      avoidReload
                      disableEnterText
                      inputProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        style: {
                          marginBottom: 20,
                        },
                        mode: "underline",
                      }}
                      initialValue={
                        state?.incoterm?.name || state?.incoterm || incotermName
                      }
                      catalogName={"incoterms-purchase"}
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        // if (option.extra === "port") {
                        //   setIsPort(true);
                        //   handleAddress(dispatch)(
                        //     {
                        //       port: "empty",
                        //     },
                        //     false,
                        //     true
                        //   );
                        // } else {
                        //   setIsPort(false);
                        //   handleAddress(dispatch)(
                        //     {
                        //       port: undefined,
                        //     },
                        //     false,
                        //     true
                        //   );
                        // }
                        handleOnChange(
                          "incoterm",
                          dispatch
                        )({
                          target: {
                            value: option.uuid,
                          },
                        });
                        setIncotermName(option?.name);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                      mode="underline"
                    />
                  </Label>

                  <Label text={`${t("payment-method")}*`} noPadding>
                    <CatalogSearch
                      withSearch
                      preload
                      disableEnterText
                      avoidReload
                      inputProps={{
                        readOnly: !canEdit(state, "paymentMethod", isEdit),
                        style: {
                          marginBottom: 20,
                        },
                        required: true,
                        mode: "underline",
                      }}
                      initialValue={
                        state?.paymentMethod?.name ||
                        state?.paymentMethod ||
                        paymentMethodName
                      }
                      catalogName={"payment-method-opportunity"}
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        handleOnChange(
                          "paymentMethod",
                          dispatch
                        )({
                          target: {
                            value: option.uuid,
                          },
                        });
                        setPaymentMethodName(option?.name);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                      mode="underline"
                    />
                  </Label>
                </Row>
              </>
            )}
            {/* {isPort && (
              <>
                <Label text={`${t("delivery_port")}*`} noPadding>
                  <CatalogSearch
                    remote
                    id="SelectedPort"
                    inputProps={{
                      readOnly: !canEdit(state, "", isEdit),
                      required: true,
                      placeholder: t("write-or-select"),
                      mode: "underline",
                    }}
                    initialValue={
                      state?.selectedAddress?.port &&
                      state?.selectedAddress?.port !== "empty"
                        ? state?.selectedAddress?.port?.name ||
                          state?.selectedAddress?.port
                        : undefined
                    }
                    catalogName={"ports"}
                    avoidInitalLoad={true}
                    onClickCallback={(
                      option,
                      open,
                      setOpen,
                      value,
                      setValue
                    ) => {
                      handleAddress(dispatch)({
                        port: option.uuid,
                      });
                      setTimeout(() => {
                        setOpen(false);
                      }, 100);
                    }}
                    mode="underline"
                  />
                </Label>
              </>
            )} */}

            <Label text={`${t("specifications")}*`} noPadding>
              <TextArea
                required={true}
                readOnly={!canEdit(state, "specification", isEdit)}
                placeholder={t("ad-material-other-details")}
                value={state?.specification}
                onChange={handleOnChange("specification", dispatch)}
                mode="underline"
              />
            </Label>

            {/* <Label text={t("images")} noPadding> */}
            <>
              <div>
                <AdFiles
                  readOnly={!canEdit(state, "images", isEdit)}
                  required={
                    state?.opportunityType === "sale" && _.isEmpty(state?.files)
                  }
                  files={state?.files}
                  placeholder={t("select-drop-files")}
                  dropPlaceholder={t("drop-files")}
                  afterUpload={(e) => {
                    if (!_.isEmpty(e)) {
                      handleAddImage(e[0]);
                    }
                  }}
                  onChange={(e) => {}}
                  type="ad"
                  iconStyles={{
                    background: "#F5F5F5",
                    borderRadius: 15,
                    padding: "7px 12px",
                  }}
                  t={t}
                />
              </div>
            </>
            {/* </Label> */}
            <FilesList
              readOnly={!canEdit(state, "images", isEdit)}
              files={state?.files?.map((f, index) => ({
                ...f,
                index,
              }))}
              handleDrop={() => {
                dispatch({
                  type: actions.UPDATE_INPUT,
                  payload: { ...state, files: filesBuffer.current },
                });
                filesBuffer.current = [];
              }}
              handleSort={handleSortImage}
              handleDelete={
                canEdit(state, "files", isEdit) ? handleDeleteImage : undefined
              }
            />
          </Paper>
          <Row justify="flex-end">
            <Button
              fullwidth={matches ? true : undefined}
              component="button"
              mode="info"
              text={t(uuid ? "update" : "save")}
              loading={submitLoading}
              style={
                submitLoading
                  ? { paddingLeft: "4rem", paddingRight: "4rem" }
                  : {}
              }
            />
          </Row>
        </FormWrap>
      </Paper>
    </form>
  );
}

export default OpportunityForm;
