import { AutoComplete, SearchAutoComplete } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { getCatalog } from "../../../../model/filters";
import { useGQL } from "../../../../utils/gql/hooks";
import { useTranslation } from "../../../../utils/hooks";
import _ from "lodash";

const CatalogSearch = ({
  preload = false,
  remote,
  initialValue,
  catalogName,
  onChangeCallback,
  onClickCallback,
  optionsAdapter,
  valueAdapter,
  cleanAfterSelected,
  isFilterCatalog = false,
  inputProps = {},
  searchProps = {},
  searchInputProps = {},
  withSearch = false,
  avoidReload = false,
  threshold = 2,
  ...props
}) => {
  const { t, i18n } = useTranslation("common");
  const { data, setData, fetchData } = useGQL(getCatalog, {}, true);
  const [value, setValue] = useState(initialValue);
  const nodeRefValue = useRef(initialValue);
  const localSearchProps = {
    handleOnChangeSearch: undefined,
    searchValue: undefined,
    ...searchProps,
  };

  useEffect(() => {
    if (preload || (initialValue && !data)) {
      initialLoad();
    }
  }, []);

  const initialLoad = async () => {
    setValue(initialValue);
    const params = {
      locale: i18n.language,
      name: catalogName,
    };
    if (!preload) {
      params.term = initialValue;
    }
    await fetchData(params);
  };

  const handleOnChange = async (e, open, setOpen, loading, setLoading) => {
    nodeRefValue.current = e.target?.value;
    if (!avoidReload) {
      const val = e.target.value;
      setValue(val);
      setLoading(true);
      if (val.length > threshold && !preload) {
        await fetchData({
          locale: i18n.language,
          name: catalogName,
          term: val,
        });
      }
      setLoading(false);
      if (onChangeCallback)
        onChangeCallback(
          e,
          open,
          setOpen,
          loading,
          setLoading,
          value,
          setValue,
          nodeRefValue.current
        );
    }
  };

  const handleItemClick = (option, open, setOpen, e) => {
    if (!props.multiple) {
      setValue(
        cleanAfterSelected
          ? ""
          : valueAdapter
          ? valueAdapter(option.name)
          : option.name
      );
    }
    if (cleanAfterSelected) setData([]);
    if (onClickCallback)
      onClickCallback(option, open, setOpen, value, setValue, e);
  };

  const applyInputProps = () => {
    if (isFilterCatalog) {
      const newProps = { ...(inputProps || {}) };
      const margin = value ? {} : { marginBottom: 0 };
      newProps.inputStyles = {
        ...(inputProps.inputStyles || {}),
        ...margin,
      };
      return newProps;
    }
    return inputProps;
  };

  const noResultsFound = () => <li>{t("write-to-results")}</li>;

  if (withSearch) {
    return (
      <SearchAutoComplete
        placeholder={t("select-value")}
        handleOnChange={handleOnChange}
        value={value}
        handleOnClickWithEvent={(e, value, open, setOpen) => setOpen(!open)}
        options={
          _.isEmpty(data?.errors)
            ? optionsAdapter
              ? optionsAdapter(data || [])
              : data || []
            : []
        }
        model={{ uuid: "uuid", text: "name" }}
        handleItemClick={handleItemClick}
        renderNoResultsItem={
          remote && nodeRefValue.current.length <= threshold
            ? noResultsFound
            : undefined
        }
        autocomplete="off"
        defaultOpen={isFilterCatalog}
        {...inputProps}
        inputProps={{ placeholder: t("write-or-select"), ...searchInputProps }}
        {...localSearchProps}
      />
    );
  }

  return (
    <AutoComplete
      remote={remote}
      placeholder={`${t("write-here")} ${props?.required ? "*" : ""}`}
      handleOnChange={handleOnChange}
      value={value}
      handleOnClickWithEvent={(e, value, open, setOpen) => {
        if (!isFilterCatalog) setOpen(!open);
      }}
      options={
        _.isEmpty(data?.errors)
          ? optionsAdapter
            ? optionsAdapter(data || [])
            : data || []
          : []
      }
      model={{ uuid: "uuid", text: "name" }}
      handleItemClick={handleItemClick}
      renderNoResultsItem={
        remote && nodeRefValue.current?.length <= threshold
          ? noResultsFound
          : undefined
      }
      autocomplete="off"
      defaultOpen={isFilterCatalog}
      {...applyInputProps()}
      {...props}
    />
  );
};

export default CatalogSearch;
