import {
  Empty,
  Grid,
  Card,
  IconItem,
  Padding,
  Pagination,
  Center,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import React, { useRef } from "react";
import { useTranslation } from "../../../utils/hooks";
import Text from "../text/Text";
import {
  adStatus,
  currencySymbolMapWithWeight,
  mapSlugToChip,
  mapSlugToChipDoubleGrid,
  modalTypes,
  TEXT_COLOR_LIGHT,
} from "../../../utils/variables";
import { useAdActions } from "../../../utils/ads/hooks";
import ModalFactory from "../modal/ModalFactory";

/**
 * Ads component.
 * @param {Object} props - Component properties
 * @param {Object} props.data - Ads data
 * @param {String} props.emptyMessageCode - Empty results key to translate.
 * @returns {JSX.Element}
 */
const Ads = ({
  data,
  emptyMessageCode,
  link,
  pagination,
  hasActions = true,
  fetchData,
  permission,
  ...props
}) => {
  const { t } = useTranslation(["common", "ads"]);
  const {
    renderActions,
    titleMessage,
    bodyMessage,
    okMessage,
    open,
    setOpen,
    type,
    handleEditAction,
    handleCloneAction,
    handleUnpublishAction,
    handlePublishAction,
    handleBlockAction,
    handleUnBlockAction,
    handleRestoreAction,
    handleDemandCoveredAction,
    handleRemovedAction,
  } = useAdActions();
  const adUuid = useRef();

  const renderActionsComponent = (ad) => {
    return ad?.status ? renderActions(ad?.status, ad?.transactionsActive) : [];
  };

  return data?.ads?.length === 0 ? (
    <Padding style={{ paddingTop: 35 }}>
      <Empty
        heading={t(emptyMessageCode)}
        icon="ads"
        iconWidth={230}
        iconHeight={272}
      />
    </Padding>
  ) : (
    <>
      <Grid style={{ padding: 0, maxWidth: "none" }}>
        {data?.ads?.map((ad, index) => {
          const description = ad?.isriCode
            ? `${ad?.materialType} (${ad?.isriCode})`
            : ad?.materialType;
          return (
            <Card
              uuid={ad?.slug}
              title={ad?.title}
              disabled={ad?.status === adStatus.COVERED}
              subheading={ad?.materialComposition}
              description={description}
              isFav={ad?.isFav}
              price={ad?.price}
              noPriceComponent={
                <Text style={{ fontSize: 20 }}>{t("no-proposed-price")}</Text>
              }
              currencySymbol={currencySymbolMapWithWeight[ad?.currency]}
              link={link ? `${link}/${ad?.slug}` : ad?.link}
              isSell={ad?.adType === "sale"}
              featuredImage={ad?.adImage}
              hideOnShare
              hideOnFav
              status={mapSlugToChipDoubleGrid[ad?.status]}
              offers={
                parseInt(ad?.transactionsActive) > 0
                  ? ad?.transactionsActive
                  : undefined
              }
              actionsProps={
                ad?.status
                  ? {
                      items: permission
                        ? permission()
                          ? renderActionsComponent(ad)
                          : []
                        : renderActionsComponent(ad),
                      openCloseCallback: (open) => {
                        adUuid.current = ad?.slug;
                      },
                    }
                  : undefined
              }
              translation={{
                status: t(ad?.status),
                offers: ad?.transactionsActive,
              }}
              containerStyles={{
                zIndex: 25 - index,
              }}
              statusActionsStyle={{
                zIndex: 2,
              }}
              {...props}
            />
          );
        })}
      </Grid>
      {pagination && (
        <>
          <Spacer height={50} />
          <Center>
            <Pagination {...pagination} />
          </Center>
        </>
      )}
      <ModalFactory
        key={adUuid.current}
        type={modalTypes.ADS_ACTIONS}
        open={open}
        setOpen={setOpen}
        titleMessage={titleMessage}
        bodyMessage={bodyMessage}
        okMessage={okMessage}
        adUuid={adUuid.current}
        handleEditAction={handleEditAction}
        handleCloneAction={handleCloneAction}
        handleUnpublishAction={handleUnpublishAction}
        handlePublishAction={handlePublishAction}
        handleBlockAction={handleBlockAction}
        handleUnBlockAction={handleUnBlockAction}
        handleRestoreAction={handleRestoreAction}
        handleDemandCoveredAction={handleDemandCoveredAction}
        handleRemovedAction={handleRemovedAction}
        typeAd={type}
        fetchData={fetchData}
      />
    </>
  );
};

export default Ads;
