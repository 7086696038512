import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
  InputText,
  Row,
  Flex,
  Label,
  Radio,
  Paper,
  Heading,
  Chip,
  InputNumber,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getCatalogNoTerm } from "../../../model/filters";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useCRUD } from "../../../utils/crud/hooks";
import { handleCountryClick, handleOnChange } from "../../../utils/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import CatalogSearch from "../../ui/input/search/CatalogSearch";
import InputCountries from "../../ui/input/country/Country";
import _ from "lodash";
import deepmerge from "deepmerge";
import { LoadCharacteristicasTypes } from "../../../utils/variables";
import ValidateGroup from "../../ui/input/validation/ValidateGroup";
import UnderlinedLink from "../../ui/link/UnderlinedLink";

const defaultData = {
  lerCodes: [],
  load: {},
};

const ModalEditLoadCharacteristics = ({
  open,
  setOpen,
  isEdit,
  data,
  ...props
}) => {
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState();
  const { t, i18n } = useTranslation(["logistics", "ads", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();
  const { data: catalogData, loading: catalogLoading } = useGQL(
    [getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm],
    [
      {
        locale: i18n.language,
        name: "transporter-equipments",
      },
      {
        locale: i18n.language,
        name: "logistics-services",
      },
      {
        locale: i18n.language,
        name: "logistics-coverage",
      },
      {
        locale: i18n.language,
        name: "logistics-activity",
      },
    ]
  );
  const [openAddLerCode, setOpenAddLerCode] = useState(false);
  const [openAddLerCodeBuyer, setOpenAddLerCodeBuyer] = useState(false);
  const isGranel = state?.load?.type === LoadCharacteristicasTypes.GRANEL;
  const isSacas = state?.load?.type === LoadCharacteristicasTypes.SACAS;
  const isPalet = state?.load?.type === LoadCharacteristicasTypes.PALET;

  useEffect(() => {
    if (isEdit) {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: data || defaultData,
      });
    } else {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: defaultData,
      });
    }
  }, []);

  const handleAddLerCode = (e, isBuyer = false) => {
    const newState = deepmerge([], state);
    if (isBuyer) {
      const lerCodesCpy = newState?.lerCodesBuyer || [];
      newState["lerCodesBuyer"] = [...lerCodesCpy, e || ""];
    } else {
      const lerCodesCpy = newState?.lerCodes || [];
      newState["lerCodes"] = [...lerCodesCpy, e || ""];
    }

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleRemoveCollection = (tIndex, key) => {
    const newState = deepmerge([], state);
    newState[key] = newState[key].filter((element, i) => i !== tIndex);
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleLoadCharacteristics = (type) => () => {
    const obj = { load: { ...(state.load || {}) } };
    if (obj.load?.type !== type) {
      obj.load.type = type;
    }
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  const handleLoadOnChange = (key, dispatch) => (e) => {
    const obj = { load: { ...(state.load || {}) } };
    obj.load[key] = e.target.value;
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  const handleSubmit = async () => {
    console.log("handleSubmit");
    console.log(state);
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
        dispatch({ type: actions.UPDATE_INPUT, payload: defaultData });
      }}
      title={t("edit_load_characteristics")}
      cancelText={t("cancel")}
      submitText={t("save-changes")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={handleSubmit}
      loading={loading}
      actionsMode="end"
      submitButtonMode="info"
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <Paper style={{ alignItems: "stretch" }}>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              style={{ gap: "15px" }}
            >
              <Paper whiteMode>
                <div style={{ width: "100%" }}>
                  <Row gap={10} align="center" justify="space-between">
                    <Label
                      text={t("ler-code")}
                      noPadding
                      bold
                      dark
                      style={{ width: "auto" }}
                    ></Label>
                  </Row>
                  <Row gap={10} align="center" justify="space-between">
                    <Label
                      text={t("seller")}
                      noPadding
                      small
                      dark
                      style={{ width: "auto" }}
                    ></Label>
                    <UnderlinedLink
                      onClick={() => {
                        setOpenAddLerCode(!openAddLerCode);
                      }}
                    >
                      {t(openAddLerCode ? "close" : "add_another")}
                    </UnderlinedLink>
                  </Row>
                  {openAddLerCode && (
                    <CatalogSearch
                      catalogName={"ler"}
                      optionsAdapter={(data) => {
                        return data.map((d) => {
                          return {
                            uuid: d.uuid,
                            name: `${d.uuid} ${d.name}`,
                          };
                        });
                      }}
                      inputProps={{
                        required: false,
                        style: {
                          marginBottom: "10px",
                        },
                        mode: "underline",
                      }}
                      cleanAfterSelected
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        handleAddLerCode(option?.uuid);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                    />
                  )}
                  {!_.isEmpty(state?.lerCodes) && (
                    <Row gap={15} wrap justify="flex-start">
                      {state?.lerCodes?.map((e, i) => {
                        return (
                          <Chip
                            key={`ler_code_${i}`}
                            text={e?.name ? e.name : e}
                            big
                            handleClick={() =>
                              handleRemoveCollection(i, "lerCodes")
                            }
                            showIcon
                            type={"white-outlined"}
                          />
                        );
                      })}
                    </Row>
                  )}

                  <Row
                    gap={10}
                    align="center"
                    justify="space-between"
                    style={{ paddingTop: "15px" }}
                  >
                    <Label
                      text={t("buyer")}
                      noPadding
                      small
                      dark
                      style={{ width: "auto" }}
                    ></Label>
                    <UnderlinedLink
                      onClick={() => {
                        setOpenAddLerCodeBuyer(!openAddLerCodeBuyer);
                      }}
                    >
                      {t(openAddLerCodeBuyer ? "close" : "add_another")}
                    </UnderlinedLink>
                  </Row>
                  {openAddLerCodeBuyer && (
                    <CatalogSearch
                      catalogName={"ler"}
                      optionsAdapter={(data) => {
                        return data.map((d) => {
                          return {
                            uuid: d.uuid,
                            name: `${d.uuid} ${d.name}`,
                          };
                        });
                      }}
                      inputProps={{
                        required: false,
                        style: {
                          marginBottom: "10px",
                        },
                        mode: "underline",
                      }}
                      cleanAfterSelected
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        handleAddLerCode(option?.uuid, true);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                    />
                  )}
                  {!_.isEmpty(state?.lerCodesBuyer) && (
                    <Row gap={15} wrap justify="flex-start">
                      {state?.lerCodesBuyer?.map((e, i) => {
                        return (
                          <Chip
                            key={`ler_code_${i}`}
                            text={e?.name ? e.name : e}
                            big
                            handleClick={() =>
                              handleRemoveCollection(i, "lerCodesBuyer")
                            }
                            showIcon
                            type={"white-outlined"}
                          />
                        );
                      })}
                    </Row>
                  )}
                </div>
              </Paper>
              <Paper whiteMode>
                <div style={{ width: "100%" }}>
                  <Label text={t("load-format")} small noPadding></Label>
                  <Flex
                    justifyContent={"flex-start"}
                    style={{ gap: 15, marginTop: "5px", marginBottom: "15px" }}
                  >
                    <Chip
                      big
                      text={t(LoadCharacteristicasTypes.GRANEL)}
                      handleClick={handleLoadCharacteristics(
                        LoadCharacteristicasTypes.GRANEL
                      )}
                      type={
                        isGranel ? "white-outlined-active" : "white-outlined"
                      }
                      showIcon={isGranel}
                    />
                    <Chip
                      big
                      text={t(LoadCharacteristicasTypes.SACAS)}
                      handleClick={handleLoadCharacteristics(
                        LoadCharacteristicasTypes.SACAS
                      )}
                      type={
                        isSacas ? "white-outlined-active" : "white-outlined"
                      }
                      showIcon={isSacas}
                    />
                    <Chip
                      big
                      text={t(LoadCharacteristicasTypes.PALET)}
                      handleClick={handleLoadCharacteristics(
                        LoadCharacteristicasTypes.PALET
                      )}
                      type={
                        isPalet ? "white-outlined-active" : "white-outlined"
                      }
                      showIcon={isPalet}
                    />
                  </Flex>
                  <ValidateGroup
                    value={isSacas || isPalet || isGranel ? 1 : undefined}
                  />
                  {isGranel && (
                    <Label
                      text={t("load-volume")}
                      small
                      noPadding
                      htmlFor="volume"
                    >
                      <InputNumber
                        required
                        id="volume"
                        value={state?.load?.volume}
                        placeholder={t("write-here")}
                        min={1}
                        step={"0.01"}
                        mode="underline"
                        onChange={handleLoadOnChange("volume", dispatch)}
                      />
                    </Label>
                  )}
                  {isSacas || isPalet ? (
                    <>
                      <Row gap={15} wrap={matches}>
                        <Label
                          text={t("load-bulk")}
                          small
                          noPadding
                          htmlFor="bulk"
                        >
                          <InputNumber
                            required
                            id="bulk"
                            value={state?.load?.bulk}
                            placeholder={t("write-here")}
                            min={1}
                            step={"0.01"}
                            mode="underline"
                            onChange={handleLoadOnChange("bulk", dispatch)}
                          />
                        </Label>
                        <Label
                          text={t("load-number")}
                          small
                          noPadding
                          htmlFor="loadNumber"
                        >
                          <InputNumber
                            required
                            id="loadNumber"
                            value={state?.load?.number}
                            placeholder={t("write-here")}
                            min={1}
                            step={"0.01"}
                            mode="underline"
                            onChange={handleLoadOnChange("number", dispatch)}
                          />
                        </Label>
                      </Row>
                      <Label
                        text={t("load-dimensions")}
                        small
                        noPadding
                        htmlFor="depth"
                      ></Label>
                      <Row gap={15} wrap={matches}>
                        <Label
                          text={t("depth")}
                          small
                          noPadding
                          htmlFor="depth"
                        >
                          <InputNumber
                            required
                            id="depth"
                            value={state?.load?.depth}
                            placeholder={t("write-here")}
                            min={1}
                            step={"0.01"}
                            mode="underline"
                            onChange={handleLoadOnChange("depth", dispatch)}
                          />
                        </Label>
                        <Label
                          text={t("width")}
                          small
                          noPadding
                          htmlFor="width"
                        >
                          <InputNumber
                            required
                            id="width"
                            value={state?.load?.width}
                            placeholder={t("write-here")}
                            min={1}
                            step={"0.01"}
                            mode="underline"
                            onChange={handleLoadOnChange("width", dispatch)}
                          />
                        </Label>
                        <Label
                          text={t("height")}
                          small
                          noPadding
                          htmlFor="height"
                        >
                          <InputNumber
                            required
                            id="height"
                            value={state?.load?.height}
                            placeholder={t("write-here")}
                            min={1}
                            step={"0.01"}
                            mode="underline"
                            onChange={handleLoadOnChange("height", dispatch)}
                          />
                        </Label>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Paper>
            </Flex>
          </Paper>
        </Container>
      }
    />
  );
};

export default ModalEditLoadCharacteristics;
