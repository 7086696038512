import {
  Flex,
  Heading,
  ImageBox,
  Row,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import GoBack from "../../../components/ui/back/GoBack";
import Breadcrumbs from "../../../components/ui/breadcrumbs/Breadcrumbs";

function TransactionHeader({
  imageUrl,
  breadcrumbs = [],
  loading,
  goBack,
  title,
  subtitle,
  rightArea = <></>,
  subHeaderRight = <></>,
}) {
  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="stretch"
        style={{ gap: "25px", padding: "15px 0" }}
      >
        <Row align="center" justify="flex-start">
          {goBack && (
            <>
              <GoBack onlyText={false} />
            </>
          )}
          {breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}
        </Row>
        <Row gap={12} align="center">
          <ImageBox url={imageUrl} />
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "7px", marginRight: "auto" }}
          >
            <Heading type={4} style={{ margin: 0 }}>
              {title}
            </Heading>
            <Typography type={2} style={{ margin: 0, color: "#7E7E7E" }}>
              {subtitle}
            </Typography>
          </Flex>
          {rightArea}
        </Row>
        {subHeaderRight}
      </Flex>
    </>
  );
}

export default TransactionHeader;
