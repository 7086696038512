import {
  Accordion,
  ClearButton,
  Container,
  Empty,
  Flex,
  Icon,
  Padding,
  Spacer,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import AddressesType from "./Type";
import { useTranslation } from "../../../utils/hooks";
import WidgetFieldSaveDiscard from "../../fields/Save";
import WidgetAddressEquipment from "./Equipment";
import WidgetAddressNew from "./New";
import WidgetAddressAlias from "./Alias";
import WidgetAddressCollectionDelivery from "./CollectionDelivery";
import WidgetAddressCountries from "./Countries";
import WidgetAddressPhone from "./Phone";
import WidgetAddressLocation from "./Location";
import WidgetAddressWarehouse from "./Warehouse";
import WidgetAddressesBySea from "./BySea";
import WidgetAddressSelectedPort from "./SelectedPort";
import styles from "./Delivery.module.scss";
import { handleCRUD } from "../../../utils/crud/functions";
import { useState } from "react";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import Separator from "../../../components/ui/separator/Separator";
import {
  GRAY_NEUTRAL_40,
  modalTypes,
  TEXT_COLOR_LIGHT,
} from "../../../utils/variables";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import {
  checkPermission,
  renderComponentWithPermission,
} from "../../../utils/functions";
import {
  buildAddresData,
  checkIfAddressIsDirty,
  dispatchAction,
} from "./functions";

const WidgetAddressesPickDelivery = ({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  bufferState,
  fetchData,
  loading,
  setLoading,
  checkIfStateIsDirty,
  extraData,
}) => {
  const { t } = useTranslation(["talk", "business", "common"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [openDeleteWarning, setDeleteWarning] = useState(false);
  const [deleteUuid, setDeleteUuid] = useState(false);
  const { permissionManage, permissionRead } = extraData || {};

  const handleDeleteAddress = async (uuid, e) => {
    e.stopPropagation();
    setDeleteUuid(uuid);
    setDeleteWarning(true);
  };

  const handleSubmitWidget = (index) => async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { ...state?.getOrgLocations?.[index] };
    delete data.__typename;
    const parsedData = buildAddresData(data);
    await handleCRUD({
      endpoint: `org/${id}/location/${data.id}`,
      method: "PUT",
      data: { address: parsedData },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
    });
  };

  return (
    <>
      <Flex justifyContent={"space-between"}>
        <h3 className={styles["sc-title"]}>{t("delivery-address")}</h3>
        {renderComponentWithPermission(
          permissionManage,
          <WidgetAddressNew id={id} fetchData={fetchData} />
        )}
      </Flex>
      <Spacer height={20} />
      {!state?.getOrgLocations || state?.getOrgLocations.length === 0 ? (
        <Padding style={{ paddingTop: 80 }}>
          <Empty
            heading={t("no-saved-address")}
            icon="addresses"
            iconWidth={220}
            iconHeight={220}
          />
        </Padding>
      ) : (
        state?.getOrgLocations?.map((address, index) => {
          const alias = address?.["alias"] || t("address");
          const isPort = address?.port && address?.port !== "empty";
          return (
            <Accordion
              key={address.id}
              title={alias}
              defaultOpen={false}
              headerExtra={
                <>
                  <AddressesType address={address} separator />
                  {renderComponentWithPermission(
                    permissionManage,
                    <>
                      <ClearButton
                        style={{
                          display: "flex",
                          flexGrow: address?.type === "" ? 1 : 0,
                          justifyContent:
                            address?.type === "" ? "flex-end" : "flex-start",
                        }}
                        onClick={(e) => handleDeleteAddress(address.id, e)}
                      >
                        <Icon
                          name="delete"
                          color={TEXT_COLOR_LIGHT}
                          style={{ fontSize: 18, marginRight: 10 }}
                        />
                        <span
                          style={{
                            fontWeight: 300,
                            fontSize: 12,
                            lineHeight: "18px",
                            textDecoration: "underline",

                            color: "#626262",
                          }}
                        >
                          {t("remove")}
                        </span>
                      </ClearButton>
                      <Separator color={GRAY_NEUTRAL_40} />
                    </>
                  )}
                </>
              }
            >
              <form onSubmit={handleSubmitWidget(index)}>
                <Container>
                  {renderComponentWithPermission(
                    permissionRead,
                    <div className="row">
                      <div className="col-12">
                        <WidgetAddressAlias
                          alias={address?.["alias"] || t("address")}
                          index={index}
                          state={state}
                          dispatch={dispatch}
                          permissionManage={permissionManage}
                        />
                      </div>
                    </div>
                  )}
                  {renderComponentWithPermission(
                    permissionRead,
                    <div className="row">
                      <div className="col-12">
                        <WidgetAddressCollectionDelivery
                          address={address}
                          index={index}
                          state={state}
                          dispatch={dispatch}
                          actions={actions}
                          permissionManage={permissionManage}
                        />
                      </div>
                    </div>
                  )}
                  {renderComponentWithPermission(
                    permissionRead,
                    <div className="row">
                      <div className="col-12">
                        <WidgetAddressesBySea
                          index={index}
                          text={t("is-by-sea")}
                          checked={isPort}
                          onChange={
                            checkPermission(permissionManage)
                              ? (e) => {
                                  dispatchAction(
                                    index,
                                    "port",
                                    e.target.checked
                                      ? {
                                          uuid: "",
                                          name: "",
                                        }
                                      : null,
                                    state,
                                    dispatch
                                  );
                                }
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  )}
                  {isPort ? (
                    <WidgetAddressSelectedPort
                      selectedPort={isPort ? address?.port : undefined}
                      onItemClick={
                        checkPermission(permissionManage)
                          ? (option) => {
                              dispatchAction(
                                index,
                                "port",
                                { uuid: option.uuid, name: option.name },
                                state,
                                dispatch
                              );
                            }
                          : undefined
                      }
                    />
                  ) : (
                    <>
                      <div className="row">
                        {renderComponentWithPermission(
                          permissionRead,
                          <div className="col-12 col-lg-6">
                            <WidgetAddressCountries
                              address={address}
                              index={index}
                              state={state}
                              dispatch={dispatch}
                              permissionManage={permissionManage}
                            />
                          </div>
                        )}
                        <div className="col-12 col-lg-6">
                          {renderComponentWithPermission(
                            permissionRead,
                            <WidgetAddressPhone
                              address={address}
                              index={index}
                              state={state}
                              dispatch={dispatch}
                              permissionManage={permissionManage}
                            />
                          )}
                        </div>
                      </div>
                      {renderComponentWithPermission(
                        permissionRead,
                        <WidgetAddressLocation
                          address={address}
                          index={index}
                          state={state}
                          dispatch={dispatch}
                          permissionManage={permissionManage}
                        />
                      )}

                      {renderComponentWithPermission(
                        permissionRead,
                        <WidgetAddressWarehouse
                          address={address}
                          index={index}
                          state={state}
                          dispatch={dispatch}
                          permissionManage={permissionManage}
                        />
                      )}

                      <div className="row">
                        {renderComponentWithPermission(
                          permissionRead,
                          <div className="col-12">
                            <WidgetAddressEquipment
                              address={address}
                              index={index}
                              state={state}
                              dispatch={dispatch}
                              permissionManage={permissionManage}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Container>
                <WidgetFieldSaveDiscard
                  permission={permissionManage}
                  isDirty={
                    bufferState.current && address
                      ? checkIfAddressIsDirty(
                          address,
                          index,
                          bufferState,
                          checkIfStateIsDirty
                        )
                      : false
                  }
                  handleDiscard={() => {
                    const newState = {
                      getOrgLocations: [...state?.getOrgLocations],
                    };
                    newState.getOrgLocations[index] =
                      bufferState.current?.getOrgLocations[index];
                    dispatch({ type: actions.INITIAL_LOAD, payload: newState });
                  }}
                  loading={submitLoading}
                />
              </form>
            </Accordion>
          );
        })
      )}
      <ModalFactory
        key={deleteUuid}
        type={modalTypes.USER_WARNING}
        open={openDeleteWarning}
        setOpen={setDeleteWarning}
        data={{ key: deleteUuid }}
        onSubmit={async (key) => {
          setLoading(true);
          await handleCRUD({
            endpoint: `org/${id}/location/${key}`,
            method: "DELETE",
            successCallback: async () => {
              await fetchData();
              setLoading(false);
            },
            messageOk: t("action-ok"),
            signOut,
            setDataUser,
          });
        }}
      />
    </>
  );
};

export default WidgetAddressesPickDelivery;
