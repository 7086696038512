import { Alert, Button, ClearButton } from "@scrapadev/scrapad-front-sdk";
import { format } from "date-fns";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { handleDownloadDocument } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import { logActions } from "../../../utils/variables";
import Chip from "../chip/Chip";
import Icon from "../icon/Icon";
import BoxIcon from "../icons/general/BoxIcon";
import Text from "../text/Text";
import DepositButton from "./DeposiButton";
import styles from "./Item.module.scss";
import MarkReceivedButton from "./PendingButton";

const lookUpTableType = {
  offers: {
    icon: <Icon name={"swap_horiz"} color={"#fff"} />,
    action: "offers",
  },
  messages: {
    icon: <Icon name={"chat_bubble_outline"} color={"#fff"} />,
    action: "messages",
  },
  contracts: {
    icon: <Icon name={"description"} color={"#fff"} />,
    action: "contracts",
  },
  samples: {
    icon: <BoxIcon color="#fff" />,
    action: "samples",
  },
  transport: {
    icon: <Icon name={"local_shipping"} color={"#fff"} />,
    action: "transport",
  },
  verification: {
    icon: <Icon name={"task_alt"} color={"#fff"} />,
    action: "verification",
  },
  payments: {
    icon: <Icon name={"account_balance_wallet"} color={"#fff"} />,
    action: "payments",
  },
  answers: {
    icon: (
      <Icon
        name={"shortcut"}
        color={"#fff"}
        style={{ transform: "scaleY(-1)" }}
      />
    ),
    action: "answers",
  },
  notes: {
    icon: <Icon name={"sticky_note_2"} color={"#fff"} />,
    action: "notes",
  },
  finish: {
    icon: <Icon name={"inventory_2"} color={"#fff"} />,
    action: "finish",
  },
};

/**
 * Item component for the logs.
 * @param {Object} props - Component properties.
 * @param {String} props.who - Who does the action.
 * @param {("offers"|"messages"|"contracts"|"samples"|"transport"|"verification"|"payments"|"answers"|"notes")} props.action - Action to show.
 * @param {("seller"|"buyer"|"logistics"|"finances"|"bo"|"system")} props.type - Type of item.
 * @returns {JSX.Element}
 */
const LogItem = ({
  uuid,
  uuidEvent,
  originatedBy,
  action,
  phase,
  type,
  date,
  content,
  contentData,
  hasResponseButton,
  hasReportButton,
  hasSignButton,
  hasReceivedPaymentButton,
  answer,
  documents,
  info,
  hasEventGeneratedAction,
  handleGeneratedAction,
  hasDepositDone,
  handleAnswer,
  handleSign,
  handleReceivedPayment,
  signLoading,
  fetchData,
  uuidComprador,
}) => {
  const textClass = answer
    ? styles["sc-logs--item-answer"]
    : styles["sc-logs--item-text"];
  const { t, i18n } = useTranslation(["common", "transactions"]);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  return (
    <div className={styles["sc-logs--item"]}>
      <div className={styles["sc-logs--item-header"]}>
        <Chip
          type={type}
          maxWidth={38}
          component={
            lookUpTableType[action]?.icon || (
              <Icon name={"info"} color={"#fff"} />
            )
          }
        />
        <p className={styles["sc-logs--item-who"]}>{originatedBy}</p>
        <span className={styles["sc-logs--item-separator"]}>-</span>
        <p className={styles["sc-logs--item-action"]}>
          {t(lookUpTableType[phase]?.action)}
        </p>
        {t(lookUpTableType[phase]?.action) ? (
          <span className={styles["sc-logs--item-separator"]}>-</span>
        ) : (
          <></>
        )}

        <p className={styles["sc-logs--item-date"]}>
          {format(Number(date), "kk:mm", {
            locale: i18n.getLanguageVariable(i18n.language),
          })}
        </p>
        {hasEventGeneratedAction && (
          <span className={styles["sc-logs--item-delete"]}>
            <ClearButton type="button" onClick={handleGeneratedAction}>
              <Icon name={"edit"} color={"#7e7e7e"} />
            </ClearButton>
          </span>
        )}
      </div>
      <div className={styles["sc-logs--item-body"]}>
        <div className={styles["sc-logs--item-text-info"]}>
          {hasReportButton ? (
            <Alert type="warning">{content}</Alert>
          ) : (
            <Text>{content}</Text>
          )}
        </div>
        <div
          className={textClass}
          style={
            contentData && contentData.length === 1 && documents
              ? { margin: 0 }
              : undefined
          }
        >
          {contentData &&
            contentData.map((data) => {
              if (data.key === "link") {
                return <></>;
              }
              return (
                <Text>
                  {data.key !== "text" && <span>{data.key} -</span>}
                  <span>{data.value}</span>
                </Text>
              );
            })}
          {(hasResponseButton || hasReportButton) && (
            <Button
              type="button"
              component="button"
              mode="normal"
              style={{ padding: "7px 17px", fontSize: 14 }}
              prefix={
                <Icon
                  name={"shortcut"}
                  color={"#7E7E7E"}
                  style={{ display: "flex", marginRight: "0.625rem" }}
                />
              }
              text={t("answer-button")}
              onClick={handleAnswer || undefined}
            />
          )}
          {hasSignButton && (
            <>
              <Button
                type="button"
                component="button"
                mode="normal"
                style={{ padding: "7px 17px", fontSize: 14 }}
                loading={signLoading}
                prefix={
                  <Icon
                    name={"draw"}
                    color={"#7E7E7E"}
                    style={{
                      display: "flex",
                      marginRight: "0.625rem",
                    }}
                  />
                }
                text={t("sign")}
                onClick={() => handleSign("ok") || undefined}
              />
              <Button
                type="button"
                component="button"
                mode="normal"
                style={{ padding: "7px 17px", fontSize: 14 }}
                loading={signLoading}
                prefix={
                  <Icon
                    name={"clear"}
                    color={"#7E7E7E"}
                    style={{ display: "flex", marginRight: "0.625rem" }}
                  />
                }
                text={t("reject")}
                onClick={() => handleSign("ko") || undefined}
              />
            </>
          )}
          {hasDepositDone && (
            <DepositButton
              fetchData={fetchData}
              uuidUser={uuidComprador}
              uuidEvent={uuidEvent}
              uuidDeposit={uuid}
            />
          )}
          {hasReceivedPaymentButton && (
            <MarkReceivedButton
              fetchData={fetchData}
              uuidEvent={uuidEvent}
              uuidDeposit={uuid}
            />
          )}
        </div>
        {documents &&
          documents.length > 0 &&
          documents.map((document) => {
            return (
              <div className={styles["sc-logs--item-contracts"]}>
                <ClearButton
                  onClick={() =>
                    handleDownloadDocument(
                      document.value.split("#")[1],
                      signOut,
                      setDataUser
                    )
                  }
                >
                  <div className={styles["sc-logs--item-contract"]}>
                    <Icon name={"attach_file"} />
                    <span>{document.value.split("#")[0]}</span>
                  </div>
                </ClearButton>
              </div>
            );
          })}
        {info && (
          <div className={styles["sc-logs--item-info"]}>
            <Icon name={"info"} />
            <Text style={{ fontSize: 12 }}>{info}</Text>
          </div>
        )}
      </div>
      {answer && (
        <div className={styles["sc-logs--item-answers"]}>
          <LogItem
            key={answer.key}
            type={"bo"}
            originatedBy={answer.originatedBy}
            action={"answers"}
            phase={logActions.MESSAGE}
            date={answer.date}
            hasResponseButton={false}
            content={answer.content}
            contentData={answer.contentData}
            documents={answer?.contentData?.filter((f) => f.key === "link")}
          />
        </div>
      )}
    </div>
  );
};

export default LogItem;
