import {
  Center,
  Flex,
  Heading,
  Paper,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../../../utils/hooks";
import DocumentsBreadcrumbs from "../documents-breadcrumbs/DocumentsBreadcrumbs";
import DocumentLocationItemSelectable from "./DocumentLocationItemSelectable";
import DocumentLocationSelectorEmptyIcon from "./DocumentLocationSelectorEmptyIcon";

function DocumentLocationSelector() {
  const { t } = useTranslation(["common"]);
  return (
    <div>
      <Flex flexDirection="column" alignItems="stretch">
        <Heading type={7}>Destino:</Heading>
        <Paper style={{ alignItems: "stretch", paddingTop: "5px" }}>
          <DocumentsBreadcrumbs
            onBack={() => {}}
            items={[
              {
                onClick: () => {},
                description: "Transacción",
              },
              {
                onClick: () => {},
                description: "Vendedor",
              },
              {
                onClick: () => {},
                description: "Logística y trazabilidad",
              },
            ]}
          />

          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "5px" }}
          >
            <EmptyFolder />
            <DocumentLocationItemSelectable
              name={"Identificación de residuo"}
            />
            <DocumentLocationItemSelectable
              name={"Identificación de residuo"}
            />
            <DocumentLocationItemSelectable
              name={"Identificación de residuo"}
            />
            <DocumentLocationItemSelectable
              name={"Identificación de residuo"}
            />
          </Flex>
        </Paper>
      </Flex>
    </div>
  );
}

function EmptyFolder() {
  return (
    <Center>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ gap: "10px" }}
      >
        <DocumentLocationSelectorEmptyIcon />
        <Typography noMargin style={{ color: WARM_NEUTRAL_50 }}>
          Esta carpeta aún esta vacía
        </Typography>
      </Flex>
    </Center>
  );
}

export default DocumentLocationSelector;

