import { toastError } from "@scrapadev/scrapad-front-sdk";
import { useCRUD } from "../crud/hooks";

/**
 * Custom hook to toggle business verification status. For basic and complete verification.
 * @returns {{handleChangeVerificationStatus: (type: string, active: boolean, fetchData: Function, loading: boolean, setLoading: (b: boolean) => void)}}
 */
export const useVerificationChangeStatus = () => {
  const { CRUD } = useCRUD();

  const handleChangeVerificationStatus = async (
    id,
    type,
    active,
    fetchData,
    loading,
    setLoading
  ) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${id}/verification/${id}?from_dashboard=true`,
      method: "PUT",
      data: {
        active,
        type,
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
    });
  };

  const handleAssignedAgent = async ({
    id,
    agentAssigned,
    fetchData = async () => {},
    setLoading = () => {},
  }) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${id}/basic-data?from_dashboard=true`,
      method: "PUT",
      data: {
        assignedBo: agentAssigned,
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
    });
  };

  return {
    handleChangeVerificationStatus,
    handleAssignedAgent,
  };
};
