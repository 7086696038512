import {
  Accordion,
  BREAKPOINT_LG,
  Button,
  Chip,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  ImageBox,
  Loading,
  Paper,
  Row,
  Tracking,
  Typography,
  WARM_NEUTRAL_60,
  toastError,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useTranslation } from "../../utils/hooks";
import TransactionSteps from "../../widgets/transactions/steps/Steps";
import { useEffect, useState } from "react";
import { gqlWrapper } from "../../utils/gqlwrapper";
import {
  getTransactionDataBO,
  getTransactionDetail,
} from "../../model/transactions";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import TransactionHeader from "./header/TransactionHeader";
import TransactionDetailHeader from "./header/Detail";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { trackingTransformer } from "../../utils/functions";
import MaterialText from "../../components/ui/material-text/MaterialText";
import ItemText from "../../components/ui/item/ItemText";
import PaperSectionHeader from "../../components/ui/paper-section-header/PaperSectionHeader";
import UnderlinedLink from "../../components/ui/link/UnderlinedLink";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { modalTypes } from "../../utils/variables";
import { DataProvider } from "../../providers/data/DataContext";
import { getAd } from "../../model/ads";
import _ from "lodash";
import FloatingCard from "../../components/ui/floating-card/FloatingCard";
import ProviderCard from "../../components/ui/provider-card/ProviderCard";
import ChipOutlined from "../../components/ui/chip/ChipOutlined";

const TransactionTransport = () => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation([
    "common",
    "transactions",
    "finances",
    "ads",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const signOut = useSignOut();
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const user = useUser();
  const setDataUser = useSetDataUser();
  const [data, setData] = useState([]);
  const [adData, setAdData] = useState({});
  const [addQuotationOpen, setAddQuotationOpen] = useState(false);
  const [editRouteOpen, setEditRouteOpen] = useState(false);
  const [editLoadCharacteristics, setEditLoadCharacteristics] = useState(false);
  const {
    canManageTransaction,
    canReadLogistics,
    canReadFinances,
    canManageLogistics,
    canManageTransactionMessages,
    canManageTransactionSamplesVerificationTransport,
    canManageTransactionReturn,
    canReadTransactionMessages,
    canReadTransactionSamplesVerificationTransport,
    canManageFinances,
  } = useOperationsProvider();

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(getTransactionDataBO, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
          fetchAdData(results?.sideData?.originAd?.uuidAd);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  const fetchAdData = async (uuid, quiet = true) => {
    if (!uuid) {
      return;
    }
    if (!quiet) setIsLoading(true);
    try {
      const fn = await gqlWrapper(getAd, setDataUser, signOut);
      const results = await fn(i18n.language, uuid);
      if (results === null) {
        setAdData({});
      } else {
        if (results?.errors?.length > 0) {
          setAdData({});
          toastError(results?.errors[0]?.message);
        } else {
          setAdData(results);
        }
      }
    } catch (e) {
      setAdData({});
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );
  const subtitle = (
    <ChipOutlined iconComponent={typeIcon}>
      <span style={{ textTransform: "uppercase" }}>
        {data?.header?.uuidConversation?.split("-")?.[0]}
      </span>
    </ChipOutlined>
  );
  useDocumentTitle(`${t("transaction")} ${id.split("-")?.[0]}`, values);

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <TransactionHeader
                      goBack
                      imageUrl={data?.sideData?.originAd?.image}
                      breadcrumbs={[
                        {
                          description: t("transactions"),
                          onClick: () =>
                            navigate("/transactions", {
                              replace: true,
                            }),
                        },
                        { description: data?.header?.adTitle },
                      ]}
                      title={data?.header?.adTitle}
                      subtitle={subtitle}
                      rightArea={
                        <TransactionDetailHeader
                          uuidEvent={id}
                          title={data?.header?.adTitle}
                          subtitle={subtitle}
                          fetchData={fetchData}
                          permission={canManageTransaction}
                          permissionLogistics={canReadLogistics}
                          onlyMenu
                        />
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <TransactionSteps id={id} currentStep={"transport"} />
            </Container>
            <Container>
              <Flex
                flexDirection="column"
                alignItems="stretch"
                style={{ gap: "10px" }}
              >
                <Paper style={{ display: "block" }}>
                  <Flex
                    flexDirection="column"
                    alignItems="stretch"
                    style={{ gap: "10px" }}
                  >
                    {canReadLogistics() &&
                    JSON.parse(process.env.REACT_APP_QUOTATIONS) ? (
                      <Paper whiteMode style={{ alignItems: "stretch" }}>
                        <Row justify="space-between">
                          <Row gap={15}>
                            <Heading type={6} style={{ margin: 0 }}>
                              {t("quotation")}
                            </Heading>
                            <Chip
                              text={"Pendiente"}
                              small={false}
                              type={"pending"}
                            />
                          </Row>
                          {canManageLogistics() ? (
                            <Button
                              component="button"
                              type="button"
                              mode={"info"}
                              text={t("add_quotation")}
                              reduced
                              onClick={() => {
                                setAddQuotationOpen(true);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Row>
                        <Flex
                          flexDirection="column"
                          alignItems="flex-start"
                          style={{ gap: "5px" }}
                        >
                          <ItemText
                            label={t("provider")}
                            value={"Evergreen Marine"}
                          />
                          <ItemText
                            label={t("provider")}
                            value={"Evergreen Marine"}
                          />
                          <ItemText
                            label={t("provider")}
                            value={"Evergreen Marine"}
                          />
                          <ItemText
                            label={t("provider")}
                            value={"Evergreen Marine"}
                          />
                        </Flex>
                        <Accordion
                          minimal
                          defaultOpen
                          toggleAtLead
                          title={
                            <Row gap={25} align="center">
                              <Heading
                                type={7}
                                style={{ margin: "0 auto 0 0", padding: 0 }}
                              >
                                {t("service")} 1
                              </Heading>
                              <FloatingCard
                                content={
                                  <ProviderCard
                                    name={"Volquetes SL"}
                                    email={"contacto@empresadevolquete.com"}
                                  />
                                }
                              >
                                Evergreen Marine
                              </FloatingCard>
                            </Row>
                          }
                        >
                          <div style={{ paddingLeft: "17px" }}>
                            <Flex
                              flexDirection="column"
                              alignItems="flex-start"
                              style={{ gap: "5px" }}
                            >
                              <ItemText
                                alt
                                label={t("provider")}
                                value={"Evergreen Marine"}
                              />
                              <ItemText
                                alt
                                label={t("provider")}
                                value={"Evergreen Marine"}
                              />
                            </Flex>
                          </div>
                        </Accordion>
                      </Paper>
                    ) : (
                      <></>
                    )}

                    <Paper whiteMode style={{ alignItems: "stretch" }}>
                      <Flex
                        flexDirection="column"
                        alignItems="stretch"
                        style={{ gap: "10px" }}
                      >
                        <PaperSectionHeader
                          left={
                            <Heading type={6} style={{ margin: 0 }}>
                              {t("route")}
                            </Heading>
                          }
                          right={
                            <UnderlinedLink
                              onClick={() => {
                                setEditRouteOpen(true);
                              }}
                            >
                              {t("edit")}
                            </UnderlinedLink>
                          }
                        />

                        <Tracking
                          breakpoint={7000}
                          steps={trackingTransformer(
                            data?.sideData?.transportData,
                            i18n,
                            data?.sideData?.originAd?.type?.toLowerCase()
                          )}
                        />
                      </Flex>
                    </Paper>
                    <Paper whiteMode style={{ alignItems: "stretch" }}>
                      <PaperSectionHeader
                        left={
                          <Heading type={6} style={{ margin: 0 }}>
                            {t("material")}
                          </Heading>
                        }
                        right={
                          <UnderlinedLink
                            alternative={true}
                            onClick={() => {
                              console.log();
                              window?.open(
                                `/ads/${data?.sideData?.originAd?.uuidAd}`,
                                "_blank"
                              );
                            }}
                          >
                            {t("view-ad")}
                          </UnderlinedLink>
                        }
                      />
                      <Typography style={{ margin: 0 }}>
                        <MaterialText
                          text={data?.sideData?.originAd?.material}
                        />
                      </Typography>
                      <Row gap={5} wrap>
                        {adData?.images?.map((image) => (
                          <ImageBox
                            url={image}
                            style={{ width: "80px", height: "80px" }}
                            innerStyle={{ width: "80px", height: "80px" }}
                          />
                        ))}
                      </Row>
                      <Divider style={{ margin: "10px 0" }} />
                      <PaperSectionHeader
                        left={
                          <Heading type={7} style={{ margin: 0 }}>
                            {t("ad-load-carateristics")}
                          </Heading>
                        }
                        right={
                          <UnderlinedLink
                            onClick={() => {
                              setEditLoadCharacteristics(true);
                            }}
                          >
                            {t("edit")}
                          </UnderlinedLink>
                        }
                      />

                      <Flex
                        flexDirection="column"
                        alignItems="flex-start"
                        style={{ gap: "10px" }}
                      >
                        {!_.isEmpty(adData?.lerCodes) && (
                          <ItemText
                            label={t("ler-code")}
                            value={adData?.lerCodes
                              ?.map((code) => code.name)
                              ?.join(", ")}
                          />
                        )}
                        {adData?.loadCharacteristics?.map((lc) => {
                          if (lc.type === "granel") {
                            return (
                              <ItemText
                                label={t("format")}
                                value={t(lc.type)}
                              />
                            );
                          } else {
                            return (
                              <>
                                <ItemText
                                  label={t("format")}
                                  value={t(lc.type)}
                                />
                                <ItemText
                                  label={t("package_load")}
                                  value={lc?.value.weight}
                                />
                                <ItemText
                                  label={`${t("package_dimensions")} (cm)`}
                                  value={`${lc?.value?.height} x ${lc?.value?.width} x ${lc?.value?.depth}`}
                                />
                              </>
                            );
                          }
                        })}
                      </Flex>
                    </Paper>
                  </Flex>
                </Paper>
              </Flex>
            </Container>
            <DataProvider>
              <ModalFactory
                key={""}
                uuidQuote={""}
                type={modalTypes.LOGISTICS_ADD_QUOTATION}
                open={addQuotationOpen}
                setOpen={setAddQuotationOpen}
              />
            </DataProvider>
            <DataProvider>
              <ModalFactory
                key={""}
                uuidQuote={""}
                type={modalTypes.LOGISTICS_EDIT_ROUTE}
                open={editRouteOpen}
                setOpen={setEditRouteOpen}
              />
            </DataProvider>
            <DataProvider>
              <ModalFactory
                key={""}
                uuidQuote={""}
                type={modalTypes.LOGISTICS_EDIT_LOAD_CHARACTERISTICS}
                open={editLoadCharacteristics}
                setOpen={setEditLoadCharacteristics}
              />
            </DataProvider>
          </>
        }
      />
    </>
  );
};

export default TransactionTransport;
