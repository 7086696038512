import React from "react";
import styles from "./DocumentFilter.module.scss";
import {
  Chip,
  ChipDropdown,
  ClearButton,
  Flex,
  Row,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../../utils/hooks";
import SortingMenu from "../../../../../components/ui/sorter/SortingMenu";
import { documentActions } from "../../../../../providers/documents/actions/DocumentsActions";
import { useDocumentsProvider } from "../../../../../providers/documents/DocumentsContext";
import SearchBar from "../../../../../components/ui/searchbar/SearchBar";
import BulkActions from "../bulk-actions/BulkActions";

function DocumentFilter() {
  const { t } = useTranslation("common");
  const { state, dispatch, searchFiles } = useDocumentsProvider();

  const search = async (term) => {
    const results = await searchFiles(term);
    console.log(results);
  };

  return (
    <div className={styles["document_filter"]}>
      <Flex flexDirection="column" alignItems="flex-start">
        <SearchBar
          placeholder={t("search")}
          handleOnChange={(e) => {
            console.log("onchange");
            console.log(e);
            search(e.target.value);
          }}
          handleOnSubmit={(e) => {
            console.log("onSubmit");
            console.log(e);
          }}
        />
      </Flex>
      <div className={styles["actions"]}>
        <Row gap={15} align="center" justify="space-between">
          <Row gap={10} wrap>
            <ChipDropdown
              type="white-outlined"
              text={"Tipo de documento"}
              customRenderItem={(item, openDropdown, setOpenDropdown) => {
                return (
                  <ClearButton onClick={(e) => {}}>
                    {item.component}
                  </ClearButton>
                );
              }}
              items={[]}
            />
            <Chip
              type="white-outlined"
              text={t("new")}
              icon="add"
              showIcon
              handleClick={() => {
                dispatch({
                  type: documentActions.UPLOAD_FILE_OPEN,
                });
              }}
            />
          </Row>
          <Row align="center" justify="flex-end">
            <BulkActions />
            <SortingMenu
              onChange={(e) => {
                dispatch({
                  type: documentActions.SORTING_SET,
                  payload: e,
                });
              }}
            />
          </Row>
        </Row>
      </div>
    </div>
  );
}

export default DocumentFilter;
