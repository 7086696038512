import React, { useContext, useMemo, useReducer } from "react";
import { actions } from "./actions/notifications";
import { getAllNotifications, getPreferences } from "../../model/notification";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { postClient } from "../../utils/restclient";
import { useSetDataUser, useSignOut } from "../AuthHooks";
import { toastError, toastSuccess } from "@scrapadev/scrapad-front-sdk";

const NotificationContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {
    notifications: [],
    pagination: {},
    isLoading: false,
  };
};

const updateData = async (
  state,
  setDataUser,
  signOut,
  offset,
  limit,
  byType,
  byDate,
  unread
) => {
  const fn = await gqlWrapper(getAllNotifications, setDataUser, signOut);
  const data = await fn(offset, limit, byType, byDate, unread);

  if (data?.errors?.length > 0) {
    toastError(JSON.stringify(data.errors), { toastId: "update_data_error" });
  } else {
    if (data?.notifications) {
      return {
        notifications: data?.notifications,
        pagination: data?.pagination,
      };
    } else {
      return {
        notifications: [],
        pagination: data?.pagination,
      };
    }
  }
};

const fetchPreferences = async (state, setDataUser, signOut, lang) => {
  const fn = await gqlWrapper(getPreferences, setDataUser, signOut);
  const data = await fn(lang);

  if (data?.errors?.length > 0) {
    toastError(JSON.stringify(data.errors), {
      toastId: "preferences_data_error",
    });
  } else {
    return data;
  }
};

const readAllFn = async () => {
  try {
    return await postClient({
      url: `/v2/notifications/read-all`,
    });
  } catch (error) {
    toastError(JSON.stringify(error.message), {
      toastId: "readall_data_error",
    });
    return false;
  }
};

const readOneFn = async (id) => {
  try {
    return await postClient({
      url: `/v2/notifications/read/${id}`,
    });
  } catch (error) {
    toastError(JSON.stringify(error.message), {
      toastId: "readone_data_error",
    });
    return false;
  }
};

const toggleChannelOnFn = async (
  uuidCategory,
  uuidChannel,
  signOut,
  setDataUser
) => {
  try {
    return await postClient({
      url: `/v2/notifications/user-preferences`,
      body: {
        uuidCategory,
        uuidChannel,
      },
      signOut,
      setDataUser,
    });
  } catch (error) {
    toastError(error, { toastId: "togglechannelon_data_error" });
    return false;
  }
};

const toggleChannelOffFn = async (selected, signOut, setDataUser) => {
  try {
    return await postClient({
      method: "DELETE",
      url: `/v2/notifications/user-preferences/${selected}`,
      signOut,
      setDataUser,
    });
  } catch (error) {
    toastError(error, { toastId: "togglechanneloff_data_error" });
    return false;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.FETCH_NOTIFICATIONS:
      return updateData();
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actions.UNSET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        pagination: action.payload.pagination,
      };
    case actions.UNSET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    case actions.FETCH_PREFERENCES:
      return {
        ...state,
        preferences: action.payload.preferences,
      };
  }
};

export const NotificationProvider = ({ defaultTalkUuid, children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(defaultTalkUuid)
  );
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();

  const contextValue = useMemo(() => {
    /**
     * UPDATE DATA CALL - ACTUALIZA EL CONTENIDO DE LOS MENSAJES AUTOMÁTICAMENTE
     */
    const updateDataCall = async ({
      offset = 0,
      limit = 12,
      byType,
      byDate,
      unread,
      skipLoading = false,
    }) => {
      if (!skipLoading) {
        dispatch({
          type: actions.SET_LOADING,
        });
      }
      const response = await updateData(
        state,
        setDataUser,
        signOut,
        offset,
        limit,
        byType,
        byDate,
        unread
      );
      dispatch({
        type: actions.SET_NOTIFICATIONS,
        payload: {
          notifications: response?.notifications,
          pagination: response?.pagination,
        },
      });
      if (!skipLoading) {
        dispatch({
          type: actions.UNSET_LOADING,
        });
      }
    };

    const readAll = async (t, offset, unread = false) => {
      dispatch({
        type: actions.SET_LOADING,
      });

      if (await readAllFn()) {
        toastSuccess("Marcado todo como leído");
      } else {
        toastSuccess(t("mark_all_read"));
      }

      dispatch({
        type: actions.UNSET_LOADING,
      });

      updateDataCall({ offset, unread });
    };

    const readOne = async (t, id, offset) => {
      if (await readOneFn(id)) {
        toastSuccess(t("mark_one_read"));
      }

      updateDataCall({ offset, skipLoading: true });
    };

    const updatePreferencesCall = async (lang) => {
      dispatch({
        type: actions.SET_LOADING,
      });
      const response = await fetchPreferences(
        state,
        setDataUser,
        signOut,
        lang
      );
      dispatch({
        type: actions.FETCH_PREFERENCES,
        payload: {
          preferences: response?.preferences,
        },
      });
      dispatch({
        type: actions.UNSET_LOADING,
      });
    };

    const updateChannel = async (t, lang, data, type = "on") => {
      const okMessage = t("saved_preferences");
      dispatch({
        type: actions.SET_LOADING,
      });
      let success = false;
      if (type === "on") {
        if (
          await toggleChannelOnFn(
            data.uuidCategory,
            data.uuidChannel,
            signOut,
            setDataUser
          )
        ) {
          success = true;
        }
      } else {
        if (await toggleChannelOffFn(data.selected, signOut, setDataUser)) {
          success = true;
        }
      }
      if (success) toastSuccess(okMessage);
      updatePreferencesCall(lang);
      dispatch({
        type: actions.UNSET_LOADING,
      });
    };

    return {
      state,
      dispatch,
      updateDataCall,
      readAll,
      readOne,
      updatePreferencesCall,
      updateChannel,
    };
  }, [state, dispatch]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationProvider = () => useContext(NotificationContext);
