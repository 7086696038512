import {
  Spinner,
  Row,
  Heading,
  Typography,
  List,
  ListItem,
  Padding,
  Center,
  NoNotifications,
  Flex,
  ClearButton,
} from "@scrapadev/scrapad-front-sdk";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useNotificationProvider } from "../../../providers/notifications/NotificationsContext";
import { useTranslation } from "../../../utils/hooks";
import MarkAsRead from "../mark-as-read/MarkAsRead";
import styles from "./NotificationsPreview.module.scss";

function NotificationsPreview() {
  return <NotificationPreviewEntry />;
}

function Divider() {
  return <hr style={{ margin: "-10px 0" }} />;
}

function NotificationPreviewEntry() {
  const { t } = useTranslation("notifications");
  const { state, updateDataCall, readAll } = useNotificationProvider();
  const navigate = useNavigate();

  useEffect(() => {
    updateDataCall({ unread: true });
  }, []);

  const read = async (e) => {
    e.stopPropagation();
    readAll(t, 0, true);
  };

  return (
    <div className={styles["sc-n-preview"]}>
      <div className={styles["header"]}>
        <Row gap={15} justify="space-between">
          <Heading type={6} style={{ margin: 0 }}>
            {t("notifications")}
          </Heading>
          <Link to="/notifications">
            <ClearButton>
              <Typography reduced>
                <u>{t("see-all")}</u>
              </Typography>
            </ClearButton>
          </Link>
        </Row>
      </div>
      {state.isLoading ? (
        <>
          <Padding>
            <Center>
              <Spinner />
            </Center>
          </Padding>
        </>
      ) : (
        <>
          <div className={styles["body"]}>
            <List>
              {state.notifications?.length > 0 ? (
                <>
                  {state.notifications?.map((notification, i) => (
                    <>
                      <ListItem
                        key={notification?.id}
                        badgeActive={!notification?.read}
                        title={notification?.title}
                        headerRightSide={`${t(
                          notification.category
                        )} - ${format(
                          Number(notification?.createdDate),
                          "dd/MM/yyyy HH:mm"
                        )}`}
                        headerColumnMode
                        onClick={() => {
                          notification?.link
                            ? (window.location.href = notification?.link)
                            : navigate("/notifications");
                        }}
                      />
                      <Divider />
                    </>
                  ))}
                </>
              ) : (
                <Padding>
                  <Center>
                    <Flex flexDirection="column" style={{ gap: "20px" }}>
                      <NoNotifications />
                      <Typography>{t("empty")}</Typography>
                    </Flex>
                  </Center>
                </Padding>
              )}
            </List>
          </div>
          {!state.isLoading && state.notifications?.length > 0 && (
            <div className={styles["footer"]}>
              <MarkAsRead onClick={read} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NotificationsPreview;
