import {
  Alert,
  BREAKPOINT_LG,
  Flex,
  Icon,
  Modal,
  Row,
  toastError,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import { useCRUD } from "../../../../utils/crud/hooks";
import { useDocumentsProvider } from "../../DocumentsContext";

function ModalDocumentDelete({
  open = false,
  setOpen,
  formerName = "",
  uuid,
  ...props
}) {
  const { CRUD } = useCRUD();
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const { reloadData } = useDocumentsProvider();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await CRUD({
      external: false,
      endpoint: `integration/delete/${uuid}`,
      method: "DELETE",
      successCallback: async () => {
        reloadData();
        setLoading(false);
        setOpen(false);
      },
      errorCallback: async (e) => {
        setLoading(false);
        toastError(`Error: ${e?.message}`);
      },
    });

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("remove_file_title")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("remove")}
      cancelText={t("cancel")}
      actionsMode="end"
      submitProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      cancelProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      loading={loading}
      maxWidth={650}
      modalStyles={{ width: matches ? "auto" : 650 }}
      BodyComponent={
        <>
          <div>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "15px" }}
            >
              <Row gap={10}>
                <Icon
                  name={"insert_drive_file"}
                  color={WARM_NEUTRAL_50}
                  style={{ color: WARM_NEUTRAL_50 }}
                />
                <Typography noMargin style={{ color: WARM_NEUTRAL_50 }}>
                  {formerName}
                </Typography>
              </Row>
              <Alert type="warning">
                <Row gap={10}>
                  <span style={{ width: "25px" }}>
                    <Icon name="info_outline" style={{ fontSize: "22px" }} />
                  </span>
                  <span>{t("remove_file_warn")}</span>
                </Row>
              </Alert>
            </Flex>
          </div>
        </>
      }
    />
  );
}

export default ModalDocumentDelete;
