import { ClearButton } from "@scrapadev/scrapad-front-sdk";
import Icon from "../icon/Icon";
import styles from "./Breadcrumbs.module.scss";

/**
 * UI Breadcrumbs component.
 * @param {Object} props - Component properties
 * @param {Array} items - Navigation items.
 * @returns {JSX.Element}
 */
const Breadcrumbs = ({ items }) => {
  if (items === null || items.length === 0) return <></>;
  return (
    <div className={styles["sc-header--breadcrumbs"]}>
      <ul>
        {items.map((item, index) => {
          let i = [];
          if (item.onClick) {
            i.push(
              <li key={`br_1_${index}`}>
                <ClearButton onClick={item.onClick}>
                  {item.description}
                </ClearButton>
              </li>
            );
          } else {
            i.push(<li>{item.description}</li>);
          }
          if (index < items.length - 1) {
            i.push(
              <li key={`br_2_${index}`}>
                <span className={styles["sc-header--arrow"]}>
                  <Icon name={"chevron_right"} color={"#7E7E7E"} />
                </span>
              </li>
            );
          }
          return i;
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;

