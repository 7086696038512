import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import {
  Alert,
  BREAKPOINT_LG,
  Flex,
  Heading,
  Icon,
  Modal,
  Row,
  TextArea,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import TransactionFiles from "../../../../components/ui/input/file/File";
import { clientTypes } from "../../../../utils/variables";

function ModalDocumentShare({
  open = false,
  setOpen,
  clientType = clientTypes.BUYER,
  ...props
}) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  console.log(clientType);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t(
        clientType === clientTypes.BUYER
          ? "share_with_buyer"
          : "share_with_seller"
      )}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("share")}
      cancelText={t("cancel")}
      actionsMode="end"
      submitProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      cancelProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      loading={loading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <>
          <div>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "15px" }}
            >
              <Alert type="info" style={{ margin: 0 }}>
                <Row gap={10} align="center">
                  <Icon name={"info"} />
                  <span>
                    Los elementos compartidos aparecerán también en la carpeta
                    del comprador. Además, esta acción se reflejará en el chat
                    de la transacción.
                  </span>
                </Row>
              </Alert>
              <TransactionFiles
                files={files}
                readOnly={false}
                hideFileType={true}
                showFilesAndInput={false}
                hideFileInput
                forceShowFileList
              />
              <div>
                <Heading type={7} style={{ margin: 0 }}>
                  Agregar mensaje
                </Heading>
                <Typography small style={{ marginBottom: "15px" }}>
                  Por favor escribe el mensaje que se reflejará en el chat de la
                  transacción.
                </Typography>
                <TextArea
                  placeholder={"Escribe el mensaje aquí"}
                  onChange={(e) => {
                    console.log(e);
                  }}
                />
              </div>
            </Flex>
          </div>
        </>
      }
    />
  );
}

export default ModalDocumentShare;

