import styles from "./File.module.scss";
import {
  ClearButton,
  DropDown,
  Flex,
  Heading,
  Icon,
  ImageBox,
  InputText,
  Label,
  Paper,
} from "@scrapadev/scrapad-front-sdk";
import InputFileWrapper from "./Wrapper";
import { useTranslation } from "../../../../utils/hooks";
import _ from "lodash";
import { useState } from "react";

/**
 * UI File component.
 * @param {Object} props - Component properties.
 * @param {Array} [props.files=[]] - Files uploaded.
 * @param {Boolean} [props.multiple=true] - If multiple files are allowed.
 * @param {String} [props.dropPlaceholder=""] - Placeholder when files are being droppped.
 * @param {Function} [props.handleUpload=()=>{}] - Handler for adding files.
 * @param {Function} [props.handleDeleteFile=()=>{}] - Handler for deleting files.
 * @param {boolean} [props.showFilesAndInput=false] - Tells the component to show the files and the input at the same time.
 * @returns {JSX.Element}
 */

export const fileTypeOptions = [
  {
    uuid: "commercial_sale_seller",
    text: "Contrato de vendedor",
  },
  {
    uuid: "commercial_sale_buyer",
    text: "Contrato de comprador",
  },
  {
    uuid: "invoice",
    text: "Factura",
  },
  {
    uuid: "packing_list",
    text: "Packing list",
  },
  {
    uuid: "weighing_ticket",
    text: "Ticket de pesaje",
  },
  {
    uuid: "cargo_loading_photos",
    text: "Fotos de la carga",
  },
  {
    uuid: "loading_report",
    text: "Loading report",
  },
  {
    uuid: "bl_draft",
    text: "BL draft",
  },
  {
    uuid: "bl_original",
    text: "BL original",
  },
  {
    uuid: "di",
    text: "Documento de identifiación de residuo",
  },
  {
    uuid: "proof_of_payment",
    text: "Justificante de pago",
  },
  {
    uuid: "proof_of_ownership",
    text: "Justificante de titularidad",
  },
  {
    uuid: "feedback_ok",
    text: "Justificante de feedback",
  },
  {
    uuid: "other",
    text: "Otros",
  },
];

const TransactionFiles = ({
  files = [],
  multiple = true,
  dropPlaceholder = "",
  beforeUpload = () => {},
  afterUpload = () => {},
  afterDelete = () => {},
  onFileTypeChange = () => {},
  afterFileNameChange = () => {},
  fileType = "transaction",
  iconStyles = {},
  showFilesAndInput = false,
  hideFileType = false,
  hideFileInput = false,
  forceShowFileList = false,
  selectedLabel,
  localMode = false,
  ...props
}) => {
  const { t } = useTranslation("common");
  const [activeRename, setActiveRename] = useState(false);
  const [activeRenameIndex, setActiveRenameIndex] = useState(-1);
  const [fileName, setFileName] = useState("");
  const handleDeleteFile = (index) => () => {
    const newFiles = Array.prototype.filter.call(
      files,
      (f, fileIndex) => fileIndex !== index
    );
    if (afterDelete) afterDelete(newFiles);
  };

  const handleRenameFile = (index, fileName) => {
    const newFiles = files.map((file, i) => {
      if (i === index) {
        if (localMode) {
          const extension = file.name.split(".").pop();
          const updatedFile = new File([file], `${fileName}.${extension}`, {
            type: file.type,
            lastModified: file.lastModified,
          });
          return updatedFile;
        } else {
          file.name = fileName;
        }
      }

      return file;
    });
    if (afterFileNameChange) afterFileNameChange(newFiles);

    setFileName("");
    setActiveRenameIndex(-1);
  };

  const renderFiles = () => {
    if (_.isEmpty(files) && !forceShowFileList) {
      return <></>;
    }

    if (localMode) {
      return (
        <div className={styles["sc-files"]}>
          <Paper>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ width: "100%", gap: "15px" }}
            >
              <Heading type={6} style={{ margin: 0 }}>
                {selectedLabel ? selectedLabel : t("selected_ones")} (
                {files.length})
              </Heading>
              {Array.prototype.map.call(files, (file, index) => {
                return (
                  <div
                    key={`${file?.name}`}
                    className={styles["sc-files--file"]}
                  >
                    <ImageBox
                      url={file?.location}
                      style={{
                        width: "30px",
                        maxWidth: "30px",
                        minWidth: "30px",
                        height: "30px",
                      }}
                      innerStyle={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                    <span
                      className={styles["file_name"]}
                      style={{ marginRight: "auto" }}
                    >
                      {activeRename && activeRenameIndex === index ? (
                        <>
                          <InputText
                            value={fileName}
                            onChange={(e) => {
                              setFileName(e.target.value);
                            }}
                            style={{ width: "100%", margin: 0 }}
                          />
                        </>
                      ) : (
                        <>{file?.name}</>
                      )}
                    </span>
                    <div className={styles["actions"]}>
                      <ClearButton
                        type="button"
                        onClick={() => {
                          if (activeRenameIndex === index && activeRename) {
                            handleRenameFile(index, fileName);
                            setActiveRename(false);
                            setActiveRenameIndex(-1);
                          } else {
                            setFileName(file?.name);
                            setActiveRename(!activeRename);
                            setActiveRenameIndex(index);
                          }
                        }}
                      >
                        <Icon
                          name={"edit"}
                          color={"#9E9E9E"}
                          style={{ fontSize: "1.25rem", marginRight: "7px" }}
                        />
                        <span className={styles["button_label"]}>
                          {t(
                            activeRename && activeRenameIndex === index
                              ? "save-changes"
                              : "rename"
                          )}
                        </span>
                      </ClearButton>
                      <ClearButton
                        type="button"
                        onClick={handleDeleteFile(index)}
                      >
                        <Icon
                          name={"delete"}
                          color={"#E15050"}
                          style={{ fontSize: "1.25rem" }}
                        />
                      </ClearButton>
                    </div>
                  </div>
                );
              })}
            </Flex>
          </Paper>
        </div>
      );
    }

    return (
      <div className={styles["sc-files"]}>
        <Paper>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ width: "100%", gap: "15px" }}
          >
            <Heading type={6} style={{ margin: 0 }}>
              {selectedLabel ? selectedLabel : t("selected_ones")} (
              {files.length})
            </Heading>
            {Array.prototype.map.call(files, (file, index) => {
              return (
                <div key={`${file?.name}`} className={styles["sc-files--file"]}>
                  <ImageBox
                    url={file?.location}
                    style={{
                      width: "30px",
                      maxWidth: "30px",
                      minWidth: "30px",
                      height: "30px",
                    }}
                    innerStyle={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <span
                    className={styles["file_name"]}
                    style={{ marginRight: "auto" }}
                  >
                    {activeRename && activeRenameIndex === index ? (
                      <>
                        <InputText
                          value={fileName}
                          onChange={(e) => {
                            setFileName(e.target.value);
                          }}
                          style={{ width: "100%", margin: 0 }}
                        />
                      </>
                    ) : (
                      <>{file?.name}</>
                    )}
                  </span>
                  <div className={styles["actions"]}>
                    <ClearButton
                      type="button"
                      onClick={() => {
                        if (activeRenameIndex === index && activeRename) {
                          handleRenameFile(index, fileName);
                          setActiveRename(false);
                          setActiveRenameIndex(-1);
                        } else {
                          setFileName(file?.name);
                          setActiveRename(!activeRename);
                          setActiveRenameIndex(index);
                        }
                      }}
                    >
                      <Icon
                        name={"edit"}
                        color={"#9E9E9E"}
                        style={{ fontSize: "1.25rem", marginRight: "7px" }}
                      />
                      <span className={styles["button_label"]}>
                        {t(
                          activeRename && activeRenameIndex === index
                            ? "save-changes"
                            : "rename"
                        )}
                      </span>
                    </ClearButton>
                    <ClearButton
                      type="button"
                      onClick={handleDeleteFile(index)}
                    >
                      <Icon
                        name={"delete"}
                        color={"#E15050"}
                        style={{ fontSize: "1.25rem" }}
                      />
                    </ClearButton>
                  </div>
                </div>
              );
            })}
          </Flex>
        </Paper>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <InputFileWrapper
        files={files}
        multiple={multiple}
        dropPlaceholder={dropPlaceholder}
        placeholder={props.placeholder}
        beforeUpload={beforeUpload}
        afterUpload={afterUpload}
        iconStyles={iconStyles}
        fileType={fileType}
        translation={{
          uploading: t("uploading"),
        }}
        localMode={localMode}
        {...props}
      />
    );
  };

  if (showFilesAndInput) {
    return (
      <>
        {!hideFileType && (
          <Label text={t("files_type")}>
            <DropDown
              required
              placeholder={t("select-value")}
              options={fileTypeOptions}
              handleItemClick={onFileTypeChange}
              value={fileTypeOptions?.find((e) => e.uuid === fileType)?.text}
              style={{
                marginBottom: 0,
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                borderRadius: 0,
              }}
            />
          </Label>
        )}

        {!hideFileInput && renderInput()}
        {renderFiles()}
      </>
    );
  }

  if (forceShowFileList) {
    return renderFiles();
  }

  return files.length > 0 ? (
    renderFiles()
  ) : !hideFileInput ? (
    renderInput()
  ) : (
    <></>
  );
};

export default TransactionFiles;
