import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Loading,
  useMatchMedia,
  Modal,
  Row,
  Chip,
  ChipDropdown,
  ClearButton,
  Flex,
  SearchAutoComplete,
  BLUE_20,
  Label,
  InputDate,
  InputNumber,
  Heading,
  Button,
  Paper,
  WARM_NEUTRAL_50,
  Separator,
  toastError,
} from "@scrapadev/scrapad-front-sdk";

import { addMonths } from "date-fns";
import { TransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import TransportForm from "../../forms/transaction/Transport";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import {
  formEvents,
  TrackingStatusTypes,
  transportCostStates,
  transportLoadUnloadStates,
} from "../../../utils/variables";
import FileUpload from "../../ui/file-upload/FileUpload";
import FileItem from "../../ui/input/file/Item";
import _ from "lodash";
import { useRawEventBO } from "../../../utils/transactions/hooks";
import {
  handleTransactionsRawSlugs,
  parseTransactionsRawEventData,
} from "../../../utils/transactions/functions";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { getTracking } from "../../../model/logistics";
import { postClient } from "../../../utils/restclient";
import { buildFormTransportLoad } from "../../forms/transaction/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../../model/filters";
import { catalogs } from "../../ui/load-possibilities/LoadPossibilities";

function ChangeLogisticTrackingStatus({
  open = false,
  setOpen,
  onSubmit = () => {},
  group,
  ...props
}) {
  const { id } = props;
  const { canManageTransactionSamplesVerificationTransport } =
    useOperationsProvider();
  const user = useUser();
  const { t, i18n } = useTranslation(["common", "transactions"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [date, setDate] = useState();
  const [netWeight, setNetWeight] = useState();
  const [weightEnabled, setWeightEnabled] = useState(false);
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const [transportdata, setTransportData] = useState({});
  const [type, setType] = useState("load");
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const prevEventData = useRef();
  const { data: fileTypesData } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: catalogs.FILE_TYPE,
  });

  const {
    rawData,
    rawLoading,
    eventData,
    fetchData: rawFetchData,
  } = useRawEventBO({
    uuidConversation: id,
    slug: handleTransactionsRawSlugs(transportdata?.lastPhase),
    eventAdapter: parseTransactionsRawEventData,
    actions: {},
    dispatch: () => {},
  });

  useEffect(() => {
    fetchData();
  }, [open]);

  useEffect(() => {
    rawFetchData();
  }, [transportdata]);

  useEffect(() => {
    if (!_.isEqual(eventData, prevEventData?.current)) {
      setNetWeight(eventData?.newWeight);
      setDate(eventData?.estimatedDate);
      setSelectedType(eventData?.state);
      prevEventData.current = eventData;
    }
  }, [eventData]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const fn = await gqlWrapper(getTracking, setDataUser, signOut);
      const results = await fn({
        uuidConversation: id,
        lang: i18n.language,
      });
      if (results === null) {
        setTransportData({});
      } else {
        if (results?.errors?.length > 0) {
          setTransportData({});
          toastError(results?.errors[0]?.message);
        } else {
          setTransportData(results);
          setType(results?.lastPhase);
        }
      }
    } catch (e) {
      setTransportData({});
      toastError(e.message || e);
    } finally {
      setLoading(false);
    }
  };

  const optionsFiles = [
    {
      uuid: transportCostStates.PENDING,
      text: t("cost_pending"),
    },
    {
      uuid: transportCostStates.LAUNCHED,
      text: t("cost_sended"),
    },
    {
      uuid: transportCostStates.EXPIRATED,
      text: t("cost_expired"),
    },
    {
      uuid: transportCostStates.PENDING_COORDINATION,
      text: t("pending-coordinated"),
    },
    {
      uuid: transportCostStates.COORDINATE,
      text: t("coordinated"),
    },
  ];

  const folderOptions = [
    {
      uuid: "BUYER",
      text: t("buyer"),
    },
    {
      uuid: "SELLER",
      text: t("seller"),
    },
    {
      uuid: "BO",
      text: `${t("back_office")} (${t("private")})`,
    },
  ];

  let options = [];
  //load, unload, boarding
  switch (group) {
    case "origin":
      options = ["load", "boarding"];
      break;
    case "transit":
      options = ["boarding", "unload"];
      break;
    case "delivery":
      options = ["unload"];
      break;
    default:
      break;
  }

  const handleDate = (key) => (date) => {
    const obj = {};
    obj[key] = date.getTime();
    setDate(obj[key]);
  };

  const handleFileConf = (value, type, index) => {
    let conf = filesConfiguration[index] || {};
    conf[type] = value;
    let filesConfigurationCpy = [...filesConfiguration];
    filesConfigurationCpy[index] = conf;
    setFilesConfiguration(filesConfigurationCpy);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = buildFormTransportLoad(
      user.userUuid,
      checkLoadUnloadSlug(),
      {
        ...eventData,
        estimatedDate: date,
        newWeight: netWeight,
        state: selectedType,
      },
      type
    );

    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      setOpen(false);
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkLoadUnloadSlug = () => {
    switch (type) {
      case "load":
        return formEvents.TRANSPORT_COLLECTION_DATE_UPDATED;
      case "unload":
        return formEvents.TRANSPORT_DELIVERY_DATE_UPDATED;
      case "boarding":
        return formEvents.TRANSPORT_TRANSIT_DATE_UPDATED;
      default:
        return formEvents.TRANSPORT_DELIVERY_DATE_UPDATED;
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("update_tacking_status")}
      reverseActions={true}
      hasForm={false}
      hasActions={false}
      onSubmit={() => {}}
      loading={loading}
      maxWidth={900}
      cancelText={t("close")}
      submitText={t("accept")}
      modalStyles={{ width: matches ? "auto" : 800 }}
      BodyComponent={
        <>
          <Flex flexDirection="column" alignItems="flex-start">
            <SearchAutoComplete
              mode="underline"
              required={true}
              searchValue={
                !_.isEmpty(selectedType)
                  ? `${type ? t(type, { ns: "transactions" }) + " - " : ""} ${t(
                      selectedType?.toLowerCase()
                    )}`
                  : undefined
              }
              value={`${type ? t(type) + " - " : ""} ${t(
                selectedType?.toLowerCase()
              )}`}
              options={Object.values(TrackingStatusTypes).map((e) => ({
                name: t(e.text),
                subSelector: e.items?.map((item) => ({
                  name: t(item?.text),
                  uuid: item?.slug,
                  type: item?.type,
                })),
              }))}
              inputProps={{ placeholder: t("write-to-search") }}
              inputStyles={{
                border: `2px solid #63A1FF`,
                padding: "5px 15px",
                color: "#63A1FF",
                borderRadius: "50px",
              }}
              trailingStyles={{
                color: "#63A1FF",
                background: "#fff",
                lineHeight: 0,
                right: "9px",
              }}
              trailingIcon={
                <i
                  className={`material-icons icon`}
                  style={{ color: "#63A1FF" }}
                >
                  arrow_drop_down{" "}
                </i>
              }
              placeholder={t("select-a-value")}
              model={{ uuid: "uuid", text: "name" }}
              containerStyles={{ width: "auto", minWidth: "275px" }}
              handleItemClick={(option, open, setOpen, e) => {
                e.stopPropagation();
                setSelectedType(option.uuid);
                setType(option?.type);
                if (
                  option.uuid === transportLoadUnloadStates.CONFIRMED &&
                  option?.type === "load"
                ) {
                  setWeightEnabled(true);
                } else {
                  setWeightEnabled(false);
                  setNetWeight(undefined);
                }
                setTimeout(() => {
                  setOpen(false);
                }, 5);
              }}
              handleOnClickWithEvent={(e, value, open, setOpen) => {
                setOpen(!open);
              }}
            />
          </Flex>
          <Row gap={20}>
            <Label text={t("estimated-date")} noPadding>
              <InputDate
                readOnly={false}
                required
                placeholder={t("write-here")}
                value={date}
                handleOnChange={handleDate("untilDate")}
                languageCode={i18n.language}
                mode="underline"
              />
            </Label>
            {weightEnabled && (
              <Label text={`${t("net_weight")} (kg)`} noPadding>
                <InputNumber
                  readOnly={!weightEnabled}
                  min={0}
                  step={".01"}
                  placeholder={t("write-here")}
                  value={netWeight}
                  onChange={(e) => setNetWeight(e.target.value)}
                  mode="underline"
                  style={{ marginTop: "10px" }}
                />
              </Label>
            )}
          </Row>
          <Heading type={7}>
            {t("attachment-files")}{" "}
            {filesSelected?.length > 0 ? `(${filesSelected?.length})` : ""}{" "}
          </Heading>
          <FileUpload
            reloadOnUpdate
            defaultFiles={filesSelected}
            onChange={(files) => {
              setFilesSelected(files);
            }}
            // acceptedTypeFile={{
            //   "text/csv": [".csv"],
            // }}
            translation={{
              select_or_drag_files: `${t("select_or_drag_files")}`,
              files_selected: t("files_selected"),
            }}
            hideFileList={true}
          />
          {!_.isEmpty(filesSelected) && (
            <Paper style={{ alignItems: "stretch", marginTop: "15px" }}>
              <Flex
                flexDirection="column"
                alignItems="stretch"
                style={{ gap: "20px", paddingRight: "5px" }}
              >
                {filesSelected?.map((file, index) => (
                  <FileItem
                    containerStyle={{ marginBottom: 0 }}
                    file={file}
                    actionsComponent={
                      <>
                        <Row gap={10}>
                          <ChipDropdown
                            style={{
                              color: WARM_NEUTRAL_50,
                            }}
                            iconStyle={{
                              color: WARM_NEUTRAL_50,
                            }}
                            closeStyle={{
                              marginRight: 0,
                            }}
                            items={
                              _.isEmpty(fileTypesData)
                                ? []
                                : fileTypesData?.map((item, i) => (
                                    <ClearButton
                                      key={item.uuid}
                                      onClick={() => {
                                        handleFileConf(
                                          item.uuid,
                                          "status",
                                          index
                                        );
                                      }}
                                    >
                                      {item?.name}
                                    </ClearButton>
                                  ))
                            }
                            type="clear"
                            text={t(
                              filesConfiguration[
                                index
                              ]?.status?.toLowerCase() || "file_type"
                            )}
                          />
                          <Separator
                            space={0}
                            style={{ backgroundColor: WARM_NEUTRAL_50 }}
                          />
                          <ChipDropdown
                            style={{
                              color: WARM_NEUTRAL_50,
                            }}
                            iconStyle={{
                              color: WARM_NEUTRAL_50,
                            }}
                            closeStyle={{
                              marginRight: 0,
                            }}
                            items={folderOptions.map((item, i) => (
                              <ClearButton
                                key={item.uuid}
                                onClick={() => {
                                  handleFileConf(item.uuid, "addressee", index);
                                }}
                              >
                                {t(item.text)}
                              </ClearButton>
                            ))}
                            type="clear"
                            text={t(
                              filesConfiguration[
                                index
                              ]?.addressee?.toLowerCase() || "share"
                            )}
                          />
                          <Separator
                            space={0}
                            style={{ backgroundColor: WARM_NEUTRAL_50 }}
                          />
                        </Row>
                      </>
                    }
                    handleDelete={() => {
                      setFilesSelected(filesSelected.filter((f) => f !== file));
                      let confCpy = [...filesConfiguration];
                      confCpy.splice(index, 1);
                      setFilesConfiguration(confCpy);
                    }}
                  />
                ))}
              </Flex>
            </Paper>
          )}

          <Row justify="flex-end" gap={15} style={{ paddingTop: "15px" }}>
            <Button
              onClick={() => setOpen(false)}
              text={t("cancel")}
              ultrawide
              mode="normal"
            />
            <Button
              onClick={() => handleSubmit()}
              text={t("save")}
              ultrawide
              mode="success"
            />
          </Row>
        </>
      }
    />
  );
}

export default ChangeLogisticTrackingStatus;
