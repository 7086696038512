import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
  InputText,
  Row,
  Flex,
  Label,
  Radio,
  Paper,
  Heading,
  BREAKPOINT_MD,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getCatalogNoTerm } from "../../../model/filters";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useCRUD } from "../../../utils/crud/hooks";
import { handleCountryClick, handleOnChange } from "../../../utils/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import CatalogSearch from "../../ui/input/search/CatalogSearch";
import InputCountries from "../../ui/input/country/Country";
import _ from "lodash";
import LoadPossibilities from "../../ui/load-possibilities/LoadPossibilities";

const defaultData = {
  originType: "address",
  address: {},
  destinationType: "address",
  destination: {},
};

export const LOAD_POSSIBILITY = {
  container: "57369bb1-e22f-4711-924b-dde80b8addd3",
  tarp: "1c1d2e48-f22d-43f3-8a94-e9d9bce316f6",
  dump_truck: "5c077d6f-4c4e-4b5c-b17d-f6f832a6e804",
  none: "none",
};

const ModalEditRoute = ({ open, setOpen, isEdit, data, ...props }) => {
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState();
  const [facilitiesAlreadySetted, setFacilitiesAlreadySetted] = useState(false);
  const [facilitiesError, setFacilitiesError] = useState(false);
  const [loadPossibilities, setLoadPossibilities] = useState([]);
  const [unloadPossibilities, setUnloadPossibilities] = useState([]);
  const { t, i18n } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const matchesMD = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const { CRUD } = useCRUD();
  const { data: catalogData, loading: catalogLoading } = useGQL(
    [getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm],
    [
      {
        locale: i18n.language,
        name: "transporter-equipments",
      },
      {
        locale: i18n.language,
        name: "logistics-services",
      },
      {
        locale: i18n.language,
        name: "logistics-coverage",
      },
      {
        locale: i18n.language,
        name: "logistics-activity",
      },
    ]
  );

  useEffect(() => {
    if (isEdit) {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: data || defaultData,
      });
    } else {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: defaultData,
      });
    }
  }, []);

  const handleSubmit = async () => {
    console.log("handleSubmit");
    console.log(state);
  };

  const handleLoadPossibilities = (choice, unload = false) => {
    setFacilitiesError(false);
    if (choice === LOAD_POSSIBILITY.none) {
      if (unload) {
        setUnloadPossibilities([choice]);
      } else {
        setLoadPossibilities([choice]);
      }
    } else {
      if (unload) {
        let lClean = unloadPossibilities.filter(
          (e) => e !== LOAD_POSSIBILITY.none
        );
        if (lClean.includes(choice)) {
          setUnloadPossibilities(lClean.filter((e) => e !== choice));
        } else {
          setUnloadPossibilities([...lClean, choice]);
        }
      } else {
        let lClean = loadPossibilities.filter(
          (e) => e !== LOAD_POSSIBILITY.none
        );
        if (lClean.includes(choice)) {
          setLoadPossibilities(lClean.filter((e) => e !== choice));
        } else {
          setLoadPossibilities([...lClean, choice]);
        }
      }
    }
  };

  let extraStyles = {};
  if (matchesMD) {
    extraStyles = {
      width: "calc(50% - 5px)",
    };
  }

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
        dispatch({ type: actions.UPDATE_INPUT, payload: defaultData });
      }}
      title={t("edit_route")}
      cancelText={t("cancel")}
      submitText={t("save-changes")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={handleSubmit}
      loading={loading}
      actionsMode="end"
      submitButtonMode="info"
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <Paper>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              justifyContent="flex-start"
              style={{
                gap: "15px",
                width: "100%",
              }}
            >
              <div>
                <Heading type={7} style={{ margin: "5px" }}>
                  {t("origin")}
                </Heading>
                <Paper whiteMode>
                  <Row gap={20}>
                    <Row align="center">
                      <Radio
                        text={t("address")}
                        id="origin_address"
                        name="originType"
                        onChange={() => {
                          handleOnChange(
                            "originType",
                            dispatch
                          )({
                            target: {
                              value: "address",
                            },
                          });
                        }}
                        checked={state?.originType === "address"}
                        required
                      />
                    </Row>
                    <Row>
                      <Radio
                        text={t("port")}
                        id="origin_port"
                        name="originType"
                        onChange={() => {
                          handleOnChange(
                            "originType",
                            dispatch
                          )({
                            target: {
                              value: "port",
                            },
                          });
                        }}
                        checked={state?.originType === "port"}
                        required
                      />
                    </Row>
                  </Row>
                  <div style={{ width: "100%" }}>
                    {state?.originType === "port" ? (
                      <Row>
                        <Label text={`${t("port")}`} noPadding>
                          <CatalogSearch
                            remote
                            id="SelectedPort"
                            inputProps={{
                              required: true,
                              placeholder: t("write-or-select"),
                              mode: "underline",
                            }}
                            initialValue={
                              state?.port && state?.port !== "empty"
                                ? state?.port?.name || state?.port
                                : undefined
                            }
                            catalogName={"ports"}
                            avoidInitalLoad={true}
                            onClickCallback={(
                              option,
                              open,
                              setOpen,
                              value,
                              setValue
                            ) => {
                              handleOnChange(
                                "port",
                                dispatch
                              )({
                                target: {
                                  value: option?.uuid,
                                },
                              });
                              setTimeout(() => {
                                setOpen(false);
                              }, 100);
                            }}
                            onChangeCallback={(e) => {
                              if (_.isEmpty(e.target.value)) {
                                handleOnChange(
                                  "port",
                                  dispatch
                                )({
                                  target: {
                                    value: undefined,
                                  },
                                });
                              }
                            }}
                            mode="underline"
                          />
                        </Label>
                      </Row>
                    ) : (
                      <>
                        <Row gap={30}>
                          <Label text={t("street")} noPadding>
                            <InputText
                              disabled={state?.port}
                              required
                              value={state?.street}
                              onChange={handleOnChange("street", dispatch)}
                              placeholder={t("write-here")}
                              mode="underline"
                            />
                          </Label>
                          <Row gap={30} style={{ width: "100%" }}>
                            <Label text={t("number")} noPadding>
                              <InputText
                                disabled={state?.port}
                                required
                                value={state?.streetNumber}
                                onChange={handleOnChange(
                                  "streetNumber",
                                  dispatch
                                )}
                                placeholder={t("write-here")}
                                mode="underline"
                              />
                            </Label>
                            <Label text={t("postal-code")} noPadding>
                              <InputText
                                disabled={state?.port}
                                required
                                value={state?.postalCode}
                                onChange={handleOnChange(
                                  "postalCode",
                                  dispatch
                                )}
                                placeholder={t("write-here")}
                                mode="underline"
                              />
                            </Label>
                          </Row>
                        </Row>
                        <Row gap={30}>
                          <Label text={t("city")} noPadding>
                            <InputText
                              disabled={state?.port}
                              required
                              value={state?.city}
                              onChange={handleOnChange("city", dispatch)}
                              placeholder={t("write-here")}
                              mode="underline"
                            />
                          </Label>
                          <Label text={t("country")} noPadding>
                            <InputCountries
                              disabled={state?.port}
                              required={true}
                              handleItemClick={handleCountryClick(
                                "countryCode",
                                dispatch
                              )}
                              value={state?.countryCode}
                              mode="underline"
                              onInnerValueChanged={(e) => {
                                if (_.isEmpty(e)) {
                                  handleOnChange(
                                    "countryCode",
                                    dispatch
                                  )({
                                    target: {
                                      value: "",
                                    },
                                  });
                                }
                              }}
                            />
                          </Label>
                        </Row>
                      </>
                    )}
                  </div>
                </Paper>
                {state?.originType !== "port" && (
                  <Paper whiteMode style={{ marginTop: "10px" }}>
                    <LoadPossibilities
                      title={t("load_possibilities")}
                      description={t("load_possibilities_desc")}
                      // data={(equipmentsData || []).map((item) => {
                      //   return { type: item.uuid, text: t(item.name) };
                      // })}
                      handleClick={(type) => handleLoadPossibilities(type)}
                      showIconFn={(type) => loadPossibilities.includes(type)}
                      typeFn={(type) => loadPossibilities.includes(type)}
                      extraStyles={extraStyles}
                    />
                  </Paper>
                )}
              </div>

              <div style={{ width: "100%" }}>
                <Heading type={7} style={{ margin: "5px" }}>
                  {t("destination")}
                </Heading>
                <Paper whiteMode>
                  <Row gap={20}>
                    <Row align="center">
                      <Radio
                        text={t("address")}
                        id="destination_address"
                        name="destinationType"
                        onChange={() => {
                          handleOnChange(
                            "destinationType",
                            dispatch
                          )({
                            target: {
                              value: "address",
                            },
                          });
                        }}
                        checked={state?.destinationType === "address"}
                        required
                      />
                    </Row>
                    <Row>
                      <Radio
                        text={t("port")}
                        id="destination_port"
                        name="destinationType"
                        onChange={() => {
                          handleOnChange(
                            "destinationType",
                            dispatch
                          )({
                            target: {
                              value: "port",
                            },
                          });
                        }}
                        checked={state?.destinationType === "port"}
                        required
                      />
                    </Row>
                  </Row>
                  <div style={{ width: "100%" }}>
                    {state?.destinationType === "port" ? (
                      <Row>
                        <Label text={`${t("port")}`} noPadding>
                          <CatalogSearch
                            remote
                            id="SelectedPort"
                            inputProps={{
                              required: true,
                              placeholder: t("write-or-select"),
                              mode: "underline",
                            }}
                            initialValue={
                              state?.port && state?.port !== "empty"
                                ? state?.port?.name || state?.port
                                : undefined
                            }
                            catalogName={"ports"}
                            avoidInitalLoad={true}
                            onClickCallback={(
                              option,
                              open,
                              setOpen,
                              value,
                              setValue
                            ) => {
                              handleOnChange(
                                "port",
                                dispatch
                              )({
                                target: {
                                  value: option?.uuid,
                                },
                              });
                              setTimeout(() => {
                                setOpen(false);
                              }, 100);
                            }}
                            onChangeCallback={(e) => {
                              if (_.isEmpty(e.target.value)) {
                                handleOnChange(
                                  "port",
                                  dispatch
                                )({
                                  target: {
                                    value: undefined,
                                  },
                                });
                              }
                            }}
                            mode="underline"
                          />
                        </Label>
                      </Row>
                    ) : (
                      <>
                        <Row gap={30}>
                          <Label text={t("street")} noPadding>
                            <InputText
                              disabled={state?.port}
                              required
                              value={state?.street}
                              onChange={handleOnChange("street", dispatch)}
                              placeholder={t("write-here")}
                              mode="underline"
                            />
                          </Label>
                          <Row gap={30} style={{ width: "100%" }}>
                            <Label text={t("number")} noPadding>
                              <InputText
                                disabled={state?.port}
                                required
                                value={state?.streetNumber}
                                onChange={handleOnChange(
                                  "streetNumber",
                                  dispatch
                                )}
                                placeholder={t("write-here")}
                                mode="underline"
                              />
                            </Label>
                            <Label text={t("postal-code")} noPadding>
                              <InputText
                                disabled={state?.port}
                                required
                                value={state?.postalCode}
                                onChange={handleOnChange(
                                  "postalCode",
                                  dispatch
                                )}
                                placeholder={t("write-here")}
                                mode="underline"
                              />
                            </Label>
                          </Row>
                        </Row>
                        <Row gap={30}>
                          <Label text={t("city")} noPadding>
                            <InputText
                              disabled={state?.port}
                              required
                              value={state?.city}
                              onChange={handleOnChange("city", dispatch)}
                              placeholder={t("write-here")}
                              mode="underline"
                            />
                          </Label>
                          <Label text={t("country")} noPadding>
                            <InputCountries
                              disabled={state?.port}
                              required={true}
                              handleItemClick={handleCountryClick(
                                "countryCode",
                                dispatch
                              )}
                              value={state?.countryCode}
                              mode="underline"
                              onInnerValueChanged={(e) => {
                                if (_.isEmpty(e)) {
                                  handleOnChange(
                                    "countryCode",
                                    dispatch
                                  )({
                                    target: {
                                      value: "",
                                    },
                                  });
                                }
                              }}
                            />
                          </Label>
                        </Row>
                      </>
                    )}
                  </div>
                </Paper>
                {state?.destinationType !== "port" && (
                  <Paper whiteMode style={{ marginTop: "10px" }}>
                    <LoadPossibilities
                      title={t("unload_possibilities")}
                      // data={(equipmentsData || []).map((item) => {
                      //   return { type: item.uuid, text: t(item.name) };
                      // })}
                      handleClick={(type) =>
                        handleLoadPossibilities(type, true)
                      }
                      showIconFn={(type) => unloadPossibilities.includes(type)}
                      typeFn={(type) => unloadPossibilities.includes(type)}
                      extraStyles={extraStyles}
                    />
                  </Paper>
                )}
              </div>
            </Flex>
          </Paper>
        </Container>
      }
    />
  );
};

export default ModalEditRoute;
