import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
  InputText,
  Row,
  Flex,
  Label,
  Radio,
  InputNumber,
  InputDate,
  Paper,
  UnderlinedIconButton,
  Heading,
  Accordion,
  IconButton,
  Icon,
  Typography,
  WARM_NEUTRAL_50,
  ClearButton,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getCatalogNoTerm } from "../../../model/filters";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useCRUD } from "../../../utils/crud/hooks";
import { handleOnChange } from "../../../utils/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import CatalogSearch from "../../ui/input/search/CatalogSearch";
import LandSeaTransportEquipment from "../../ui/catalogs/Equipment";
import { BLUE_COLOR } from "../../../utils/variables";
import _ from "lodash";

const defaultService = {
  provider: undefined,
  equipment: [],
  units: 0,
  unitPrice: 0,
  totalCost: 0,
  transitDays: undefined,
  expirationDate: undefined,
};

const defaultData = {
  transportType: undefined,
  incoterm: undefined,
  canDispatch: undefined,
  services: [
    {
      ...defaultService,
    },
  ],
};

const ModalAddQuotation = ({ open, setOpen, isEdit, data, ...props }) => {
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState();
  const { t, i18n } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();
  const { data: catalogData, loading: catalogLoading } = useGQL(
    [getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm],
    [
      {
        locale: i18n.language,
        name: "transporter-equipments",
      },
      {
        locale: i18n.language,
        name: "logistics-services",
      },
      {
        locale: i18n.language,
        name: "logistics-coverage",
      },
      {
        locale: i18n.language,
        name: "logistics-activity",
      },
    ]
  );

  useEffect(() => {
    if (isEdit) {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: data || defaultData,
      });
    } else {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: defaultData,
      });
    }
  }, []);

  const handleServiceOnChange = (index, key, e) => {
    const stateCpy = _.cloneDeep(state);

    if (key === "equipment") {
      const obj = {};
      const alreadySelected = state.services[index][key]?.includes(
        e?.target?.value
      );
      if (alreadySelected) {
        obj[key] = state.services[index][key].filter(
          (f) => f !== e?.target?.value
        );
      } else {
        obj[key] = state.services[index][key]
          ? [...state.services[index][key], e?.target?.value]
          : [e?.target?.value];
      }
      stateCpy.services[index][key] = obj[key];
    } else {
      stateCpy.services[index][key] = e?.target?.value;
    }

    handleOnChange(
      "services",
      dispatch
    )({
      target: {
        value: stateCpy.services,
      },
    });
  };

  const handleAddService = () => {
    const stateCpy = _.cloneDeep(state);

    stateCpy.services.push(defaultService);

    handleOnChange(
      "services",
      dispatch
    )({
      target: {
        value: stateCpy.services,
      },
    });
  };

  const handleRemoveService = (index) => {
    const stateCpy = _.cloneDeep(state);

    handleOnChange(
      "services",
      dispatch
    )({
      target: {
        value: stateCpy.services.filter((element, i) => i !== index),
      },
    });
  };

  const handleSubmit = async () => {
    console.log("handleSubmit");
    console.log(state);
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
        dispatch({ type: actions.UPDATE_INPUT, payload: defaultData });
      }}
      title={t(isEdit ? "edit_quotation_title" : "enter_quotation_title")}
      cancelText={t("cancel")}
      submitText={t(isEdit ? "save-changes" : "send_quotation")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={handleSubmit}
      loading={loading}
      actionsMode="end"
      submitButtonMode="info"
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "15px" }}
          >
            <Label
              text={t("transport-type")}
              small
              style={{ paddingBottom: "13px" }}
            >
              <Row gap={20}>
                <Row align="center">
                  <Radio
                    text="Marítimo"
                    id="transportType_sea"
                    name="transportType"
                    onChange={() => {
                      handleOnChange(
                        "transportType",
                        dispatch
                      )({
                        target: {
                          value: "sea",
                        },
                      });
                    }}
                    checked={state?.transportType === "sea"}
                    required
                  />
                </Row>
                <Row>
                  <Radio
                    text="Terrestre"
                    id="transportType_ground"
                    name="transportType"
                    onChange={() => {
                      handleOnChange(
                        "transportType",
                        dispatch
                      )({
                        target: {
                          value: "ground",
                        },
                      });
                    }}
                    checked={state?.transportType === "ground"}
                    required
                  />
                </Row>
              </Row>
            </Label>
            <Row align="flex-start" gap={20} wrap={matches}>
              <Label
                text={`${t("incoterm")}`}
                small
                style={{
                  paddingBottom: "0",
                  marginBottom: 0,
                  cursor: "pointer",
                }}
              >
                <CatalogSearch
                  preload
                  disableEnterText
                  avoidReload
                  inputProps={{
                    required: true,
                    style: {
                      marginBottom: 20,
                    },
                    mode: "underline",
                  }}
                  initialValue={state?.incoterm?.name}
                  catalogName={"incoterms"}
                  onClickCallback={(option, open, setOpen, value, setValue) => {
                    handleOnChange(
                      "incoterm",
                      dispatch
                    )({
                      target: {
                        value: option.uuid,
                      },
                    });
                    setTimeout(() => {
                      setOpen(false);
                    }, 100);
                  }}
                />
              </Label>
              <div style={{ width: "100%" }}>
                <Label text={t("scrapad_can_dispatch")} noPadding small></Label>
                <Row
                  gap={25}
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <span>
                    <Radio
                      id="canDispatch_yes"
                      name="canDispatch"
                      text={t("yes")}
                      onChange={() => {
                        handleOnChange(
                          "canDispatch",
                          dispatch
                        )({
                          target: {
                            value: true,
                          },
                        });
                      }}
                      checked={state?.canDispatch === true}
                      required
                    />
                  </span>
                  <span>
                    <Radio
                      id="canDispatch_no"
                      name="canDispatch"
                      text={t("no")}
                      onChange={() => {
                        handleOnChange(
                          "canDispatch",
                          dispatch
                        )({
                          target: {
                            value: false,
                          },
                        });
                      }}
                      checked={state?.canDispatch === false}
                      required
                    />
                  </span>
                </Row>
              </div>
            </Row>

            <Paper
              style={{ alignItems: "stretch", gap: "0", paddingTop: "10px" }}
            >
              {state?.services?.map((service, index) => (
                <ServiceItem
                  index={index}
                  service={service}
                  onChange={handleServiceOnChange}
                  onDelete={
                    state?.services.length > 1 ? handleRemoveService : undefined
                  }
                />
              ))}
            </Paper>
            <UnderlinedIconButton
              iconColor={BLUE_COLOR}
              iconName={"add_circle_outline"}
              text={t("add_more_services")}
              textStyles={{ color: BLUE_COLOR, fontWeight: 600 }}
              onClick={() => {
                handleAddService();
              }}
              buttonStyles={{
                marginTop: "15px",
              }}
            />
          </Flex>
        </Container>
      }
    />
  );
};

const ServiceItem = ({
  service = {},
  index = 0,
  onChange = (index, key, value) => {},
  onDelete,
}) => {
  const { t, i18n } = useTranslation(["logistics", "common", "transactions"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  return (
    <Accordion
      toggleAtLead
      minimal
      defaultOpen
      title={
        <Row gap={25} align="center">
          <Heading type={7} style={{ margin: "0 auto 0 0", padding: 0 }}>
            {t("service")} {index + 1} -
            {service?.provider ? service?.provider : ""} (
            {`${service?.totalCost}€`})
          </Heading>
        </Row>
      }
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        style={{
          gap: "10px",
          marginLeft: "-12px",
          marginRight: "-12px",
          marginTop: "-10px",
        }}
      >
        <Paper whiteMode style={{ alignItems: "stretch", width: "100%" }}>
          <Label text={`${t("provider")}`} small noPadding>
            <CatalogSearch
              preload
              disableEnterText
              avoidReload
              inputProps={{
                required: true,
                style: {
                  marginBottom: 20,
                },
                mode: "underline",
              }}
              initialValue={service?.name}
              catalogName={"incoterms"}
              onClickCallback={(option, open, setOpen, value, setValue) => {
                onChange(index, "provider", {
                  target: {
                    value: option.uuid,
                  },
                });

                setTimeout(() => {
                  setOpen(false);
                }, 100);
              }}
            />
          </Label>
          <Row gap={20} wrap={matches}>
            <Label text={t("transport-equipment")} small noPadding>
              <LandSeaTransportEquipment
                handleItemClick={(option, open, setOpen, e) => {
                  onChange(index, "equipment", {
                    target: {
                      value: option.uuid,
                    },
                  });
                }}
                value={service?.equipment || []}
                autoCompleteProps={{
                  mode: "underline",
                  multiple: false,
                  readOnly: false,
                  required: true,
                }}
              />
            </Label>
            <Label text={`${t("units")}`} noPadding small>
              <InputText
                required
                min={0}
                placeholder={t("write-here")}
                step={"1"}
                value={service?.units}
                onChange={(e) => {
                  onChange(index, "units", {
                    target: {
                      value: e.target.value,
                    },
                  });
                }}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={20} wrap={matches}>
            <Label text={`${t("unit-price")} (€)`} small noPadding>
              <InputNumber
                required
                min={0}
                step={".01"}
                placeholder={t("write-here")}
                value={service?.unitPrice}
                onChange={(e) => {
                  onChange(index, "unitPrice", {
                    target: {
                      value: e.target.value,
                    },
                  });
                }}
                mode="underline"
              />
            </Label>
            <Label text={`${t("total-cost")} (€)`} noPadding small>
              <InputText
                required
                readOnly
                min={0}
                placeholder={t("write-here")}
                value={service?.units * service?.unitPrice}
                // value={service?.totalCost}
                // onChange={(e) => {
                //   onChange(index, "totalCost", {
                //     target: {
                //       value: e.target.value,
                //     },
                //   });
                // }}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={20} wrap={matches}>
            <Label text={t("transit_time_in_days")} small noPadding>
              <InputNumber
                required
                min={1}
                placeholder={t("write-here")}
                mode="underline"
                value={service?.transitDays}
                onChange={(e) => {
                  onChange(index, "transitDays", {
                    target: {
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Label>
            <Label text={t("expire-date")} noPadding>
              <InputDate
                required
                placeholder={t("write-here")}
                value={service?.expirationDate}
                handleOnChange={(e, open, setOpen) => {
                  onChange(index, "expirationDate", {
                    target: {
                      value: e?.getTime(),
                    },
                  });
                }}
                languageCode={i18n.language}
                mode="underline"
              />
            </Label>
          </Row>
          {typeof onDelete === "function" && (
            <RemoveServiceButton
              onClick={(e) => {
                e?.preventDefault();
                onDelete(index);
              }}
            />
          )}
        </Paper>
      </Flex>
    </Accordion>
  );
};

function RemoveServiceButton({ onClick = () => {} }) {
  const { t } = useTranslation(["common"]);
  return (
    <ClearButton onClick={onClick}>
      <Row gap={5} align="center">
        <Icon
          name="delete"
          color={WARM_NEUTRAL_50}
          style={{ fontSize: "18px" }}
        />
        <Typography
          noMargin
          style={{ color: WARM_NEUTRAL_50, textDecoration: "underline" }}
        >
          {t("remove_service")}
        </Typography>
      </Row>
    </ClearButton>
  );
}

export default ModalAddQuotation;
