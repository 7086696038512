import {
  Chip,
  ClearButton,
  Divider,
  Flex,
  Heading,
  IconButton,
  Loading,
  Row,
  Typography,
  UnderlinedLink,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useState } from "react";
import { useDocumentsProvider } from "../../../../../providers/documents/DocumentsContext";
import { documentActions } from "../../../../../providers/documents/actions/DocumentsActions";
import UserItem from "../../../../../components/ui/user-item/UserItem";
import LinkWithIcon from "../../../../../components/ui/link/LinkWithIcon";
import { mapSlugToChip } from "../../../../../utils/variables";
import { useTranslation } from "../../../../../utils/hooks";

function DocumentDetailPanel({ uuid }) {
  const { t } = useTranslation(["common"]);
  const { state, dispatch, documentDetails, openFile, downloadLink, copyLink } =
    useDocumentsProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [url, setUrl] = useState();

  useEffect(() => {
    getBasicData();
    fetchData(uuid);
  }, [uuid]);

  const getBasicData = () => {
    let found = false;
    state?.dataVisible?.subfolders?.forEach((folder) => {
      if (folder?.id === uuid) {
        setName(folder?.name);
        setUrl(folder?.url);
        found = true;
        return;
      }
    });

    if (!found) {
      state?.dataVisible?.files?.forEach((file) => {
        if (file?.id === uuid) {
          setName(file?.name);
          setUrl(file?.url);
          return;
        }
      });
    }
  };

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await documentDetails(id);
      console.log(response);
      setData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      style={{ gap: "10px", width: "100%" }}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gap={10} align="flex-start" justify="space-between">
            <Heading type={7} style={{ margin: 0, wordBreak: "break-all" }}>
              {name}
            </Heading>
            <IconButton
              name="close"
              onClick={() => {
                dispatch({
                  type: documentActions.DETAIL_PANEL_CLOSE,
                });
              }}
            />
          </Row>
          {/* <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "10px" }}
          >
            <Typography noMargin style={{ fontSize: "13px" }}>
              <strong>{t("people_with_access")}</strong>
            </Typography>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "0" }}
            >
              <UserItem name={"Juan Carlos Pérez"} />
              <UserItem name={"Juan Carlos Pérez"} />
              <UserItem name={"Juan Carlos Pérez"} />
            </Flex>
          </Flex> */}
          {/* <ClearButton
            onClick={() => {
              dispatch({
                type: documentActions.MANAGE_ACCESS_OPEN,
              });
            }}
          >
            <UnderlinedLink>{t("manage_access")}</UnderlinedLink>
          </ClearButton> */}
          {/* <Divider /> */}
          <Flex
            flexDirection="column"
            alignItems={"stretch"}
            style={{ gap: "5px" }}
          >
            <FileDetails
              date={`${data?.day} ${data?.month} ${data?.year} - ${data?.created_by}`}
              // status={"signed"}
            />
          </Flex>
          <Divider />
          <Flex
            flexDirection="column"
            alignItems={"stretch"}
            style={{ gap: "5px" }}
          >
            <LinkWithIcon
              iconName="open_in_new"
              onClick={() => {
                openFile(url);
              }}
            >
              {t("open_in_sharepoint")}
            </LinkWithIcon>
            <LinkWithIcon
              iconName="link"
              onClick={() => {
                copyLink(uuid);
              }}
            >
              {t("copy_link")}
            </LinkWithIcon>
            <LinkWithIcon
              iconName="file_download"
              onClick={() => {
                downloadLink(uuid);
              }}
            >
              {t("download")}
            </LinkWithIcon>
          </Flex>
        </>
      )}
    </Flex>
  );
}

function FileDetails({ date, status }) {
  const { t } = useTranslation(["common"]);

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      style={{ gap: "15px" }}
    >
      <Typography noMargin style={{ fontSize: "13px" }}>
        <strong>{t("file_details")}</strong>
      </Typography>
      {date && (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          style={{ gap: "0" }}
        >
          <Typography small noMargin>
            Creado
          </Typography>
          <Typography color="light" noMargin style={{ fontSize: "10px" }}>
            {date}
          </Typography>
        </Flex>
      )}
      {status && (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          style={{ gap: "5px" }}
        >
          <Typography small noMargin>
            {t("status")}
          </Typography>
          <Chip type={mapSlugToChip[status]} text={t(status)} />
        </Flex>
      )}
    </Flex>
  );
}

export default DocumentDetailPanel;
