import React, { useEffect, useState } from "react";
import styles from "./KanbanItem.module.scss";
import MiniImage from "../../image/MiniImage";
import {
  Empty,
  Icon,
  Loading,
  Row,
  toastError,
  Tracking,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../utils/hooks";
import { GRAY_NEUTRAL_40, modalTypes } from "../../../../utils/variables";
import _ from "lodash";
import { formatDate, trackingTransformer } from "../../../../utils/functions";
import ModalFactory from "../../modal/ModalFactory";
import ChipOutlined from "../../chip/ChipOutlined";
import { gqlWrapper } from "../../../../utils/gqlwrapper";
import { getTransactionTransportData } from "../../../../model/transactions";
import { useSetDataUser, useSignOut } from "../../../../providers/AuthHooks";

function KanbanItem({
  imageSrc,
  title,
  id,
  isPort,
  address,
  originOrg,
  destinationOrg,
  tracking,
  date,
  status,
  onUpdate = () => {},
  setBottomElement = () => {},
  group,
}) {
  const { t, i18n } = useTranslation(["common", "transactions"]);
  const [open, setOpen] = useState(false);
  const [trackingData, setTrackingData] = useState(tracking);

  const handleStatusChange = async (e) => {
    setOpenChangeStatus(false);
    onUpdate();
  };

  const setOpenChangeStatus = (e) => {
    if (e) {
      setBottomElement(
        <ModalFactory
          type={modalTypes.LOGISTIC_CHANGE_TRACKING_STATUS}
          open={true}
          setOpen={setOpenChangeStatus}
          id={id}
          onSubmit={handleStatusChange}
          group={group}
        />
      );
    } else {
      setBottomElement(<></>);
    }
  };

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );

  return (
    <>
      <div
        // onClick={() => {
        //   setOpen(!open);
        // }}
        className={styles["kanban_item"]}
      >
        <div className={styles["header"]}>
          <div className={styles["feature_image"]}>
            <MiniImage
              src={imageSrc || " "}
              width={45}
              height={45}
              containerStyle={{
                minWidth: "45px",
                minHeight: "45px",
                backgroundColor: GRAY_NEUTRAL_40,
                borderRadius: "5px",
              }}
            />
          </div>
          <div className={styles["title_wrap"]}>
            <h5 className={styles["title"]}>{title}</h5>
            <Row gap={5}>
              <ChipOutlined
                iconComponent={typeIcon}
                onClick={() => {
                  window?.open(`/transactions/${id}/transport`, "_blank");
                }}
              >
                <span style={{ textTransform: "uppercase" }}>
                  {id?.split("-")[0]}
                </span>
              </ChipOutlined>
              {/* <ChipOutlined iconComponent={typeIcon}>
                {uuid?.split("-")[0]}
              </ChipOutlined> */}
            </Row>
            {/* <span
              className={styles["desc"]}
              onClick={() => {
                window?.open(`/transactions/${id}/transport`, "_blank");
              }}
            >
              {id?.split("-")[0]}
            </span> */}
          </div>
        </div>
        <div className={styles["body"]}>
          <div className={styles["actions"]}>
            <ItemStatus onClick={() => setOpenChangeStatus(true)}>
              {t(status?.toLowerCase())}{" "}
              <Icon
                name={"edit"}
                style={{
                  fontSize: "17px",
                  lineHeight: "normal",
                  position: "relative",
                  top: "4px",
                }}
              />
            </ItemStatus>
            {/* <div className={styles["status_change_action_wrap"]}>
            <ChipDropdown
              items={[{ uuid: "e23" }, { uuid: "e23" }, { uuid: "e23" }].map(
                (item, index) => (
                  <ClearButton
                    key={item.uuid}
                    onClick={() => handleStatusChange(item)}
                  >
                    botón {index}
                  </ClearButton>
                )
              )}
              type="clear"
              text={_.isEmpty(status) ? t("select-value") : status}
              dropDownStyle={{
                position: "static",
                top: 0,
                left: 0,
              }}
            />
          </div> */}
          </div>
          <div className={styles["address"]}>
            <AddressRow
              isPort={isPort}
              text={address}
              onClick={() => {
                setOpen(!open);
              }}
              isOpen={open}
            />
          </div>
        </div>

        {open && (
          <div className={styles["tracking"]}>
            <TrackingSection
              trackingData={trackingData}
              uuid={id}
              afterLoad={(e) => {
                setTrackingData(e);
              }}
            />
          </div>
        )}

        <div className={styles["footer"]}>
          <div className={styles["business_name"]}>
            {originOrg} - {destinationOrg}
          </div>
          <div className={styles["date"]}>{formatDate(date, i18n)}</div>
        </div>
      </div>
    </>
  );
}

function AddressRow({ isPort, text, onClick, isOpen = false }) {
  return (
    <div className={styles["address_row"]}>
      <div className={styles["icon"]}>
        {isPort ? (
          <Icon
            name={"directions_boat"}
            color="#9E9E9E"
            style={{
              fontSize: "15px",
              paddingTop: "4px",
              paddingRight: "5px",
            }}
          />
        ) : (
          <Icon
            name={"directions_bus"}
            color="#9E9E9E"
            style={{
              fontSize: "15px",
              paddingTop: "4px",
              paddingRight: "5px",
            }}
          />
        )}
      </div>
      <div className={styles["text"]}>{text}</div>
      {typeof onClick === "function" && (
        <div className={styles["toggle_button"]} onClick={onClick}>
          <Icon
            name={isOpen ? "expand_less" : "expand_more"}
            style={{ fontSize: "17px" }}
          />
        </div>
      )}
    </div>
  );
}

function ItemStatus({ children, onClick }) {
  return (
    <span className={styles["item_status"]} onClick={onClick}>
      {children}
    </span>
  );
}

function TrackingSection({ trackingData, uuid, afterLoad = () => {} }) {
  const { i18n } = useTranslation("common");
  const [data, setData] = useState(trackingData);
  const [loading, setLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  useEffect(() => {
    if (_.isEmpty(trackingData)) {
      fetchTrackingData();
    }
  }, []);

  const fetchTrackingData = async () => {
    setLoading(true);
    try {
      const fn = await gqlWrapper(
        getTransactionTransportData,
        setDataUser,
        signOut
      );
      const results = await fn(i18n.language, uuid);
      if (results === null) {
        setData({});
      } else {
        if (results?.errors?.length > 0) {
          setData({});
          toastError(results?.errors[0]?.message);
        } else {
          afterLoad(results);
          setData(results);
        }
      }
    } catch (e) {
      setData({});
      toastError(e.message || e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (_.isEmpty(data)) {
    return <Empty />;
  }

  return (
    <Tracking
      breakpoint={7000}
      steps={trackingTransformer(trackingData, i18n)}
    />
  );
}

export default KanbanItem;
