import { FolderIcon, Row } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./FileItem.module.scss";
import Menu from "../../../../../../components/ui/menu/Menu";
import MenuItem from "../../../../../../components/ui/menu/MenuItem";
import { documentActions } from "../../../../../../providers/documents/actions/DocumentsActions";
import { clientTypes, OWNER_TYPES } from "../../../../../../utils/variables";
import { useTranslation } from "../../../../../../utils/hooks";
import { useDocumentsProvider } from "../../../../../../providers/documents/DocumentsContext";
import _ from "lodash";

function FileItem({
  uuid,
  selected = false,
  isFolder = true,
  thumbnailUrl,
  name,
  url,
  status,
  isPrivate,
  onCheck = () => {},
  onClick = () => {},
}) {
  const { t } = useTranslation(["common"]);
  const { state, dispatch, openFile, downloadLink, copyLink } =
    useDocumentsProvider();

  const renderMenu = () => {
    let owner = "";
    state?.breadcrumbs?.forEach((bc) => {
      if (!_.isEmpty(bc?.owner)) {
        owner = bc?.owner;
      }
    });

    if (isFolder) {
      return (
        <Menu
          selfManaged={true}
          items={[
            <MenuItem
              icon={"person_add"}
              onClick={() => {
                dispatch({
                  type: documentActions.MANAGE_ACCESS_OPEN,
                });
              }}
            >
              {t("manage_access")}
            </MenuItem>,
            <MenuItem icon={"add_link"} onClick={() => {}}>
              {t("generate_link")}
            </MenuItem>,
            <MenuItem
              icon={"open_in_new"}
              onClick={() => {
                openFile(url);
              }}
            >
              {t("open")}
            </MenuItem>,
            // <MenuItem
            //   icon={"download"}
            //   onClick={() => {
            //     downloadLink(uuid);
            //   }}
            // >
            //   {t("download")}
            // </MenuItem>,
            <MenuItem
              icon={"info"}
              onClick={() => {
                dispatch({
                  type: documentActions.DETAIL_PANEL_OPEN,
                  payload: {
                    uuid: uuid,
                  },
                });
              }}
            >
              {t("see_details")}
            </MenuItem>,
          ]}
        />
      );
    }

    return (
      <Menu
        selfManaged={true}
        items={[
          <MenuItem
            icon={"edit"}
            onClick={() => {
              dispatch({
                type: documentActions.RENAME_SET,
                payload: {
                  formerName: name,
                  uuid: uuid,
                },
              });
            }}
          >
            {t("rename")}
          </MenuItem>,
          <MenuItem
            icon={"open_with"}
            onClick={() => {
              dispatch({
                type: documentActions.MOVE_OPEN,
              });
            }}
          >
            {t("move")}
          </MenuItem>,
          <MenuItem
            icon={"share"}
            onClick={() => {
              copyLink(uuid);
            }}
            title={"Compartir"}
            withSubitems
          >
            <MenuItem onClick={() => {}}>Copiar link</MenuItem>
            <MenuItem
              disabled={owner === OWNER_TYPES.buyer}
              onClick={() => {
                dispatch({
                  type: documentActions.SHARE_OPEN,
                  payload: {
                    clientType: clientTypes.BUYER,
                  },
                });
              }}
            >
              {t("buyer")}
            </MenuItem>
            <MenuItem
              disabled={owner === OWNER_TYPES.seller}
              onClick={() => {
                dispatch({
                  type: documentActions.SHARE_OPEN,
                  payload: {
                    clientType: clientTypes.SELLER,
                  },
                });
              }}
            >
              {t("seller")}
            </MenuItem>
          </MenuItem>,
          <MenuItem
            icon={"person_add"}
            onClick={() => {
              dispatch({
                type: documentActions.MANAGE_ACCESS_OPEN,
              });
            }}
          >
            {t("manage_access")}
          </MenuItem>,
          <MenuItem
            icon={"download"}
            onClick={() => {
              downloadLink(uuid);
            }}
          >
            {t("download")}
          </MenuItem>,
          <MenuItem
            icon={"open_in_new"}
            onClick={() => {
              openFile(url);
            }}
          >
            {t("open")}
          </MenuItem>,
          <MenuItem
            icon={"info"}
            onClick={() => {
              dispatch({
                type: documentActions.DETAIL_PANEL_OPEN,
                payload: {
                  uuid: uuid,
                },
              });
            }}
          >
            {t("see_details")}
          </MenuItem>,
          <MenuItem
            icon={"info"}
            onClick={() => {
              dispatch({
                type: documentActions.DELETE_FILE_SET,
                payload: {
                  formerName: name,
                  uuid: uuid,
                },
              });
            }}
          >
            {t("remove")}
          </MenuItem>,
        ]}
      />
    );
  };

  return (
    <div
      className={`${styles["file_item"]} ${
        isFolder ? styles["folder"] : styles["is-file"]
      } ${selected ? styles["selected"] : ""}`}
      onClick={onClick}
    >
      {!isFolder && (
        <div
          className={styles["selected_check"]}
          onClick={(e) => {
            e.stopPropagation();
            onCheck();
          }}
        ></div>
      )}

      {isFolder ? (
        <div className={styles["file_menu"]}>
          {/* <IconButton name="more_vert" type="button" onClick={() => {}} /> */}
          {renderMenu()}
        </div>
      ) : (
        <></>
      )}
      <div className={styles["header"]}>
        {isFolder ? (
          <FolderIcon lockMode={isPrivate} />
        ) : (
          <>
            <div
              className={styles["preview-image"]}
              style={{
                backgroundImage: `url(${thumbnailUrl})`,
              }}
            ></div>
          </>
        )}
      </div>
      <div className={styles["body"]}>
        {isFolder ? (
          <p className={styles["title_folder"]} title={"title"}>
            {name}
          </p>
        ) : (
          <>
            <Row gap={15}>
              <p className={styles["title_file"]} title={"title"}>
                {name}
              </p>
              <div className={styles["file_menu"]}>{renderMenu()}</div>
            </Row>
          </>
        )}
      </div>
    </div>
  );
}

export default FileItem;
