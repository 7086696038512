import { gql } from "@apollo/client";
import client from "../utils/gqlclient";
import { QUERY_UPPER_LIMIT, tableModules } from "../utils/variables";

export async function getTransactionDetail(locale, conversationUuid) {
  const data = await client.query({
    query: getLogForConversation,
    variables: {
      lang: locale,
      conversationUuid,
    },
  });
  return data.errors
    ? { errors: data.errors }
    : data.data.getLogForConversation;
}

export async function getTransactionTransportData(locale, conversationUuid) {
  const data = await client.query({
    query: getConversationTransportData,
    variables: {
      lang: locale,
      conversationUuid,
    },
  });
  return data.errors
    ? { errors: data.errors }
    : data.data.getLogForConversation?.sideData?.transportData || {};
}

export async function getTransactionRawEvent(uuidEvent) {
  const data = await client.query({
    query: getRawEvent,
    variables: { uuidEvent },
  });
  return data.data.getRawEvent;
}

export async function getTransactionLastRawEvent({ uuidConversation, slug }) {
  const data = await client.query({
    query: getRawEventLast,
    variables: { uuidConversation, slug },
  });
  return data.data.getRawEventBO;
}

export async function getAdTransactions({
  locale,
  uuid,
  limit = QUERY_UPPER_LIMIT,
}) {
  const data = await client.query({
    query: gqlGetTable,
    variables: {
      lang: locale,
      tableModule: tableModules.TRANSACTIONS,
      offset: 0,
      limit: limit,
      filter: {
        ops: [
          {
            field: "uuidAd",
            value: uuid,
          },
        ],
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}

export async function getTransactionDataBO(locale, conversationUuid) {
  const data = await client.query({
    query: getTransactionDataBOGql,
    variables: {
      lang: locale,
      conversationUuid,
    },
  });
  return data.errors
    ? { errors: data.errors }
    : data.data.getTransactionDataBO || {};
}

const getLogForConversation = gql`
  query ($lang: String!, $conversationUuid: UUID!) {
    getLogForConversation(lang: $lang, conversationUuid: $conversationUuid) {
      header {
        adTitle
        startedAt
        startedBy
        uuidConversation
      }
      sideData {
        aboutTransaction {
          buyer
          initDate
          phase
          seller
        }
        originAd {
          adType
          type
          amount
          image
          material
          price
          title
          uuidAd
        }
        offerData {
          amount
          buyerPaymentMethod
          buyerTerms {
            active
            amount
            desc
            date
          }
          isAccepted
          marginScrapAd
          proposedPrice
          sellerPaymentMethod
          sellerTerms {
            active
            amount
            desc
            uuidDeposit
          }
          totalBuyyer
          totalSeller
        }
        transportData {
          advertiser {
            address
            orgName
            orgUuid
            portAddress
          }
          interested {
            address
            orgName
            orgUuid
            portAddress
          }
          tracking {
            desc
            phase
            entries {
              date
              desc
              estimatedHours
              state
            }
          }
          lastPhase
        }
      }
      log {
        date
        logItems {
          key
          type
          phase
          eventName
          content
          contentData {
            key
            value
          }
          originatedBy
          date
          realatedMessage {
            key
            type
            content
            originatedBy
            date
            contentData {
              key
              value
            }
          }
        }
      }
    }
  }
`;

const getConversationTransportData = gql`
  query ($lang: String!, $conversationUuid: UUID!) {
    getLogForConversation(lang: $lang, conversationUuid: $conversationUuid) {
      sideData {
        transportData {
          advertiser {
            address
            orgName
            orgUuid
            portAddress
          }
          interested {
            address
            orgName
            orgUuid
            portAddress
          }
          tracking {
            desc
            phase
            entries {
              date
              desc
              estimatedHours
              state
            }
          }
          lastPhase
        }
      }
    }
  }
`;

// const getLogForConversation = gql`
//   query ($lang: String!, $conversationUuid: UUID!) {
//     getLogForConversation(lang: $lang, conversationUuid: $conversationUuid) {
//       header {
//         adTitle
//         startedAt
//         startedBy
//         uuidConversation
//       }
//       sideData {
//         aboutTransaction {
//           label
//           value
//         }
//         originAd {
//           label
//           value
//         }
//         offerData {
//           amount
//           deliveryTolerance
//           grossWeight
//           sellerPhases {
//             phaseName
//             value
//           }
//           buyerPhases {
//             phaseName
//             value
//           }
//           proposedPrice
//           totalServices
//           totalTransaction
//           totalServicesBuyer
//           transportType
//         }
//         advertiser {
//           address
//           orgName
//           role
//           orgUuid
//           uuidPerson
//         }
//         interested {
//           address
//           orgName
//           role
//           orgUuid
//           uuidPerson
//         }
//       }
//       log {
//         date
//         logItems {
//           content
//           contentData {
//             key
//             value
//           }
//           originatedBy
//           date
//         }
//       }
//     }
//   }
// `;

// const getLogForConversation = gql`
//   query ($lang: String!, $conversationUuid: UUID!) {
//     getLogForConversation(lang: $lang, conversationUuid: $conversationUuid) {
//       header {
//         adTitle
//         startedAt
//         startedBy
//         uuidConversation
//       }
//       sideData {
//         aboutTransaction {
//           label
//           value
//         }
//         originAd {
//           label
//           value
//         }
//         offerData {
//           amount
//           grossWeight
//           sellerPhases {
//             phaseName
//             value
//           }
//           buyerPhases {
//             phaseName
//             value
//           }
//           proposedPrice
//           totalServices
//           totalTransaction
//           totalServicesBuyer
//           transportType
//           incoterm {
//             uuid
//             name
//           }
//           paymentMethod {
//             uuid
//             name
//           }
//         }
//         advertiser {
//           address
//           orgName
//           role
//           orgUuid
//           uuidPerson
//           portAddress
//         }
//         interested {
//           address
//           orgName
//           role
//           orgUuid
//           uuidPerson
//           portAddress
//         }
//       }
//       log {
//         date
//         logItems {
//           key
//           type
//           content
//           phase
//           eventName
//           contentData {
//             key
//             value
//           }
//           originatedBy
//           date
//           realatedMessage {
//             key
//             type
//             content
//             originatedBy
//             date
//             contentData {
//               key
//               value
//             }
//           }
//         }
//       }
//     }
//   }
// `;

const gqlGetTable = gql`
  query (
    $lang: String!
    $tableModule: String!
    $offset: Int!
    $limit: Int!
    $filter: FilterOpsTables
  ) {
    tableData(
      lang: $lang
      tableModule: $tableModule
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      columns {
        name
        filterable
        sortable
        ref
      }
      rows {
        uuid
        cells {
          value
          format
          colRef
        }
      }
    }
  }
`;

const getRawEvent = gql`
  query ($uuidEvent: String!) {
    getRawEvent(uuidEvent: $uuidEvent) {
      event
      slug
    }
  }
`;

const getRawEventLast = gql`
  query ($uuidConversation: String!, $slug: [String!]) {
    getRawEventBO(uuidConversation: $uuidConversation, slug: $slug) {
      event
      slug
    }
  }
`;

const getTransactionDataBOGql = gql`
  query ($lang: String!, $conversationUuid: UUID!) {
    getTransactionDataBO(lang: $lang, conversationUuid: $conversationUuid) {
      header {
        adTitle
        startedAt
        startedBy
        uuidConversation
      }
      sideData {
        aboutTransaction {
          buyer
          initDate
          phase
          seller
        }
        originAd {
          adType
          type
          amount
          image
          material
          price
          title
          uuidAd
        }
        offerData {
          amount
          buyerPaymentMethod
          buyerTerms {
            active
            amount
            desc
            date
          }
          isAccepted
          marginScrapAd
          proposedPrice
          sellerPaymentMethod
          sellerTerms {
            active
            amount
            desc
            uuidDeposit
          }
          totalBuyyer
          totalSeller
        }
        transportData {
          advertiser {
            address
            orgName
            orgUuid
            portAddress
          }
          interested {
            address
            orgName
            orgUuid
            portAddress
          }
          tracking {
            desc
            phase
            entries {
              date
              desc
              estimatedHours
              state
            }
          }
          lastPhase
        }
      }
    }
  }
`;
