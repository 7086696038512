import {
  BREAKPOINT_LG,
  Flex,
  Heading,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import TransactionFiles from "../../../../components/ui/input/file/File";
import DocumentLocationItem from "../../components/document-location-item/DocumentLocationItem";
import DocumentLocationSelector from "../../components/location-selector/DocumentLocationSelector";

function ModalDocumentMove({ open = false, setOpen, ...props }) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("move")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("move")}
      cancelText={t("cancel")}
      actionsMode="end"
      submitProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      cancelProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      loading={loading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <>
          <div>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "15px" }}
            >
              <TransactionFiles
                files={files}
                readOnly={false}
                hideFileType={true}
                showFilesAndInput={false}
                hideFileInput
                forceShowFileList
                selectedLabel={"Elementos seleccionados"}
              />
              <Heading type={4} style={{ margin: 0 }}>
                Elegir ubicación
              </Heading>
              <DocumentLocationItem
                locationName={"Identificación de residuo"}
              />
              <DocumentLocationSelector />
            </Flex>
          </div>
        </>
      }
    />
  );
}

export default ModalDocumentMove;

