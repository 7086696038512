import {
  BREAKPOINT_MD,
  Button,
  Empty,
  Flex,
  Heading,
  Loading,
  Paper,
  Row,
  Spinner,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../utils/hooks";
import WidgetDataWrapper from "../wrappers/DataWrapper";
import { useParams } from "react-router";
import { getOpportunityOffers } from "../../model/opportunities";
import OpportunityOffer from "./widgets/Offer/OpportunityOffer";
import _ from "lodash";
import OpportunityOfferForm from "./OpportunityOfferForm";
import WidgetOpportunitiesCosts from "./Costs";
import { format } from "date-fns";
import { useUser } from "../../providers/AuthHooks";
import { Link } from "react-router-dom";
import { currencySymbolMapWithWeight } from "../../utils/variables";
import { getRoomSlug, WS_ROOM } from "../../utils/websockets/functions";
import WebsocketsWrapper from "../websockets/Wrapper";

function OpportunityOfferPage({
  loading,
  setLoading,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  isDirty,
  setIsDirty,
  bufferState,
  checkIfStateIsDirty,
  opportunityType,
  id,
  maxAmount,
  opportunityOwner,
  title,
  material,
  files,
  paymentMethod,
  ...props
}) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [openNewOffer, setOpenNewOffer] = useState(false);
  const [editOfferUuid, setEditOfferUuid] = useState(undefined);
  const [selectedOffer, setSelectedOffer] = useState(undefined);
  const user = useUser();
  const offers = Object.values(state);

  useEffect(() => {
    if (_.isEmpty(offers)) {
      setSelectedOffer(undefined);
    }
  }, [offers]);

  const toggleNewOffer = () => {
    setOpenNewOffer(!openNewOffer);
    setEditOfferUuid(undefined);
  };

  const closeCostArea = () => {
    setSelectedOffer(undefined);
  };

  if (openNewOffer) {
    return (
      <Paper style={{ padding: "15px", alignItems: "stretch" }}>
        <OpportunityOfferForm
          uuid={editOfferUuid}
          opportunityId={id}
          opportunityType={opportunityType}
          onBack={toggleNewOffer}
          maxAmount={maxAmount}
          opportunityPaymentMethod={paymentMethod}
        />
      </Paper>
    );
  }

  return (
    <Flex
      flexDirection={matches ? "column" : "row"}
      style={{
        gap: "15px",
      }}
    >
      <Paper style={{ padding: "15px", alignItems: "stretch" }}>
        <Flex
          flexDirection="column"
          alignItems="stretch"
          justifyContent="flex-start"
          style={{ gap: "15px" }}
        >
          <Row gap={15} align="center" justify="space-between">
            <Heading type={5} style={{ margin: 0 }}>
              {t("offers")}
            </Heading>
            <Button
              fullwidth={matches ? true : undefined}
              component="button"
              mode="info"
              text={t("make_an_offer")}
              onClick={toggleNewOffer}
              reduced
              data-cy={"opportunity-make-offer"}
            />
          </Row>

          {loading ? (
            <Spinner />
          ) : (
            <>
              {_.isEmpty(state) ? (
                <Flex data-cy="opportunity-offers-empty">
                  <Empty />
                </Flex>
              ) : (
                <Flex
                  flexDirection={"column"}
                  style={{
                    gap: "15px",
                  }}
                  data-cy="opportunity-offers"
                >
                  {offers?.map((offer) => (
                    <OpportunityOffer
                      key={`op_of_${offer?.uuidOffer}`}
                      opportunityId={id}
                      uuid={offer?.uuidOffer}
                      status={offer?.status}
                      opportunityType={opportunityType}
                      active={selectedOffer === offer?.uuidOffer}
                      title={`${t("offer")} ${
                        offer?.uuidOffer?.split("-")?.[0]
                      }`}
                      transactionUuid={offer?.uuidTransaction}
                      amount={`${offer?.amount}t`}
                      date={`${
                        offer?.uuidBOUser === user?.userUuid
                          ? t("you")
                          : offer?.nameBO
                      } - ${format(
                        Number(offer?.updatedDate),
                        "dd/MM/yyyy HH:mm"
                      )}`}
                      owner={
                        offer?.orgName ? (
                          <Link
                            to={`/business/${offer?.uuidOrg}`}
                            target="_blank"
                          >
                            {offer?.orgName}
                          </Link>
                        ) : undefined
                      }
                      price={`${offer?.price} ${
                        currencySymbolMapWithWeight[offer?.currency]
                          ? currencySymbolMapWithWeight[offer?.currency]
                          : offer?.currency
                      }`}
                      onClick={(e) => {
                        if (e !== selectedOffer) {
                          setSelectedOffer(undefined);
                          setTimeout(() => {
                            setSelectedOffer(e);
                          }, 25);
                        }
                      }}
                      itsMine={offer?.uuidBOUser === user?.userUuid}
                      uuidUserCounterOffer={offer?.uuidUserCounterOffer}
                      opportunityOwner={opportunityOwner}
                      onEdit={() => {
                        setEditOfferUuid(offer?.uuidOffer);
                        setOpenNewOffer(true);
                        setSelectedOffer(undefined);
                      }}
                      onRemoved={() => {
                        setSelectedOffer(undefined);
                      }}
                      closeCostArea={closeCostArea}
                      currency={offer?.currency}
                      opportunityTitle={title}
                      material={material}
                      files={files}
                    />
                  ))}
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Paper>
      {selectedOffer && (
        <Paper>
          <WidgetOpportunitiesCosts
            offers={offers}
            opportunityId={id}
            uuid={selectedOffer}
            opportunityType={opportunityType}
            opportunityOwner={opportunityOwner}
          />
        </Paper>
      )}
      {user && (
        <WebsocketsWrapper
          callback={async (data) => {
            if (
              !_.isEmpty(data) &&
              data?.subscribeRoom?.type === WS_ROOM.opportunity &&
              data?.subscribeRoom?.uuid === id
            ) {
              await fetchData();
            }
          }}
          room={getRoomSlug("/opportunities")}
        />
      )}
    </Flex>
  );
}

function OpportunityOffers({ state = {}, loading = false }) {
  const { id } = useParams();
  const { i18n } = useTranslation(["common"]);
  if (loading || _.isEmpty(state)) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <WidgetDataWrapper
        id={id}
        Component={OpportunityOfferPage}
        fnData={getOpportunityOffers}
        fnParameters={{
          uuid: id,
          locale: i18n.language,
        }}
        opportunityType={state?.opportunityType}
        maxAmount={state?.amount}
        opportunityOwner={state?.boUser?.uuid}
        title={state?.title}
        material={state?.material}
        files={state?.files}
        paymentMethod={state?.paymentMethod}
      />
    </div>
  );
}

export default OpportunityOffers;
