import {
  Alert,
  AutoComplete,
  BREAKPOINT_MD,
  Button,
  DropDown,
  Flex,
  Heading,
  Icon,
  IconButton,
  InputNumber,
  Label,
  Paper,
  Row,
  Spacer,
  TextArea,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useRef, useState } from "react";
import { useTranslation } from "../../utils/hooks";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { handleOnChange } from "../../utils/functions";
import _ from "lodash";
import BusinessRegister from "../business/register/BusinessRegister";
import NewAddressForm from "../business/addresses/NewAddressForm";
import FormWrap from "../../components/ui/form-wrap/FormWrap";
import AddressSelector from "../../wrappers/address/AddressSelector";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalog, getCatalogNoTerm } from "../../model/filters";
import { currencyOptions } from "../../utils/variables";
import FilesList from "../fields/FilesList";
import deepmerge from "deepmerge";
import WidgetDataWrapper from "../wrappers/DataWrapper";
import { getOpportunityOfferDetail } from "../../model/opportunities";
import CatalogSearch from "../../components/ui/input/search/CatalogSearch";
import AdFiles from "../ads/AdFile";
import { handleCRUD } from "../../utils/crud/functions";

function OpportunityOfferFormInner({
  loading,
  setLoading,
  state,
  dispatch,
  actions,
  fetchData = () => {},
  updateData = () => {},
  extraData,
  isDirty,
  setIsDirty,
  bufferState,
  checkIfStateIsDirty,
  uuid,
  maxAmount,
  opportunityType,
  opportunityId,
  opportunityPaymentMethod,
  onBack = () => {},
  ...props
}) {
  const { t, i18n } = useTranslation(["common", "ads", "talk"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  const filesBuffer = useRef([]);
  const [showNewBusiness, setShowNewBusiness] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [orgName, setOrgName] = useState(state?.orgName);
  // const [isPort, setIsPort] = useState(state?.address?.port);
  const [incotermName, setIncotermName] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [requiredFinancing, setRequiredFinancing] = useState(false);
  const { data: currencyList } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "currency",
  });

  const { data: paymentMethodSellerList } = useGQL(getCatalog, {
    locale: i18n.language,
    name: "payment-method-seller",
    term: opportunityPaymentMethod?.uuid,
  });

  console.log(paymentMethodSellerList);

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    let data = {
      ...state,
      ...{
        uuidUser: user?.userUuid,
        files: state?.files?.map((file) => file.uuid),
        amount: state?.amount?.split(" ")[0] * 100 * 1,
        proposedPrice: state?.proposedPrice * 100 * 1,
        // material: !_.isEmpty(state?.material?.name)
        //   ? state?.material?.uuid
        //   : state?.material,
      },
    };

    if (opportunityType !== "sale") {
      delete data?.incoterm;
      delete data?.paymentMethod;
    } else {
      if (data?.incoterm?.name) {
        data.incoterm = data.incoterm.uuid;
      }
      if (data?.paymentMethod?.name) {
        data.paymentMethod = data.paymentMethod.uuid;
      }
    }

    if (uuid) {
      delete data?.orgName;
      delete data?.updatedDate;
      delete data?.opportunityTypeDesc;
    }

    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: data,
      successCallback: (e) => {
        setIsDirty(false);
        setTimeout(() => {
          fetchData();
          updateData();
        }, 25);
        onBack();
        // setTimeout(() => {
        //   navigate(`/opportunities/${opportunityId}`, { replace: true });
        // }, 25);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const onBusinessRegistered = (e) => {
    if (!_.isEmpty(e?.uuid)) {
      handleOnChange(
        "interestedOrg",
        dispatch
      )({
        target: {
          value: e?.uuid,
        },
      });
      setOrgName(e?.name);
    }
    setShowNewBusiness(false);
  };

  const onAddressRegistered = () => {
    setShowNewAddress(false);
  };
  const handleItemSelected = (key) => (option, open, setOpen) => {
    const obj = {};
    obj[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setOpen(!open);
  };

  const handleAddImage = (e) => {
    const newState = deepmerge([], state);
    const filesCpy = newState?.files || [];
    newState["files"] = [...filesCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleDeleteImage = (e) => {
    const newState = deepmerge([], state);
    newState["files"] = newState["files"]?.filter(
      (element) => e.uuid !== element.uuid
    );
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleSortImage = (dragIndex, hoverIndex) => {
    if (state?.files?.length === 0) return;
    const newState = deepmerge([], state);
    const bkState = deepmerge([], state);
    newState?.files?.splice(dragIndex, 1);
    newState?.files?.splice(hoverIndex, 0, bkState?.files?.[dragIndex]);
    filesBuffer.current = [...newState?.files];
  };

  const handleAddress =
    (dispatch = () => {}) =>
    (e = {}, isFav = false, keepId = false) => {
      const newState = deepmerge([], state);
      if (e?.port) {
        newState["selectedAddress"] = { ...newState?.selectedAddress, ...e };
      } else {
        if (isFav) {
          newState["selectedAddress"] = { ...e };
        } else {
          newState["selectedAddress"] = { ...newState?.selectedAddress, ...e };
          if (!keepId) delete newState["selectedAddress"]["id"];
        }
      }
      dispatch({ type: actions.UPDATE_INPUT, payload: newState });
    };

  if (showNewBusiness) {
    return (
      <div>
        <Row align="center" gap={10}>
          <IconButton
            type="button"
            name={"arrow_back"}
            onClick={() => {
              setShowNewBusiness(false);
            }}
          />
          <Heading type={5} style={{ margin: 0 }}>
            {t("new_business")}
          </Heading>
        </Row>
        <Spacer height={10} />
        <BusinessRegister onSuccess={onBusinessRegistered} />
      </div>
    );
  }

  if (showNewAddress) {
    return (
      <div>
        <Row align="center" gap={10}>
          <IconButton
            type="button"
            name={"arrow_back"}
            onClick={() => {
              setShowNewAddress(false);
            }}
          />
          <Heading type={5} style={{ margin: 0 }}>
            {t("new_address")}
          </Heading>
        </Row>

        <NewAddressForm
          orgUuid={state?.interestedOrg}
          onSuccess={onAddressRegistered}
        />
      </div>
    );
  }

  const isEdit = uuid ? true : false;

  return (
    <form onSubmit={handleSubmitWidget}>
      <Flex
        flexDirection={"column"}
        alignItems={"stretch"}
        justifyContent={"flex-start"}
        style={{ gap: "15px" }}
      >
        <Row align="center" gap={10}>
          <IconButton
            type="button"
            name={"arrow_back"}
            onClick={() => {
              onBack(false);
            }}
          />
          <Heading type={5} style={{ margin: 0 }}>
            {uuid ? t("edit-offer") : t("new-offer")}
          </Heading>
        </Row>
        <Paper
          whiteMode
          style={{ padding: "15px 20px", alignItems: "stretch", gap: "15px" }}
        >
          <Row align="center" justify="space-between">
            <Heading type={5} style={{ margin: 0 }}>
              {t("business_singular")}
            </Heading>
            {isEdit ? (
              <></>
            ) : (
              <Button
                text={t("new_business")}
                mode="outline-info"
                reduced
                component="button"
                onClick={() => setShowNewBusiness(true)}
              />
            )}
          </Row>
          <CatalogSearch
            required
            initialValue={!_.isEmpty(orgName) ? orgName : state?.interestedOrg}
            inputProps={{
              readOnly: isEdit,
              position: "absolute",
              leadingIcon: (
                <Icon
                  name={"search"}
                  color={"#B1B1B1"}
                  style={{
                    position: "relative",
                    left: -10,
                    fontSize: 20,
                  }}
                />
              ),
              trailingIcon: <></>,
            }}
            catalogName={"org-bo-asigned"}
            //catalogName={"org-autocomplete"}
            onClickCallback={(option, open, setOpen) => {
              handleOnChange(
                "interestedOrg",
                dispatch
              )({
                target: {
                  value: option.uuid,
                },
              });
              setOrgName(option.name);
            }}
            onChangeCallback={(e) => {
              if (_.isEmpty(e.target.value)) {
                handleOnChange(
                  "interestedOrg",
                  dispatch
                )({
                  target: {
                    value: undefined,
                  },
                });
                setOrgName(undefined);
              }
            }}
            mode={"underline"}
          />
        </Paper>
        <FormWrap disabled={_.isEmpty(state?.interestedOrg)}>
          <Paper
            whiteMode
            style={{
              padding: "15px 20px",
              alignItems: "stretch",
              gap: "20px",
            }}
          >
            <Row align="center" justify="space-between">
              <Heading type={5} style={{ margin: 0 }}>
                {t(
                  opportunityType !== "sale"
                    ? "pickup_address"
                    : "delivery_address"
                )}
              </Heading>
              {isEdit ? (
                <></>
              ) : (
                <Button
                  text={t("new_address")}
                  mode="outline-info"
                  reduced={true}
                  component="button"
                  onClick={() => setShowNewAddress(true)}
                />
              )}
            </Row>
            <Label text={t("saved_address")} noPadding>
              <AddressSelector
                uuid={state?.selectedAddress?.uuidFavourite}
                uuidOrg={state?.interestedOrg}
                savedDirectionProps={{
                  readOnly: isEdit,
                  onChange: (option, open, setOpen) => {
                    handleOnChange(
                      "selectedAddress",
                      dispatch
                    )({
                      target: {
                        value: {
                          uuidFavourite: option.uuid,
                        },
                      },
                    });
                    //handleAddress(dispatch)({ id: option?.uuid }, true);
                    setTimeout(() => {
                      setOpen(false);
                    }, 5);
                  },
                  val: state?.selectedAddress?.uuidFavourite,
                }}
              />
            </Label>
          </Paper>
          <Paper
            whiteMode
            style={{ padding: "15px 20px", alignItems: "stretch" }}
          >
            <Heading type={5} style={{ margin: 0 }}>
              {t("offer")}
            </Heading>
            <Spacer height={10} />
            <Row gap={30} wrap={matches}>
              <Label text={`${t("amount")} (t)`} noPadding>
                <Row gap={15}>
                  <InputNumber
                    min={1}
                    step={".01"}
                    max={maxAmount?.split(" ")[0]}
                    required={true}
                    placeholder={t("write-here")}
                    name="amount"
                    onChange={handleOnChange("amount", dispatch)}
                    value={state?.amount?.split(" ")[0]}
                    mode="underline"
                    readOnly={isEdit}
                  />
                </Row>
              </Label>
              <Label text={`${t("ad-proposed-price")}`} noPadding>
                <Row gap={15}>
                  <InputNumber
                    min={1}
                    step={".01"}
                    required={true}
                    placeholder={t("write-here")}
                    name="proposedPrice"
                    onChange={handleOnChange("proposedPrice", dispatch)}
                    value={state?.proposedPrice}
                    mode="underline"
                  />
                  <DropDown
                    id="currency"
                    name="currency"
                    placeholder={t("select-value")}
                    options={
                      currencyList
                        ? currencyList.map((item) => ({
                            uuid: item.uuid,
                            text: item?.name?.includes("%")
                              ? item.name
                              : `${item.name}/t`,
                          }))
                        : currencyOptions
                    }
                    required={true}
                    value={
                      currencyList
                        ? currencyList
                            ?.find((f) => f.uuid === state?.currency)
                            ?.name.includes("%")
                          ? currencyList?.find(
                              (f) => f.uuid === state?.currency
                            )?.name
                          : `${
                              currencyList?.find(
                                (f) => f.uuid === state?.currency
                              )?.name
                            }/t`
                        : currencyOptions
                            ?.find((f) => f.uuid === state?.currency)
                            ?.name.includes("%")
                        ? currencyOptions?.find(
                            (f) => f.uuid === state?.currency
                          )?.name
                        : `${
                            currencyOptions?.find(
                              (f) => f.uuid === state?.currency
                            )?.name
                          }/t`
                    }
                    handleItemClick={handleItemSelected("currency")}
                    style={{ maxWidth: "130px" }}
                    mode="underline"
                  />
                </Row>
              </Label>
            </Row>

            {opportunityType === "sale" && (
              <>
                <Row gap={30} wrap={matches}>
                  <Label text={`${t("incoterm")}*`} noPadding>
                    <CatalogSearch
                      preload
                      avoidReload
                      inputProps={{
                        readOnly: isEdit,
                        required: true,
                        style: {
                          marginBottom: 20,
                        },
                        mode: "underline",
                      }}
                      initialValue={
                        state?.incoterm?.name || state?.incoterm || incotermName
                      }
                      catalogName={"incoterms-purchase"}
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        // if (option.extra === "port") {
                        //   setIsPort(true);
                        //   handleAddress(dispatch)(
                        //     {
                        //       port: "empty",
                        //     },
                        //     false,
                        //     true
                        //   );
                        // } else {
                        //   setIsPort(false);
                        //   handleAddress(dispatch)(
                        //     {
                        //       port: undefined,
                        //     },
                        //     false,
                        //     true
                        //   );
                        // }
                        handleOnChange(
                          "incoterm",
                          dispatch
                        )({
                          target: {
                            value: option.uuid,
                          },
                        });
                        setIncotermName(option?.name);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                      mode="underline"
                      disableEnterText
                      placeholder={t("select-value")}
                    />
                  </Label>

                  <Label
                    text={`${t("payment-method")} ${
                      opportunityType !== "sale" ? "*" : ""
                    }`}
                    noPadding
                  >
                    <CatalogSearch
                      withSearch
                      preload
                      inputProps={{
                        readOnly: opportunityType !== "sale" ? isEdit : false,
                        style: {
                          marginBottom: 20,
                        },
                        required: opportunityType !== "sale",
                        mode: "underline",
                      }}
                      initialValue={
                        state?.paymentMethod?.name ||
                        state?.paymentMethod ||
                        paymentMethodName
                      }
                      catalogName={"payment-method-opportunity"}
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        handleOnChange(
                          "paymentMethod",
                          dispatch
                        )({
                          target: {
                            value: option.uuid,
                          },
                        });
                        setPaymentMethodName(option?.name);
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                      mode="underline"
                      disableEnterText
                      avoidReload
                    />
                  </Label>
                </Row>
              </>
            )}
            {opportunityType !== "sale" && (
              <>
                <Label
                  text={`${t("payment-method")} ${
                    opportunityType !== "sale" ? "*" : ""
                  }`}
                  noPadding
                >
                  <AutoComplete
                    value={paymentMethodName}
                    options={paymentMethodSellerList}
                    inputProps={{
                      readOnly: opportunityType !== "sale" ? isEdit : false,
                      style: {
                        marginBottom: 20,
                      },
                      required: opportunityType !== "sale",
                      mode: "underline",
                    }}
                    onClickCallback={(
                      option,
                      open,
                      setOpen,
                      value,
                      setValue
                    ) => {
                      handleOnChange(
                        "paymentMethod",
                        dispatch
                      )({
                        target: {
                          value: option.uuid,
                        },
                      });
                      setPaymentMethodName(option?.name);
                      if (option?.extra) {
                        setRequiredFinancing(true);
                      } else {
                        setRequiredFinancing(false);
                      }
                      setTimeout(() => {
                        setOpen(false);
                      }, 100);
                    }}
                    mode="underline"
                  />
                </Label>
                {requiredFinancing && (
                  <Alert type="info" style={{ marginTop: "-15px" }}>
                    {t("this_payment_method_required_financing", {
                      ns: "common",
                    })}
                  </Alert>
                )}
              </>
            )}
            {/* {isPort && (
              <>
                <Label text={`${t("delivery_port")}`} noPadding>
                  <CatalogSearch
                    remote
                    id="SelectedPort"
                    inputProps={{
                      readOnly: isEdit,
                      required: true,
                      placeholder: t("write-or-select"),
                      mode: "underline",
                    }}
                    initialValue={
                      state?.selectedAddress?.port &&
                      state?.selectedAddress?.port !== "empty"
                        ? state?.selectedAddress?.port?.name ||
                          state?.selectedAddress?.port
                        : undefined
                    }
                    catalogName={"ports"}
                    avoidInitalLoad={true}
                    onClickCallback={(
                      option,
                      open,
                      setOpen,
                      value,
                      setValue
                    ) => {
                      handleAddress(dispatch)({
                        port: option.uuid,
                      });
                      setTimeout(() => {
                        setOpen(false);
                      }, 100);
                    }}
                    mode="underline"
                  />
                </Label>
              </>
            )} */}

            <Label text={`${t("specifications")}`} noPadding>
              <TextArea
                required={true}
                placeholder={t("ad-material-other-details")}
                value={state?.specification}
                onChange={handleOnChange("specification", dispatch)}
                mode="underline"
              />
            </Label>

            <Label text={t("images")} noPadding>
              <>
                <div>
                  <AdFiles
                    required={
                      state?.opportunityType === "sale" &&
                      _.isEmpty(state?.files)
                    }
                    files={state?.files}
                    placeholder={t("select-drop-files")}
                    dropPlaceholder={t("drop-files")}
                    afterUpload={(e) => {
                      if (!_.isEmpty(e)) {
                        handleAddImage(e[0]);
                      }
                    }}
                    onChange={(e) => {}}
                    type="ad"
                    iconStyles={{
                      background: "#F5F5F5",
                      borderRadius: 15,
                      padding: "7px 12px",
                    }}
                    t={t}
                  />
                </div>
              </>
            </Label>
            <FilesList
              files={state?.files?.map((f, index) => ({
                ...f,
                index,
              }))}
              handleDrop={() => {
                dispatch({
                  type: actions.UPDATE_INPUT,
                  payload: { ...state, files: filesBuffer.current },
                });
                filesBuffer.current = [];
              }}
              handleSort={handleSortImage}
              handleDelete={handleDeleteImage}
            />
          </Paper>
          <Row justify="flex-end" gap={20}>
            <Button
              fullwidth={matches ? true : undefined}
              component="button"
              mode="outline-gray"
              text={t("cancel")}
              loading={submitLoading}
              reduced
              onClick={(e) => {
                e.preventDefault();
                onBack();
              }}
              style={
                submitLoading
                  ? { paddingLeft: "4rem", paddingRight: "4rem" }
                  : {
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }
              }
            />
            <Button
              fullwidth={matches ? true : undefined}
              component="button"
              mode="info"
              text={t(uuid ? "update" : "save")}
              loading={submitLoading}
              reduced
              style={
                submitLoading
                  ? { paddingLeft: "4rem", paddingRight: "4rem" }
                  : { paddingLeft: "30px", paddingRight: "30px" }
              }
            />
          </Row>
        </FormWrap>
      </Flex>
    </form>
  );
}

function OpportunityOfferForm({
  uuid,
  opportunityId,
  opportunityType,
  maxAmount,
  opportunityPaymentMethod,
  onBack = () => {},
  fetchData = () => {},
}) {
  const { i18n } = useTranslation(["common"]);
  return (
    <div style={{ width: "100%" }}>
      <WidgetDataWrapper
        uuid={uuid}
        opportunityId={opportunityId}
        Component={OpportunityOfferFormInner}
        fnData={getOpportunityOfferDetail}
        fnParameters={{
          uuid: uuid,
          locale: i18n.language,
          opportunityId: opportunityId,
          edit: uuid ? true : false,
        }}
        dataAdapter={(data) => {
          return { ...data, amount: maxAmount };
        }}
        onBack={onBack}
        opportunityType={opportunityType}
        opportunityPaymentMethod={opportunityPaymentMethod}
        maxAmount={maxAmount}
        updateData={fetchData}
      />
    </div>
  );
}

export default OpportunityOfferForm;
