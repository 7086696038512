import React from "react";
import styles from "./UserItem.module.scss";
import UserIconRounded from "../icons/general/UserIconRounded";

function UserItem({ avatar, name }) {
  return (
    <div className={styles["user_item"]}>
      <div
        className={styles["avatar"]}
        style={{ backgroundImage: `url(${avatar})` }}
      >
        {!avatar && <UserIconRounded />}
      </div>
      <p>{name}</p>
    </div>
  );
}

export default UserItem;

